// Importar módulos y hooks necesarios de React
import { createContext, useContext, useState } from "react";

// Importar función para manejar rutas protegidas relacionadas con el blog para el superadministrador
import { protectedRouteSuperAdmin } from "../api/authUser";

// Crear un contexto para manejar los datos del blog para el superadministrador
const BlogContextSuperAdmin = createContext();

// Hook personalizado para usar el contexto del blog del superadministrador
export const useBlogSuperAdmin = () => {
  const context = useContext(BlogContextSuperAdmin);

  // Si el contexto no está disponible, lanzar un error
  if (!context) {
    throw new Error("useBlog must be used within a BlogProvider");
  }

  // Devolver el contexto del blog del superadministrador
  return context;
};

// Componente proveedor del contexto del blog para el superadministrador
export const BlogSuperAdminProvider = ({ children }) => {
  // Estado para almacenar la lista de artículos del blog
  const [articles, setArticles] = useState([]);

  // Estado para almacenar las categorías de los artículos del blog
  const [categories, setCategories] = useState([]);

  // Obtener todos los artículos
  const getArticles = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get("/articles");
      setArticles(res.data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  // Obtener un artículo específico
  const getArticle = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(`/articles/${id}`);
      return res.data;
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  };

  // Crear un artículo
  const createArticle = async (articleData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/super-admin-articles",
        articleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error creating article:", error);
    }
  };

  // Actualizar un artículo
  const updateArticle = async (id, articleData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-articles/${id}`,
        articleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error updating article:", error);
    }
  };

  // Eliminar un artículo
  const deleteArticle = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().delete(
        `/super-admin-articles/${id}`
      );
      return res.data;
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  return (
    <BlogContextSuperAdmin.Provider
      value={{
        articles,
        categories,
        getArticles,
        getArticle,
        createArticle,
        updateArticle,
        deleteArticle,
      }}
    >
      {children}
    </BlogContextSuperAdmin.Provider>
  );
};
