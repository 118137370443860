import { createContext, useContext, useState } from "react";
import { protectedRoute } from "../api/authUser"; // Importa la función de ruta protegida para las solicitudes autenticadas

const SolutionsContext = createContext(); // Crea el contexto para las soluciones

export const useSolutions = () => {
  const context = useContext(SolutionsContext); // Utiliza el contexto de soluciones

  if (!context) {
    throw new Error("useSolutions must be used within a SolutionsProvider"); // Lanza un error si useSolutions se usa fuera del proveedor
  }
  return context;
};

export const SolutionsProvider = ({ children }) => {
  const [solutions, setSolutions] = useState([]); // Estado para almacenar las soluciones
  const [solutionsContent, setSolutionsContent] = useState([]); // Estado para almacenar el contenido de las soluciones
  const [solutionsSecction, setSolutionsSecction] = useState([]); // Estado para almacenar la sección de soluciones

  // Función para obtener todas las soluciones
  const getAllSolutions = async () => {
    try {
      const res = await protectedRoute().get("/allSolutions");
      setSolutions(res.data); // Establece las soluciones obtenidas en el estado
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para obtener un subconjunto de soluciones
  const getSolutions = async () => {
    try {
      const res = await protectedRoute().get("/solutions");
      setSolutions(res.data); // Establece las soluciones obtenidas en el estado
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para obtener una solución específica por ID
  const getSolution = async (id) => {
    try {
      const res = await protectedRoute().get(`/solutions/${id}`);
      return res.data; // Devuelve los datos de la solución específica
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para crear una nueva solución
  const createSolutions = async (solutionData) => {
    try {
      const res = await protectedRoute().post("/solutions", solutionData, {
        headers: {
          "Content-Type": "multipart/form-data", // Indica que se está enviando datos multipart/form-data
        },
      });
      return res.data; // Devuelve los datos de la nueva solución
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para actualizar una solución existente
  const updateSolution = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(`/solutions/${id}`, solutionData, {
        headers: {
          "Content-Type": "multipart/form-data", // Indica que se está enviando datos multipart/form-data
        },
      });
      return res.data; // Devuelve los datos de la solución actualizada
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para eliminar una solución
  const deleteSolution = async (id) => {
    try {
      const res = await protectedRoute().delete(`/solutions/${id}`);
      return res.data; // Devuelve la respuesta de la eliminación
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Funciones relacionadas con el contenido de soluciones

  // Función para obtener todo el contenido de soluciones
  const getSolutionsContent = async () => {
    try {
      const res = await protectedRoute().get("/solutions-content");
      setSolutionsContent(res.data); // Establece el contenido de soluciones en el estado
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para obtener contenido de solución específico por ID
  const getSolutionsContentById = async (id) => {
    try {
      const res = await protectedRoute().get(`/solutions-content/${id}`);
      return res.data; // Devuelve los datos del contenido específico
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para crear contenido de soluciones
  const createSolutionsContent = async (solutionData) => {
    try {
      const res = await protectedRoute().post(
        "/solutions-content",
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Indica que se está enviando datos multipart/form-data
          },
        }
      );
      return res.data; // Devuelve los datos del nuevo contenido de solución
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para actualizar contenido de soluciones
  const updateSolutionsContent = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(
        `/solutions-content/${id}`,
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Indica que se está enviando datos multipart/form-data
          },
        }
      );
      return res.data; // Devuelve los datos del contenido actualizado
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Funciones relacionadas con la sección de soluciones

  // Función para obtener todas las secciones de soluciones
  const getSolutionsSecction = async () => {
    try {
      const res = await protectedRoute().get("/solutions-secction");
      setSolutionsSecction(res.data); // Establece la sección de soluciones en el estado
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para obtener una sección de solución específica por ID
  const getSolutionsSecctionById = async (id) => {
    try {
      const res = await protectedRoute().get(`/solutions-secction/${id}`);
      return res.data; // Devuelve los datos de la sección específica
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para crear una nueva sección de soluciones
  const createSolutionsSecction = async (solutionData) => {
    try {
      const res = await protectedRoute().post(
        "/solutions-secction",
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Indica que se está enviando datos multipart/form-data
          },
        }
      );
      return res.data; // Devuelve los datos de la nueva sección de soluciones
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para actualizar una sección de soluciones existente
  const updateSolutionsSecction = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(
        `/solutions-secction/${id}`,
        solutionData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Indica que se está enviando datos multipart/form-data
          },
        }
      );
      return res.data; // Devuelve los datos de la sección de soluciones actualizada
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  return (
    <SolutionsContext.Provider
      value={{
        solutions,
        getAllSolutions,
        getSolution,
        getSolutions,
        createSolutions,
        updateSolution,
        deleteSolution,
        solutionsContent,
        getSolutionsContentById,
        getSolutionsContent,
        createSolutionsContent,
        updateSolutionsContent,
        solutionsSecction,
        getSolutionsSecctionById,
        getSolutionsSecction,
        createSolutionsSecction,
        updateSolutionsSecction,
      }}
    >
      {children}{" "}
      {/* Renderiza los componentes hijos que estarán dentro del proveedor */}
    </SolutionsContext.Provider>
  );
};
