import React, { useState, useEffect, useRef, useContext } from "react"; // Importa React y varios hooks
import { Parallax } from "react-parallax"; // Importa el componente Parallax para crear efectos de paralaje
import { useContentAboutUs } from "../../Context/AuthAboutUsContext"; // Importa un hook personalizado para obtener contenido
import { ThemeContext } from "../../Context/ThemeContext"; // Importa el contexto del tema (modo oscuro o claro)
import { useInView } from "react-intersection-observer"; // Importa un hook para detectar si un elemento está visible en la vista
import CountUp from "react-countup"; // Importa un componente para animar contadores numéricos
import bg30 from "../../../public/bg30.png"; // Importa una imagen de fondo
import "./aboutUs.css"; // Importa los estilos CSS
import { useContentHome } from "../../Context/AuthContentHomeContext";
import { Carousel, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom"; // Asegúrate de importar useNavigate

function AboutUsHome() {
  const { firstContent, getFirstContent } = useContentAboutUs(); // Usa el contexto para obtener el contenido y una función para cargarlo
  const { darkMode } = useContext(ThemeContext); // Obtiene si el tema actual es oscuro o claro desde el contexto del tema
  const containerRef = useRef(null); // Crea una referencia para el contenedor principal
  const [isVisible, setIsVisible] = useState(false); // Estado local para controlar la visibilidad de un elemento
  const { content, getContent } = useContentHome(); // Obtiene funciones y datos del contexto
  const { slideBlog, getSlideBlogHome } = useContentHome(); // Obtiene funciones y datos del contexto
  const navigate = useNavigate(); // Inicializa el hook de navegación

  // Configuración para detectar cuando el elemento entra en vista (para activar animaciones, por ejemplo)
  const { ref, inView } = useInView({
    triggerOnce: true, // La animación se dispara solo una vez
    threshold: 0.1, // El umbral determina qué tan visible debe estar el elemento para considerarse en vista
  });

  // Hook de efecto que se ejecuta al montar el componente, carga el contenido inicial
  useEffect(() => {
    getFirstContent();
    getContent();
    getSlideBlogHome();
  }, []);

  const handleClick = (articleId) => {
    console.log(articleId); // Debería imprimir "3" cuando se haga clic en la imagen
    navigate(`/blog/${articleId}`); // Redirige a /blog/3
  };

  return (
    <>
      <div className="h-[85vh] pt-[%]">
        <div className="w-full h-full flex flex-col items-center rounded-md">
          <Carousel
            autoplay={true}
            loop={true}
            nextArrow={false}
            prevArrow={false}
            className="w-full h-full"
          >
            {content.map((item) => (
              <div key={item.id} className="w-full">
                <Parallax bgImage={item.image_background} strength={50}>
                  <div
                    className={`w-full bg-black/65 flex justify-between items-center h-[85vh] ${
                      darkMode ? "text-white" : ""
                    }`}
                  >
                    <div className="flex flex-col justify-center items-center rounded-r-full h-full">
                      <div className="f-slide w-[90%] pt-[5%] rounded-r-full flex flex-col justify-center">
                        <h1
                          className={`text-6xl mb-4 w-[80%] font-semibold ${
                            darkMode ? "text-white" : "text-white"
                          }`}
                        >
                          {item.title}
                        </h1>
                        <h2
                          className={`text-7xl mb-4 w-[80%] font-semibold ${
                            darkMode ? "text-white" : "text-white"
                          }`}
                        >
                          {item.sub_title}
                        </h2>
                        <div className="f-s-slide w-full flex gap-10">
                          <p
                            className={`w-[80%] text-2xl text-justify font-medium ${
                              darkMode ? "text-white" : "text-white"
                            }`}
                          >
                            {item.description}
                          </p>
                          <img className="w-[22%]" src={item.logo} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Parallax>
              </div>
            ))}
            {content.map((image, index) => (
              <div
                key={image.id}
                className="s-slide overflow-hidden h-full relative"
              >
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=573104593430&text=Hola%20GTI%20-%20Gesti%C3%B3n%20de%20Tecnolog%C3%ADa%20Inform%C3%A1tica%20necesito%20informaci%C3%B3n"
                >
                  <img
                    className="w-full h-full pt-20"
                    src={image.image_slide}
                    alt="logo"
                  />
                </a>
                {/* {index === 0 && ( // Mostrar el botón solo en el segundo slide (index 0)
                  <button className="absolute bg-[#cdd9fc] left-[50%] bottom-[6%] transform -translate-x-1/2 font-semibold text-xl text-[#000] p-4 rounded-md z-10">
                    <a href="/stock-devices">Ver más</a>
                  </button>
                )} */}
              </div>
            ))}
            {slideBlog.map((item) => (
              <div key={item.id} className="w-full">
                <Parallax bgImage={item.image_background} strength={500}>
                  <div
                    className={`w-full bg-black/60 flex justify-between items-center h-[85vh] ${
                      darkMode ? "text-white" : ""
                    }`}
                  >
                    <div className="flex flex-col justify-center items-center rounded-r-full h-full">
                      <div className="t-slide w-[90%] rounded-r-full flex flex-col justify-center">
                        <h1
                          className={`text-6xl whitespace-pre-line w-[80%] font-semibold ${
                            darkMode ? "text-white" : "text-white"
                          }`}
                        >
                          {item.title}
                        </h1>
                        <h2
                          onClick={() => handleClick("16")}
                          className={`mt-16 text-3xl w-full font-semibold cursor-pointer ${
                            darkMode ? "text-white" : "text-white"
                          }`}
                        >
                          Seguir leyendo...
                        </h2>
                      </div>
                    </div>
                  </div>
                </Parallax>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div
        ref={ref}
        className={`estadisticas w-full py-8 flex ${
          darkMode
            ? "bg-gray-900 text-[#ffffff]"
            : "bg-[#F1F8FE] text-[#0e4980]"
        }`}
      >
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={30} duration={12} suffix="+" />}
          </h2>
          <p className="text-xl">Años de experiencia</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && (
              <CountUp start={0} end={1000} duration={12} suffix="+" />
            )}
          </h2>
          <p className="text-xl">Clientes</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && (
              <CountUp start={0} end={6000} duration={12} suffix="+" />
            )}
          </h2>
          <p className="text-xl">Dispositivos en modelo DaaS e IaaS</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={100} duration={12} suffix="+" />}
          </h2>
          <p className="text-xl">Colaboradores</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={100} duration={12} suffix="%" />}
          </h2>
          <p className="text-xl">Cubrimiento a nivel nacional</p>
        </div>
      </div>
    </>
  );
}

export default AboutUsHome;
