// Importar los módulos y hooks necesarios
import { createContext, useContext, useState } from "react";
import { protectedRoute } from "../api/authUser";

// Crear un contexto para el contenido de "Sobre Nosotros"
const ContentAboutUsContext = createContext();

// Hook personalizado para acceder al contexto de "Sobre Nosotros"
export const useContentAboutUs = () => {
  const context = useContext(ContentAboutUsContext);

  // Lanza un error si el hook se usa fuera de su proveedor
  if (!context) {
    throw new Error(
      "useContentAboutUs debe usarse dentro de un ContentAboutUsProvider"
    );
  }
  return context;
};

// Proveedor del contexto de "Sobre Nosotros"
export const ContentAboutUsProvider = ({ children }) => {
  // Definir estados para manejar los diferentes contenidos
  const [firstContent, setFirstContent] = useState([]);
  const [subContent, setSubContent] = useState([]);
  const [missionAndVissionContent, setMissionAndVissionContent] = useState([]);
  const [valuesContent, setValuesContent] = useState([]);
  const [membershipsContent, setMembershipsContent] = useState([]);
  const [policyContent, setPolicyContent] = useState([]);
  const [cycleImages, setCycleImages] = useState([]);
  const [cycleContent, setCycleContent] = useState([]);

  // content about us
  const getFirstContent = async () => {
    try {
      const res = await protectedRoute().get(`/content-about-us`);
      setFirstContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFirstContentById = async (id) => {
    try {
      const res = await protectedRoute().get(`/content-about-us/${id}`);
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createFirstContent = async (contentData) => {
    try {
      const res = await protectedRoute().post(
        "/content-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateFirstContent = async (id, contentData) => {
    try {
      const res = await protectedRoute().put(
        `/content-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // sub content about us
  const getSubContent = async () => {
    try {
      const res = await protectedRoute().get(`/sub-content-about-us`);
      setSubContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSubContentById = async (id) => {
    try {
      const res = await protectedRoute().get(`/sub-content-about-us/${id}`);
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createSubContent = async (contentData) => {
    try {
      const res = await protectedRoute().post(
        "/sub-content-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateSubContent = async (id, contentData) => {
    try {
      const res = await protectedRoute().put(
        `/sub-content-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // sub content about us
  const getMissionAndVissionContent = async () => {
    try {
      const res = await protectedRoute().get(`/mission-vission-about-us`);
      setMissionAndVissionContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMissionAndVissionContentById = async (id) => {
    try {
      const res = await protectedRoute().get(`/mission-vission-about-us/${id}`);
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createMissionAndVissionContent = async (contentData) => {
    try {
      const res = await protectedRoute().post(
        "/mission-vission-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateMissionAndVissionContent = async (id, contentData) => {
    try {
      const res = await protectedRoute().put(
        `/mission-vission-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // values content about us
  const getValuesContent = async () => {
    try {
      const res = await protectedRoute().get(`/values-about-us`);
      setValuesContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getValuesContentById = async (id) => {
    try {
      const res = await protectedRoute().get(`/values-about-us/${id}`);
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createValuesContent = async (contentData) => {
    try {
      const res = await protectedRoute().post("/values-about-us", contentData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateValuesContent = async (id, contentData) => {
    try {
      const res = await protectedRoute().put(
        `/values-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // memberships content about us
  const getMembershipsContent = async () => {
    try {
      const res = await protectedRoute().get(`/membershipsImages-about-us`);
      setMembershipsContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMembershipsContentById = async (id) => {
    try {
      const res = await protectedRoute().get(
        `/membershipsImages-about-us/${id}`
      );
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createMembershipsContent = async (contentData) => {
    try {
      const res = await protectedRoute().post(
        "/membershipsImages-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateMembershipsContent = async (id, contentData) => {
    try {
      const res = await protectedRoute().put(
        `/membershipsImages-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMembershipsContent = async (id) => {
    try {
      const res = await protectedRoute().delete(
        `/membershipsImages-about-us/${id}`
      );
      return res.data;
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  // quality policy content about us
  const getPolicyContent = async () => {
    try {
      const res = await protectedRoute().get(`/qualityPolicy-about-us`);
      setPolicyContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPolicyContentById = async (id) => {
    try {
      const res = await protectedRoute().get(`/qualityPolicy-about-us/${id}`);
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createPolicyContent = async (contentData) => {
    try {
      const res = await protectedRoute().post(
        "/qualityPolicy-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updatePolicyContent = async (id, contentData) => {
    try {
      const res = await protectedRoute().put(
        `/qualityPolicy-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // Cycle experience content
  const getCycleExperienceImages = async () => {
    try {
      const res = await protectedRoute().get(`/images-cycle-experience`);
      setCycleImages(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCycleExperienceImagesById = async (id) => {
    try {
      const res = await protectedRoute().get(`/images-cycle-experience/${id}`);
      const cycleData = res.data;
      return cycleData;
    } catch (error) {
      console.error("Error fetching cycle:", error);
    }
  };

  const createCycleExperienceImages = async (cycleData) => {
    try {
      const res = await protectedRoute().post(
        "/images-cycle-experience",
        cycleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateCycleExperienceImages = async (id, cycleData) => {
    try {
      const res = await protectedRoute().put(
        `/images-cycle-experience/${id}`,
        cycleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesCycleExperienceImages = async (id) => {
    try {
      const res = await protectedRoute().delete(
        `/images-cycle-experience/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //Cycle experience content
  const getCycleExperienceContent = async () => {
    try {
      const res = await protectedRoute().get(`/cycle-experience`);
      setCycleContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCycleExperienceContentById = async (id) => {
    try {
      const res = await protectedRoute().get(`/cycle-experience/${id}`);
      const cycleData = res.data;
      return cycleData;
    } catch (error) {
      console.error("Error fetching cycle:", error);
    }
  };

  const createCycleExperienceContent = async (cycleData) => {
    try {
      const res = await protectedRoute().post("/cycle-experience", cycleData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateCycleExperienceContent = async (id, cycleData) => {
    try {
      const res = await protectedRoute().put(
        `/cycle-experience/${id}`,
        cycleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesCycleExperienceContent = async (id) => {
    try {
      const res = await protectedRoute().delete(`/cycle-experience/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContentAboutUsContext.Provider
      value={{
        firstContent,
        getFirstContentById,
        getFirstContent,
        createFirstContent,
        updateFirstContent,
        subContent,
        getSubContentById,
        getSubContent,
        createSubContent,
        updateSubContent,
        missionAndVissionContent,
        getMissionAndVissionContentById,
        getMissionAndVissionContent,
        createMissionAndVissionContent,
        updateMissionAndVissionContent,
        valuesContent,
        getValuesContentById,
        getValuesContent,
        createValuesContent,
        updateValuesContent,
        membershipsContent,
        getMembershipsContent,
        getMembershipsContentById,
        createMembershipsContent,
        updateMembershipsContent,
        deleteMembershipsContent,
        policyContent,
        getPolicyContent,
        getPolicyContentById,
        createPolicyContent,
        updatePolicyContent,
        cycleImages,
        getCycleExperienceImages,
        getCycleExperienceImagesById,
        createCycleExperienceImages,
        updateCycleExperienceImages,
        deletesCycleExperienceImages,
        cycleContent,
        getCycleExperienceContent,
        getCycleExperienceContentById,
        createCycleExperienceContent,
        updateCycleExperienceContent,
        deletesCycleExperienceContent,
      }}
    >
      {children}
    </ContentAboutUsContext.Provider>
  );
};
