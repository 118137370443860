import { createContext, useContext, useState } from "react";
import { protectedRoute } from "../api/authUser"; // Importa la función de ruta protegida para las solicitudes autenticadas

const ServicesContext = createContext(); // Crea el contexto para los servicios

export const useServices = () => {
  const context = useContext(ServicesContext); // Utiliza el contexto de servicios

  if (!context) {
    throw new Error("useServices must be used within a ServicesProvider"); // Lanza un error si useServices se usa fuera del proveedor
  }
  return context;
};

export const ServicesProvider = ({ children }) => {
  const [services, setServices] = useState([]); // Estado para almacenar los servicios

  // Función para obtener todos los servicios asociados a una solución específica
  const getServices = async (solutionId) => {
    try {
      const res = await protectedRoute().get(
        `/services/solution/${solutionId}`
      );
      setServices(res.data); // Establece los servicios obtenidos en el estado
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para obtener un servicio específico por ID
  const getService = async (id) => {
    try {
      const res = await protectedRoute().get(`/services/${id}`);
      const serviceData = res.data;
      return serviceData; // Devuelve los datos del servicio específico
    } catch (error) {
      console.error("Error fetching service:", error); // Maneja los errores de la solicitud
    }
  };

  // Función para crear un nuevo servicio
  const createServices = async (solutionData) => {
    try {
      const res = await protectedRoute().post("/services", solutionData, {
        headers: {
          "Content-Type": "multipart/form-data", // Indica que se está enviando datos multipart/form-data
        },
      });
      return res.data; // Devuelve los datos del nuevo servicio
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para actualizar un servicio existente
  const updateService = async (id, solutionData) => {
    try {
      const res = await protectedRoute().put(`/services/${id}`, solutionData, {
        headers: {
          "Content-Type": "multipart/form-data", // Indica que se está enviando datos multipart/form-data
        },
      });
      return res.data; // Devuelve los datos del servicio actualizado
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  // Función para eliminar un servicio
  const deleteServices = async (id) => {
    try {
      const res = await protectedRoute().delete(`/services/${id}`);
      return res.data; // Devuelve la respuesta de la eliminación
    } catch (error) {
      console.log(error); // Maneja los errores de la solicitud
    }
  };

  return (
    <ServicesContext.Provider
      value={{
        services,
        getService,
        getServices,
        createServices,
        updateService,
        deleteServices,
      }}
    >
      {children}{" "}
      {/* Renderiza los componentes hijos que estarán dentro del proveedor */}
    </ServicesContext.Provider>
  );
};
