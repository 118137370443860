import React, { useContext, useEffect } from "react"; // Importa React y varios hooks
import "./daas.css"; // Importa los estilos CSS específicos
import dispo from "../../../public/dispo.png";
import dispoBlack from "../../../public/dispoBlack.png";
import servi from "../../../public/servi.png";
import serviBlack from "../../../public/serviBlack.png";
import admi from "../../../public/admi.png";
import admiBlack from "../../../public/admiBlack.png";
import contrato from "../../../public/contrato.png";
import contratoBlack from "../../../public/contratoBlack.png";
import check from "../../../public/check.png";
import checkBlack from "../../../public/checkBlack.png";
import cycleDaaS from "../../../public/cicloDaaS.png";
import cycleDaaSBlack from "../../../public/cicloDaaSBlack.png";
import { ThemeContext } from "../../Context/ThemeContext"; // Importa el contexto de tema (modo oscuro/claro)

function DaaS() {
  const { darkMode } = useContext(ThemeContext); // Obtiene el valor de `darkMode` del contexto del tema

  // Define los elementos que serán parte del ciclo DaaS, cada uno con un texto y una clase CSS específica
  const items = [
    { text: "Disponer y Renovar", className: "disponer-renovar" },
    { text: "Descubrir y Diseñar", className: "descubrir-disenar" },
    { text: "Configurar", className: "configurar" },
    { text: "Implementar", className: "implementar" },
    { text: "Optimizar", className: "optimizar" },
    { text: "Mantener", className: "mantener" },
  ];

  useEffect(() => {
    // Maneja el evento de desplazamiento (scroll) para rotar los elementos con la clase "outer-circle3"
    const handleScroll = () => {
      const elements = document.querySelectorAll(".outer-circle3");
      const translateValue = window.innerWidth <= 430 ? 150 : 220; // Ajusta la distancia de la transformación según el ancho de la ventana
      elements.forEach((el, index) => {
        el.style.transform = `rotate(${
          window.scrollY / 10 + index * (360 / items.length)
        }deg) translate(${translateValue}px) rotate(-${
          window.scrollY / 10 + index * (360 / items.length)
        }deg)`;
      });
    };

    window.addEventListener("scroll", handleScroll); // Agrega el listener para el evento de scroll
    return () => window.removeEventListener("scroll", handleScroll); // Limpia el listener al desmontar el componente
  }, [items.length]); // Dependencia del efecto: se actualiza cuando cambia la longitud de `items`

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center">
        <div className="w-[100%] grid-cols-7 grid justify-center py-10 sum">
          <div className="metric w-full flex flex-col justify-center items-center">
            <img
              src={darkMode ? dispoBlack : dispo}
              alt="img"
              className="w-[60%]"
            />
            <h1
              className={`${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              } text-center text-xl h-[60px] pt-4`}
            >
              EQUIPOS
            </h1>
          </div>
          <div className="metric w-full flex justify-center items-center">
            <h1
              className={`simbol text-6xl ${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              }`}
            >
              +
            </h1>
          </div>
          <div className="metric w-full flex flex-col justify-center items-center">
            <img
              src={darkMode ? serviBlack : servi}
              alt="img"
              className="w-[60%]"
            />
            <h1
              className={`${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              } text-center text-xl h-[60px] pt-4`}
            >
              SERVICIOS
            </h1>
          </div>
          <div className="metric w-full flex justify-center items-center">
            <h1
              className={`simbol text-6xl ${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              }`}
            >
              +
            </h1>
          </div>
          <div className="metric w-full flex flex-col justify-center items-center">
            <img
              src={darkMode ? admiBlack : admi}
              alt="img"
              className="w-[60%]"
            />
            <h1
              className={`${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              } text-xl h-[60px] pt-4`}
            >
              ADMINISTRACIÓN
            </h1>
          </div>
          <div className="metric w-full flex justify-center items-center">
            <h1
              className={`simbol text-6xl ${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              }`}
            >
              =
            </h1>
          </div>
          <div className="metric w-full flex flex-col justify-center items-center">
            <img
              src={darkMode ? contratoBlack : contrato}
              alt="img"
              className="w-[60%]"
            />
            <h1
              className={`doble-t ${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              } text-center text-xl h-[60px] pt-4`}
            >
              UN SOLO CONTRATO <br />
              UN PAGO FIJO MENSUAL
            </h1>
          </div>
        </div>
        <h1
          className={`modelsTi text-4xl pb-4 ${
            darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
          }`}
        >
          Modelos de apropiación de TI
        </h1>
        <div className="table-container">
          <table>
            <thead>
              <tr className="header-row text-center">
                <th
                  className={` ${darkMode ? "bg-gray-900" : "bg-[#0e4980]"}`}
                ></th>
                <th className={` ${darkMode ? "bg-gray-900" : "bg-[#0e4980]"}`}>
                  COMPRA DIRECTA
                </th>
                <th className={` ${darkMode ? "bg-gray-900" : "bg-[#0e4980]"}`}>
                  FINANCIAMIENTO
                </th>
                <th className={` ${darkMode ? "bg-gray-900" : "bg-[#0e4980]"}`}>
                  DAAS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}>
                <td
                  className={`category ${
                    darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                  }`}
                >
                  Contabilidad
                </td>
                <td>El dispositivo cuenta como activo (CAPEX)</td>
                <td>El dispositivo cuenta como (CAPEX)</td>
                <td>El dispositivo cuenta como gasto (OPEX)</td>
              </tr>
              <tr className={` ${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                <td
                  className={`category ${
                    darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                  }`}
                >
                  Servicios administrados
                </td>
                <td colSpan="2" className="merged-cell">
                  Estos servicios se deben costear por separado
                </td>
                <td>
                  Estos servicios estan incluidos con los dispositivos bajo una
                  cuota mensual
                </td>
              </tr>
              <tr className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}>
                <td
                  className={`category ${
                    darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                  }`}
                >
                  Proveedores
                </td>
                <td colSpan="2" className="merged-cell">
                  El cliente puede firmar multiples contratos de adquisición,
                  con diferentes proveedores
                </td>
                <td>
                  El provvedor DaaS ofrece un unico contrato, con alcances y ANS
                  personalizados
                </td>
              </tr>
              <tr className={` ${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                <td
                  className={`category ${
                    darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                  }`}
                >
                  Duración
                </td>
                <td>
                  El comprador decide cuando retirar el dispositivo. (No mayor a
                  4 años)
                </td>
                <td>Usualmente entre 24 y 48 meses</td>
                <td>
                  Contratos de 3, 4, 5 años con renovación de dispositivos
                </td>
              </tr>
              <tr className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}>
                <td
                  className={`category ${
                    darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                  }`}
                >
                  Ciclo de renovación
                </td>
                <td>
                  El comprador renueva el dispositivo una vez finalizado su
                  ciclo de vida
                </td>
                <td>
                  El arrendador renueva el dispositivo al finalizar su base
                  instalada
                </td>
                <td>
                  Renovación permanente sin importar antigüedad de contrato
                </td>
              </tr>
              <tr className={` ${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                <td
                  className={`category ${
                    darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                  }`}
                >
                  Ventajas
                </td>
                <td>Auto-gestiona su base instalada</td>
                <td>
                  Financieramente escalable. Auto-gestiona su base instalada
                </td>
                <td>
                  Continuidad operativa. Flexibilidad al ser un servicio puro.
                  Distribución por centro de costos
                </td>
              </tr>
              <tr className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}>
                <td
                  className={`category ${
                    darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                  }`}
                >
                  Desventajas
                </td>
                <td>
                  Totalmente CAPEX. El modelo menos escalable de todos.
                  Dificultad para asignar a centros de costos internos
                </td>
                <td>
                  El cliente debe gestionar los incidentes. No hay flexibilidad
                  al reducir la base instalada. Duplicidad en pagos
                </td>
                <td>Gestion y control de dispositivos tercerizados</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-[100%] flex flex-col items-center justify-center">
          <div className="w-full justify-start">
            <h1
              className={`text-4xl pt-10 ${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              }`}
            >
              Alcance Solución DaaS (Dispositivo como Servicio)
            </h1>
            <p className={`text-xl text-justify pb-10 ${darkMode ? "text-[#ffffff]" : ""}`}>
              DaaS (Dispositivo como servicio), no se trata solo de adquirir un
              dispositivo tecnológico, va más allá, es contar con un equipo de
              expertos para gestionar y administrarlos, además de elegir entre
              diferentes planes ﬂexibles para su negocio:
            </p>
          </div>
          <div className="w-full tab">
            <table className="table-daas">
              <thead>
                <tr className={`${darkMode ? "bg-gray-900" : "bg-[#0e4980]"}`}>
                  <th>PAQUETE SERVICIOS GESTIONADOS EN DaaS</th>
                  <th>BÁSICO</th>
                  <th>CORPORATIVO</th>
                  <th>ENTERPRISE</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}
                >
                  <td>Tarifas fijas en periodos de 24, 36, 48 y 60 meses</td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr className={`${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                  <td>Garantía directa con fabricante desde 3 a 5 años</td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr
                  className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}
                >
                  <td>Entrega en ciudades principales – Cobertura Nacional</td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr className={`${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                  <td>
                    Servicios:
                    <br />
                    • Alistamiento básico de equipos antes de la entrega
                    <br />
                    • Software: hasta la capa de sistema operativo
                    <br />
                    • Hardware: instalación básica de equipo en sitio
                    <br />• Mesa de servicios para escalar requerimientos,
                    incluye informes periódicos
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr
                  className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}
                >
                  <td>
                    Atenciones de incidentes de hardware remoto y en sitio
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr className={`${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                  <td>Mantenimientos preventivos</td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr
                  className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}
                >
                  <td>
                    Despliegue de imágenes semilla desde instalaciones de GTI
                  </td>
                  <td>
                    {/* <img src={darkMode ? noCheckBlack : noCheck} alt="" /> */}
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr className={`${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                  <td>
                    Migración de datos y configuración de perfil de usuario
                    final
                  </td>
                  <td>
                    {/* <img src={darkMode ? noCheckBlack : noCheck} alt="" /> */}
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr
                  className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}
                >
                  <td>
                    Seguro contra todo riesgo sin deducible. (bajo las
                    condiciones estipuladas por la aseguradora y su contrato)
                  </td>
                  <td>
                    {/* <img src={darkMode ? noCheckBlack : noCheck} alt="" /> */}
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr className={`${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                  <td>
                    Gestión de Dispositivos (herramientas de control e
                    inventarios)
                  </td>
                  <td>
                    {/* <img src={darkMode ? noCheckBlack : noCheck} alt="" /> */}
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr
                  className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}
                >
                  <td>Dispositivos de respaldo según necesidad</td>
                  <td>
                    {/* <img src={darkMode ? noCheckBlack : noCheck} alt="" /> */}
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr className={`${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                  <td>Protección contra daños accidentales (ADP)</td>
                  <td>
                    {/* <img src={darkMode ? noCheckBlack : noCheck} alt="" /> */}
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr
                  className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}
                >
                  <td>
                    Técnico especializado en sitio para soportar los
                    dispositivos
                  </td>
                  <td>
                    {/* <img src={darkMode ? noCheckBlack : noCheck} alt="" /> */}
                  </td>
                  <td>
                    {/* <img src={darkMode ? checkBlack : check} alt="" /> */}
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr className={`${darkMode ? "bg-gray-400" : "bg-[#BEDDF9]"}`}>
                  <td>
                    Licenciamiento de Office365, Antivirus y otras aplicaciones
                    requeridas
                  </td>
                  <td>
                    {/* <img src={darkMode ? noCheckBlack : noCheck} alt="" /> */}
                  </td>
                  <td>
                    {/* <img src={darkMode ? checkBlack : check} alt="" /> */}
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
                <tr
                  className={` ${darkMode ? "bg-[#616161]" : "bg-[#ffffff]"}`}
                >
                  <td>
                    Backup en nube para datos críticos de los dispositivos
                  </td>
                  <td>
                    {/* <img src={darkMode ? noCheckBlack : noCheck} alt="" /> */}
                  </td>
                  <td>
                    {/* <img src={darkMode ? checkBlack : check} alt="" /> */}
                  </td>
                  <td>
                    <img src={darkMode ? checkBlack : check} alt="" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-full py-6">
            <h1
              className={`text-4xl ${
                darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
              }`}
            >
              Beneficios Solución DaaS (Dispositivo como Servicio)
            </h1>
          </div>
          <div className="beneficios flex justify-between">
            <div className="w-[40%]">
              <h2
                className={`text-2xl pb-4 ${
                  darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                }`}
              >
                Beneficios financieros:
              </h2>
              <ul
                className={`benefits-daas ${darkMode ? "text-[#ffffff]" : ""}`}
              >
                <li>
                  Costos predecibles: Al optar por el modelo de renta o DaaS, el
                  cliente paga una tarifa mensual fija por el uso de los
                  dispositivos (36, 48 y 60 meses según contrato), lo que
                  permite tener un control más preciso de los gastos.
                </li>
                <li>
                  Los pagos por renta o DaaS pueden considerarse como gastos
                  operativos en lugar de inversiones de capital. Esto trae
                  beneficios fiscales para la organización, ya que los gastos
                  operativos se pueden deducir en el año fiscal en curso,
                  mientras que las inversiones de capital generalmente se
                  amortizan a lo largo del tiempo.
                </li>
                <li>
                  El inicio del pago del servicio es treinta (30) días después
                  de entregados los dispositivos.
                </li>
              </ul>
            </div>
            <div className="w-[40%]">
              <h2
                className={`text-2xl pb-4 ${
                  darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                }`}
              >
                Beneficios técnicos:
              </h2>
              <ul
                className={`benefits-daas ${darkMode ? "text-[#ffffff]" : ""}`}
              >
                <li>
                  Todos nuestros dispositivos se entregan con garantía extendida
                  (entre 3 a 5 años cubierta directamente por el fabricante).
                </li>
                <li>
                  Se incluye una (1) ronda de mantenimiento preventivo por año y
                  durante la vigencia del contrato.
                </li>
                <li>
                  Se incluye software de inventario, gestión y administración de
                  dispositivos, líder en cuadrante de gartner.
                </li>
                <li>
                  Se incluye servicio de atención de incidentes relacionados
                  con fallas físicas de forma remota o en sitio, a través de los
                  diferentes canales suministrados por nuestra Mesa de Servicios
                  bajo las mejores prácticas ITIL.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-[100%] flex flex-col items-center justify-center">
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full flex justify-start">
              <h1
                className={`text-4xl py-10 ${
                  darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                }`}
              >
                Ciclo DaaS (Dispositivo Como Servicio)
              </h1>
            </div>
            <div className="cycle-container3">
              <div className={`center-circle3 ${darkMode ? "" : ""}`}>
                <img src={darkMode ? cycleDaaSBlack : cycleDaaS} alt="" />
              </div>
              {items.map((cycleImage, index) => (
                <div
                  key={index}
                  className={`outer-circle3 ${
                    darkMode ? "bg-gray-600 border-gray-900" : "bg-[#3a9ec9ec]"
                  }`}
                >
                  <p>{cycleImage.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DaaS;
