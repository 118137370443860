// Importar módulos y hooks necesarios de React
import { createContext, useContext, useState } from "react";

// Importar función para manejar rutas protegidas relacionadas con el blog
import { protectedRoute } from "../api/authUser";

// Crear un contexto para manejar los datos del blog
const BlogContext = createContext();

// Hook personalizado para usar el contexto del blog
export const useBlog = () => {
  const context = useContext(BlogContext);

  // Si el contexto no está disponible, lanzar un error
  if (!context) {
    throw new Error("useBlog must be used within a BlogProvider");
  }

  // Devolver el contexto del blog
  return context;
};

// Componente proveedor del contexto del blog
export const BlogProvider = ({ children }) => {
  // Estado para almacenar la lista de artículos del blog
  const [articles, setArticles] = useState([]);

  // Estado para almacenar las categorías de los artículos del blog
  const [categories, setCategories] = useState([]);

  // Obtener todos los artículos
  const getArticles = async () => {
    try {
      const res = await protectedRoute().get("/articles");
      setArticles(res.data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  // Obtener un artículo específico
  const getArticle = async (id) => {
    try {
      const res = await protectedRoute().get(`/articles/${id}`);
      return res.data;
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  };

  // Crear un artículo
  const createArticle = async (articleData) => {
    try {
      const res = await protectedRoute().post("/articles", articleData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.error("Error creating article:", error);
    }
  };

  // Actualizar un artículo
  const updateArticle = async (id, articleData) => {
    try {
      const res = await protectedRoute().put(`/articles/${id}`, articleData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.error("Error updating article:", error);
    }
  };

  // Eliminar un artículo
  const deleteArticle = async (id) => {
    try {
      const res = await protectedRoute().delete(`/articles/${id}`);
      return res.data;
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  // Obtener todas las categorías
  const getCategories = async () => {
    try {
      const res = await protectedRoute().get("/categories");
      setCategories(res.data);
      return res.data;
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Obtener una categoría específica
  const getCategory = async (id) => {
    try {
      const res = await protectedRoute().get(`/categories/${id}`);
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  };

  // Crear una categoría
  const createCategory = async (categoryData) => {
    try {
      const res = await protectedRoute().post("/categories", categoryData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  // Actualizar una categoría
  const updateCategory = async (id, categoryData) => {
    try {
      const res = await protectedRoute().put(
        `/categories/${id}`,
        categoryData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  // Eliminar una categoría
  const deleteCategory = async (id) => {
    try {
      const res = await protectedRoute().delete(`/categories/${id}`);
      return res.data;
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <BlogContext.Provider
      value={{
        articles,
        categories,
        getArticles,
        getArticle,
        createArticle,
        updateArticle,
        deleteArticle,
        getCategories,
        getCategory,
        createCategory,
        updateCategory,
        deleteCategory,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};
