import React, { useContext } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { CartContext } from "../../Context/CartContext";
import { Link } from "react-router-dom";

function CartShopping() {
  const { cartItems, removeFromCart } = useContext(CartContext); // Usar el contexto

  return (
    <>
      <Navbar />
      <div className="w-full flex flex-col items-center justify-center mt-24 min-h-[80vh]">
        <h2 className="text-2xl font-bold mb-8">Productos a cotizar</h2>

        {cartItems.length === 0 ? (
          <div>
            <div className="w-[100%]">
              <p>No hay productos en la cotización.</p>
              <Link to="/stock-devices">
                <button className="w-full bg-blue-600 text-white px-4 py-2 mt-2 rounded-b-md">
                  Volver
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center">
            <table className="w-[80%] bg-white">
              <thead>
                <tr>
                  <th className="py-2 text-black">Producto</th>
                  <th className="py-2 text-black">Título</th>
                  <th className="py-2 text-black">Descripción</th>
                  <th className="py-2 text-black">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item, index) => (
                  <tr key={index} className="text-center">
                    <td className="py-2">
                      {/* Mostrar la primera imagen del array de imágenes */}
                      {item.images && item.images.length > 0 ? (
                        <img
                          src={item.images[0]}
                          alt={item.name}
                          className="h-28 object-contain"
                        />
                      ) : (
                        <span>No Image Available</span>
                      )}
                    </td>
                    <td className="py-2">{item.name}</td>
                    <td className="py-2">{item.description}</td>
                    <td className="py-2">
                      <button
                        onClick={() => removeFromCart(item.id)} // Llamada a la función para eliminar
                        className="bg-red-600 text-white px-4 py-2 rounded-lg"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Botón para volver a la página anterior */}
            <div className="w-[80%]">
              <Link to="/stock-devices">
                <button className="w-full bg-blue-600 text-white px-4 py-2 mt-2 rounded-b-md">
                  Volver
                </button>
              </Link>
            </div>
          </div>
        )}
        <div className="w-[80%] border p-4 rounded-lg my-4">
          <h3 className="text-xl font-bold mb-2">
            Llena la información para realizar la cotización de los productos:
          </h3>
          <input
            type="text"
            placeholder="Nombre"
            className="border border-gray-300 px-4 py-2 mb-2 w-full"
          />
          <input
            type="email"
            placeholder="Correo"
            className="border border-gray-300 px-4 py-2 mb-2 w-full"
          />
          <textarea
            id="content"
            rows={5}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline `}
            placeholder="Cuentanos que duda tienes, o que necesitas para la cotización del producto."
          ></textarea>
          <button className="bg-blue-600 text-white px-4 py-2 rounded-lg">
            Enviar
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CartShopping;
