import { createContext, useContext, useEffect, useState } from "react";
import {
  loginRequest,
  protectedRoute,
  registerRequest,
  updateCredentialsRequest,
  verifyTokenRequest,
} from "../api/authUser"; // Importa las funciones de solicitud a la API relacionadas con la autenticación

export const AuthUserContext = createContext(); // Crea el contexto para la autenticación

export const useAuth = () => {
  const context = useContext(AuthUserContext); // Utiliza el contexto de autenticación
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider"); // Lanza un error si useAuth se usa fuera del proveedor
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Estado para almacenar los datos del usuario
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado para manejar si el usuario está autenticado
  const [userErrors, setUserErrors] = useState([]); // Estado para manejar los errores relacionados con el usuario
  const [loading, setLoading] = useState(true); // Estado para manejar el estado de carga

  const value = {
    user,
    setUser,
  }; // Valor que se proporcionará a los componentes que utilicen este contexto

  // Función para registrar un nuevo usuario
  const signup = async (user) => {
    try {
      const res = await registerRequest(user); // Llama a la función de registro
      if (res.data === 200) {
        setUser(res.data); // Establece los datos del usuario
        setIsAuthenticated(true); // Marca al usuario como autenticado
      }
    } catch (error) {
      setUserErrors(error); // Maneja los errores de registro
      console.log(error);
    }
  };

  // Función para iniciar sesión
  const signin = async (user) => {
    try {
      const res = await loginRequest(user); // Llama a la función de inicio de sesión
      if (res.data.token) {
        localStorage.setItem("token", res.data.token); // Almacena el token en el almacenamiento local
        setIsAuthenticated(true); // Marca al usuario como autenticado
        setUser(res.data); // Establece los datos del usuario
        console.log(res);
      } else {
        setIsAuthenticated(false); // Si no se recibe un token, no se autentica al usuario
        setUserErrors(["Correo o contraseña invalidos*"]); // Muestra un error de credenciales
      }
    } catch (error) {
      console.log(error);
      if (Array.isArray(error.response.data)) {
        return setUserErrors(error.response.data); // Maneja los errores de inicio de sesión
      }
      setUserErrors([error.response.data.message]); // Muestra el mensaje de error
    }
  };

  // Función para actualizar los datos del usuario
  const updateUser = async (id, userData) => {
    try {
      const res = await protectedRoute().put(
        `/update-credentials/${id}`,
        userData
      ); // Llama a la función para actualizar los datos del usuario
      if (res.data) {
        setUser(res.data); // Actualiza los datos del usuario en el estado
        console.log("Usuario actualizado", res.data);
      }
    } catch (error) {
      setUserErrors(["Error al actualizar los datos del usuario"]); // Maneja los errores de actualización
      console.log(error);
    }
  };

  // Función para cerrar sesión
  const logout = () => {
    localStorage.removeItem("token"); // Elimina el token del almacenamiento local
    setIsAuthenticated(false); // Marca al usuario como no autenticado
    setUser(null); // Limpia los datos del usuario
  };

  // Efecto para limpiar los errores de usuario después de 5 segundos
  useEffect(() => {
    if (userErrors.length > 0) {
      const timer = setTimeout(() => {
        setUserErrors([]);
      }, 5000); // Borra los errores después de 5 segundos
      return () => clearTimeout(timer); // Limpia el temporizador cuando el componente se desmonta
    }
  }, [userErrors]);

  // Efecto para verificar si el usuario está autenticado al cargar la página
  useEffect(() => {
    const checkLogin = async () => {
      const token = localStorage.getItem("token"); // Obtiene el token del almacenamiento local
      if (!token) {
        setIsAuthenticated(false); // Si no hay token, marca al usuario como no autenticado
        setLoading(false); // Termina el estado de carga
        return setUser(null); // Limpia los datos del usuario
      }

      try {
        const res = await verifyTokenRequest(token); // Verifica el token
        // console.log(res);
        if (!res.data) {
          setIsAuthenticated(false); // Si el token no es válido, marca al usuario como no autenticado
          setLoading(false); // Termina el estado de carga
          return;
        }

        setIsAuthenticated(true); // Si el token es válido, marca al usuario como autenticado
        setUser(res.data); // Establece los datos del usuario
        setLoading(false); // Termina el estado de carga
      } catch (error) {
        console.log(error);
        setIsAuthenticated(false); // Maneja los errores de verificación
        setUser(null); // Limpia los datos del usuario
        setLoading(false); // Termina el estado de carga
      }
    };
    checkLogin(); // Ejecuta la función para verificar el inicio de sesión al montar el componente
  }, []);

  return (
    <AuthUserContext.Provider
      value={{
        signup,
        signin,
        updateUser,
        logout,
        loading,
        user,
        isAuthenticated,
        userErrors,
        value,
      }}
    >
      {children}{" "}
      {/* Renderiza los componentes hijos que estarán dentro del proveedor */}
    </AuthUserContext.Provider>
  );
};
