import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoGTI from "../../../public/Logo-GTI.png";
import LogoGTIblack from "../../../public/GTIblackMenu.png";
import arrow from "../../../public/flecha-curva.png";
import arrowblack from "../../../public/flecha-curva Black.png";
import { ThemeContext } from "../../Context/ThemeContext";
import { useContext, useState, useEffect } from "react";
import "./nav.css";
import { useAuth } from "../../Context/AuthUserContext";

function NavbarAdmin() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext); //variables de estado del modo oscuro cuando se asigne
  const [menuOpen, setMenuOpen] = useState(false); //variables de estado para hacer visible el menu hamburgesa en vista mobile
  const [scrollingUp, setScrollingUp] = useState(true);
  const location = useLocation();
  const { isAuthenticated, logout, user } = useAuth();
  // const userName = user && user.user && user.User.username;
  const navigate = useNavigate();

  const isActive = (path) => location.pathname === path; //funcion para dar el estado de activo o inactivo en los items de la barra de navegacion

  //funcion para ocultar la barra de navegacion cuando se hace scrolldown y visible cuando se hace scrollup
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setScrollingUp(false);
      } else {
        setScrollingUp(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //variables para cambiar el logo cuando se aplica el modo oscuro/claro
  const logoSrc = darkMode ? LogoGTIblack : LogoGTI;
  const arrowSrc = darkMode ? arrowblack : arrow;
  

  return (
    <>
      {isAuthenticated ? (
        <>
          <div
            className={`navbar-container ${
              darkMode ? "dark-mode" : "light-mode"
            } ${menuOpen ? "menu-open h-[100vh] items-start" : "h-[100px]"} ${
              scrollingUp ? "visible" : "hidden"
            }`}
          >
            <header
              className={`navbar ${
                menuOpen ? "menu-open h-[100vh] items-start" : ""
              }`}
            >
              <img
                src={logoSrc}
                className={`w-[10%] header-logo`}
                alt="Logo GTI"
              />
              <div className="flex-container">
                <nav className={`nav-content ${menuOpen ? "menu-open" : ""}`}>
                  <div className={`mobile-nav ${menuOpen ? "open" : ""}`}>
                    <img src={logoSrc} className="logo" alt="Logo GTI" />
                    <button
                      onClick={() => setMenuOpen(!menuOpen)}
                      className={`navbar-button ${
                        darkMode ? "dark-mode" : "light-mode"
                      }`}
                    >
                      {menuOpen ? "✖" : "☰"}
                    </button>
                  </div>
                  <ul
                    className={`navbar-menu font-semibold ${
                      menuOpen ? "menu-open" : ""
                    }`}
                  >
                    <li
                      className={`nav-item ${
                        darkMode ? "dark-mode" : "light-mode"
                      } ${isActive("/home-panel") ? "active" : ""}`}
                    >
                      <Link to="/home-panel">Contenido Inicio</Link>
                    </li>
                    <li
                      className={`nav-item ${
                        darkMode ? "dark-mode" : "light-mode"
                      } ${isActive("/about-us-panel") ? "active" : ""}`}
                    >
                      <Link to="/about-us-panel">Contenido Quienes somos</Link>
                    </li>
                    <li
                      className={`nav-item ${
                        darkMode ? "dark-mode" : "light-mode"
                      } ${isActive("/solutions-panel") ? "active" : ""}`}
                    >
                      <Link to="/solutions-panel">Soluciones Añadidas</Link>
                    </li>
                    <li
                      className={`nav-item ${
                        darkMode ? "dark-mode" : "light-mode"
                      } ${isActive("/blog-panel") ? "active" : ""}`}
                    >
                      <Link to="/blog-panel">Contenido Blog</Link>
                    </li>
                    <li
                      className={`nav-item ${
                        darkMode ? "dark-mode" : "light-mode"
                      } ${isActive("/ecommerce-panel") ? "active" : ""}`}
                    >
                      <Link to="/ecommerce-panel">Contenido Ecommerce</Link>
                    </li>
                      <li
                        className={`nav-item ${
                          darkMode ? "dark-mode" : "light-mode"
                        } ${isActive(`/edit-profile/${user.User.id}`) ? "active" : ""}`}
                      >
                        <Link to={`/edit-profile/${user.User.id}`}>{user && user.User.username}</Link>
                      </li>
                    <li
                      className={`nav-item ${
                        darkMode ? "dark-mode" : "light-mode"
                      }`}
                    >
                      <Link
                        to="/login-user"
                        onClick={() => {
                          logout();
                        }}
                      >
                        Cerrar Sesión
                      </Link>
                    </li>
                    <li
                      className={`li nav-item ${
                        darkMode ? "dark-mode" : "light-mode"
                      }`}
                    >
                      <label className="switch">
                        <span className="sun">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <g fill="#ffd43b">
                              <circle r="5" cy="12" cx="12"></circle>
                              <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                            </g>
                          </svg>
                        </span>
                        <span className="moon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
                          </svg>
                        </span>
                        <input
                          type="checkbox"
                          className="input"
                          checked={darkMode}
                          onChange={toggleDarkMode}
                        />
                        <span className="slider2"></span>
                      </label>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-switch">
                <label className="switch">
                  <span className="sun">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <g fill="#ffd43b">
                        <circle r="5" cy="12" cx="12"></circle>
                        <path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path>
                      </g>
                    </svg>
                  </span>
                  <span className="moon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
                    </svg>
                  </span>
                  <input
                    type="checkbox"
                    className="input"
                    checked={darkMode}
                    onChange={toggleDarkMode}
                  />
                  <span className="slider2"></span>
                </label>
              </div>
            </header>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default NavbarAdmin;
