import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./solutionsPanel.css";
import NavbarSuperAdmin from "../../components/Navbar/NavbarSuperAdmin";
import { ThemeContext } from "../../Context/ThemeContext";
import { useSolutionsSuperAdmin } from "../../Context/SolutionsContextSuperAdmin";
import { useServicesSuperAdmin } from "../../Context/ServicesContextSuperAdmin";
import FooterAmin from "../../components/Footer/FooterAmin";
import { Parallax } from "react-parallax";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function PanelAddSolutionsSuperAdmin() {
  // Contexto para el tema de la aplicación
  const { darkMode } = useContext(ThemeContext);

  // Estado para manejar el servicio seleccionado y el segundo servicio
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceTwo, setSelectedServiceTwo] = useState(null);

  // Refs para manejar el scroll y la visibilidad de elementos
  const titleRef = useRef(null);
  const cardRefs = useRef([]);
  const containerRef = useRef(null);

  // Estado para controlar la visibilidad de la sección
  const [isVisible, setIsVisible] = useState(false);

  // Estado para manejar el estado de carga de soluciones
  const [loadingSolutions, setLoadingSolutions] = useState(true);

  // Hooks de ubicación y navegación
  const location = useLocation();
  const navigate = useNavigate();

  // Contextos para obtener datos de soluciones y servicios
  const { solutions, getSolutions, getAllSolutions, deleteSolution } =
    useSolutionsSuperAdmin();
  const { solutionsSecction, getSolutionsSecction } = useSolutionsSuperAdmin();
  const { solutionsContent, getSolutionsContent } = useSolutionsSuperAdmin();
  const { services, getServices, deleteServices } = useServicesSuperAdmin();

  // Cargar datos iniciales
  useEffect(() => {
    getSolutions();
    getServices();
    getAllSolutions();
    getSolutionsSecction();
    getSolutionsContent();
  }, []);

  // Manejo del estado de carga de soluciones
  useEffect(() => {
    const fetchSolutions = async () => {
      await getSolutions();
      setLoadingSolutions(false);
    };
    fetchSolutions();
  }, []);

  // Observador de intersección para manejar la visibilidad de elementos al hacer scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Manejo del parámetro de búsqueda en la URL para seleccionar el servicio
  useEffect(() => {
    if (!loadingSolutions) {
      const query = new URLSearchParams(location.search);
      const serviceName = query.get("service");

      if (serviceName) {
        const solution = solutions.find((sol) => sol.title === serviceName);
        if (solution) {
          setSelectedService(serviceName);
          getServices(solution.id);
        }
      }
    }
  }, [location.search, solutions, loadingSolutions]);

  // Manejo del clic en un servicio para seleccionarlo y hacer scroll hacia él
  const handleClick = (serviceName, index) => {
    setSelectedService(serviceName);
    navigate(`?service=${serviceName}`);
    setTimeout(() => {
      cardRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  // Manejo del clic en el botón "Volver" para deseleccionar el servicio
  const handleBackClick = () => {
    setSelectedService(null);
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Manejo del scroll hacia el título cuando se deselecciona el servicio
  useEffect(() => {
    if (selectedService === null && titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedService]);

  // Manejo de la eliminación de una solución
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      await deleteSolution(id);
      getSolutions();
      Swal.fire("¡Eliminado!", "La solución ha sido eliminada.", "success");
    }
  };

  // Manejo de la eliminación de un servicio
  const handleDeleteService = async (id) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0E4980",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      await deleteServices(id);
      getServices();
      Swal.fire("¡Eliminado!", "El servicio ha sido eliminado.", "success");
    }
  };

  // Inicialización de AOS y carga de todas las soluciones
  useEffect(() => {
    AOS.init({ duration: 1000 });
    getAllSolutions().catch((error) =>
      console.error("Error loading solutions:", error)
    );
  }, []);

  // Selección del servicio por defecto
  useEffect(() => {
    if (Array.isArray(solutions) && solutions.length > 0) {
      const defaultService = solutions.find(
        (service) => service.title === "XaaS (Todo como servicio)"
      );
      if (defaultService) {
        handleServiceClick(defaultService.id);
      }
    } else {
      console.error("solutions no es un arreglo o está vacío:", solutions);
    }
  }, [solutions]);

  // Manejo del clic en un servicio para cargar los detalles del servicio seleccionado
  const handleServiceClick = async (id) => {
    await getServices(id).catch((error) =>
      console.error("Error loading services:", error)
    );
    const service = solutions.find((service) => service.id === id);
    setSelectedServiceTwo(service);
  };

  // Configuración del slider
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <span ref={titleRef}></span>
      <NavbarSuperAdmin />
      <div
        className={`w-full flex justify-center min-h-[100vh] ${
          darkMode ? "bg-[#424242] text-white" : "bg-gray-100 text-[#0e4980]"
        }`}
      >
        <div className="t w-[80%] pt-[8%]">
          {solutionsSecction.map((solutionSecction, index) => (
            <div className="w-full pb-[2%]">
              <Parallax
                bgImage={solutionSecction.image_background}
                blur={{ min: -2, max: 4 }}
                strength={500}
              >
                <div
                  className={`principal flex bg-black/65  w-full justify-between pt-[15%] pb-[5%] ${
                    darkMode ? " text-white" : ""
                  }`}
                  ref={containerRef}
                >
                  <div className="txt flex flex-col justify-center items-center w-full rounded-r-full h-[50vh]">
                    <div
                      className={`txt-c w-[80%] h-[50vh] rounded-r-full flex flex-col justify-center ${
                        darkMode ? "" : ""
                      } ${
                        isVisible
                          ? "animate-fade-left animate-once animate-duration-[5000ms] animate-normal animate-fill-backwards"
                          : ""
                      }`}
                    >
                      <h1
                        className={`text-6xl mb-4 w-[80%] font-semibold ${
                          darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                        }`}
                      >
                        {solutionSecction.title}
                      </h1>
                      <h2
                        className={`text-4xl mb-4 w-[80%] font-semibold ${
                          darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                        }`}
                      >
                        {solutionSecction.sub_title}
                      </h2>
                      <p
                        className={`w-[50%] text-xl text-justify font-medium ${
                          darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                        }`}
                      >
                        {solutionSecction.description}
                      </p>
                    </div>
                  </div>
                </div>
              </Parallax>
              <button
                className={`w-full p-2 rounded-md ${
                  darkMode
                    ? "text-[#ffffff] bg-gray-900"
                    : "bg-[#0e4980] text-[#ffffff]"
                }`}
              >
                <Link
                  to={`/solutions-secction-form-panel-super-admin/${solutionSecction.id}`}
                >
                  Editar contenido
                </Link>
              </button>
            </div>
          ))}
          {solutionsContent.map((solutionContent, index) => (
            <div className="w-full pb-[2%]">
              <Parallax
                bgImage={solutionContent.image_background}
                strength={600}
              >
                <div
                  className={`so bg-black/45 w-full flex flex-col items-center justify-center py-[5%] ${
                    darkMode ? "" : ""
                  }`}
                >
                  <div className="title-initial w-[50%] text-center flex justify-center mt-4 mb-14 items-center">
                    <h1
                      className={`title text-2xl mb-8 text-[#0e4980] font-semibold ${
                        darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                      }`}
                    >
                      {solutionContent.title}
                    </h1>
                  </div>
                  <div
                    className={`full2 w-full py-8 flex justify-center items-center ${
                      darkMode ? "" : ""
                    }`}
                  >
                    <div className="tdo w-[80%] flex justify-center items-center">
                      <div
                        className="container-cycle w-[50%] p-4"
                        data-aos="fade-up"
                      >
                        <div className="cycle-container2">
                          <div className="cycle2 w-[90%]">
                            {Array.isArray(solutions) &&
                              solutions.map((solution) => (
                                <div
                                  className={`cycle-item2 ${
                                    selectedService &&
                                    selectedService.id === solution.id
                                      ? "active"
                                      : ""
                                  } ${
                                    darkMode
                                      ? "bg-[#424242e1] border-[#2b2a2ae1]"
                                      : "bg-[#0e3153f4]"
                                  }`}
                                  onClick={() =>
                                    handleServiceClick(solution.id)
                                  }
                                  key={solution.id}
                                >
                                  <div className="icon">
                                    <img
                                      src={solution.icon_solution}
                                      alt={solution.title}
                                    />
                                  </div>
                                  <div className={"label"}>
                                    <h1
                                      className={`${
                                        darkMode
                                          ? "text-[#ffffff]"
                                          : "text-[#ffffff]"
                                      }`}
                                    >
                                      {solution.title}
                                    </h1>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="container w-[50%]">
                        <div className="description-container w-full">
                          {selectedServiceTwo && (
                            <div
                              className={`flex justify-center items-center p-8 rounded-md min-h-[300px] border-l-[20px] border-[#113d69] ${
                                darkMode
                                  ? "bg-gray-600 text-white border-gray-800"
                                  : "bg-[#beddf9]"
                              }`}
                            >
                              <div className="w-full h-full flex flex-col justify-center">
                                <h1
                                  className={`text-sm text-[#113d69] mb-8 font-medium ${
                                    darkMode
                                      ? "text-[#ffffff]"
                                      : "text-[#0e4980]"
                                  }`}
                                >
                                  <span className="font-bold text-lg">
                                    {selectedServiceTwo
                                      ? selectedServiceTwo.title
                                      : ""}
                                  </span>
                                </h1>
                                <p
                                  className={`sol text-sm mb-4 text-justify text-[#113d69] ${
                                    darkMode
                                      ? "text-[#ffffff]"
                                      : "text-[#0e4980]"
                                  }`}
                                >
                                  {selectedServiceTwo.description}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Parallax>
              <button
                className={`w-full p-2 rounded-md ${
                  darkMode
                    ? "text-[#ffffff] bg-gray-900"
                    : "bg-[#0e4980] text-[#ffffff]"
                }`}
              >
                <Link
                  to={`/solutions-content-form-panel-super-admin/${solutionContent.id}`}
                >
                  Editar contenido
                </Link>
              </button>
            </div>
          ))}

          <div className="w-[92%] flex justify-between">
            <h1 className={`text-3xl`}>
              Soluciones Tecnologicas y Servicios Tecnologicos Agregados:
            </h1>
            <button
              className={`w-[20%] p-2 rounded-md ${
                darkMode
                  ? "text-[#ffffff] bg-gray-900"
                  : "bg-[#0e4980] text-[#ffffff]"
              }`}
            >
              <Link to="/solutions-form-panel-super-admin">
                Añadir Soluciones
              </Link>
            </button>
          </div>
          {solutions.length === 0 && (
            <h1 className={`text-3xl text-[#0e4980]`}>
              No se han agregado soluciones!
            </h1>
          )}

          <div className="contenedor-cards2 w-full flex flex-col my-8 items-center justify-center mb-8">
            <div className="cardSolutions2">
              {solutions.map((solution, index) => (
                <div
                  key={solution.id}
                  ref={(el) => (cardRefs.current[index] = el)}
                  className={`card2 w-[22%] flex flex-col justify-center items-center rounded-t-[8px] rounded-b-[8px] ${
                    selectedService === solution.title
                      ? "expanded"
                      : "" || selectedService === null
                      ? ""
                      : "hidden"
                  } ${
                    darkMode
                      ? "bg-gray-600 text-white border-gray-800"
                      : "bg-[#F1F8FE]"
                  }`}
                >
                  <img src={solution.image_solution} alt="" />
                  <div className="cardExtended2">
                    <h1
                      className={`h-[60px] ${
                        darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                      }`}
                    >
                      {solution.title}
                    </h1>
                    <p
                      className={`w-[90%] ${
                        darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                      } ${
                        selectedService === solution.title ? "expanded" : ""
                      }`}
                    >
                      {solution.description}
                    </p>
                  </div>
                  <div className="z-1 w-full flex justify-between">
                    <button
                      className={`${
                        darkMode
                          ? "text-[#ffffff] bg-gray-900"
                          : "bg-[#0e4980] text-[#0e4980]"
                      } ${selectedService === solution.title ? "hidden" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(solution.title, index);
                      }}
                    >
                      Visualizar
                    </button>
                    <button
                      className={`${
                        darkMode
                          ? "text-[#ffffff] bg-gray-900"
                          : "bg-[#0e4980] text-[#0e4980]"
                      } ${selectedService === solution.title ? "hidden" : ""}`}
                    >
                      <Link to={`/solutions-super-admin/${solution.id}`}>
                        Editar
                      </Link>
                    </button>
                    <button
                      className={`${
                        darkMode
                          ? "text-[#ffffff] bg-[#7d1e1e]"
                          : "bg-[#800e0e] text-[#0e4980]"
                      } ${selectedService === solution.title ? "hidden" : ""}`}
                      onClick={() => handleDelete(solution.id)}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="s w-full flex flex-col items-center justify-center">
            {selectedService &&
              services.length > 0 &&
              services.map((service, index) => (
                <div
                  key={service.id}
                  className={`service-item2 ${
                    darkMode ? " text-white" : "text-[#0E4980]"
                  }`}
                >
                  <div className="service-details2">
                    <div
                      className={`service-detail-card2 ${
                        index % 2 === 0
                          ? `bg-[#cadfea] ${darkMode ? "bg-gray-500" : ""}`
                          : `bg-[#9ec5da] ${darkMode ? "bg-gray-700" : ""}`
                      }`}
                    >
                      <div className="service-detail-icon-wrapper2">
                        <img
                          src={service.image_service}
                          className="service-detail-icon2"
                        />
                      </div>
                      <div className="service-detail-content2">
                        <h3 className="service-detail-info2">
                          {service.name_service}
                        </h3>
                        <p className="service-detail-description2">
                          {service.description_service}
                        </p>
                      </div>
                      <div className="buttons-services w-full flex justify-end">
                        <button
                          className={`h-[35px] px-2 ml-2 mt-2 rounded-md ${
                            darkMode
                              ? "text-[#ffffff] bg-gray-900"
                              : "bg-[#0e4980] text-[#ffffff]"
                          }`}
                        >
                          <Link to={`/services-super-admin/${service.id}`}>
                            Editar
                          </Link>
                        </button>
                        <button
                          className={`h-[35px] px-2 ml-2 mt-2 rounded-md ${
                            darkMode
                              ? "text-[#ffffff] bg-[#7d1e1e]"
                              : "bg-[#800e0e] text-[#ffffff]"
                          }`}
                          onClick={() => handleDeleteService(service.id)}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {selectedService && services.length === 0 && (
              <p>No hay servicios disponibles para esta solución.</p>
            )}
            {selectedService && (
              <div className="w-full flex justify-center mb-4">
                <button
                  onClick={handleBackClick}
                  className={`back-button ${
                    darkMode
                      ? "text-[#ffffff] bg-gray-900"
                      : "bg-[#0e4980] text-[#ffffff]"
                  }`}
                >
                  Volver a todas las soluciones
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default PanelAddSolutionsSuperAdmin;
