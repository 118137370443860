// Importar módulos y hooks necesarios de React
import React, { createContext, useState, useEffect } from 'react';

// Crear un contexto para manejar el tema (modo oscuro/claro)
export const ThemeContext = createContext();

// Componente proveedor del contexto de tema
export const ThemeProvider = ({ children }) => {
  // Estado para almacenar el estado del modo oscuro (true o false)
  const [darkMode, setDarkMode] = useState(() => {
    // Obtener el estado del modo oscuro desde el almacenamiento local
    const savedMode = localStorage.getItem('darkMode');
    // Si el valor guardado es 'true', usarlo; de lo contrario, usar false
    return savedMode === 'true' || false;
  });

  // Efecto para guardar el estado del modo oscuro en el almacenamiento local
  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]); // Ejecutar este efecto cada vez que 'darkMode' cambie

  // Función para alternar el estado del modo oscuro
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  // Proveer el contexto a los componentes hijos
  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
