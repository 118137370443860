import React, { useContext } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useParams, Link } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import { useProducts } from "../../Context/ProductsContext"; // Importa el contexto de productos
import { Carousel } from "@material-tailwind/react";

function InfoProductById() {
  const { addToCart, cartItems } = useContext(CartContext); // Usar el contexto del carrito
  const { products } = useProducts(); // Usar el contexto de productos
  const { id } = useParams(); // Obtiene el ID del producto desde la URL
  const product = products.find((p) => p.id === parseInt(id)); // Encuentra el producto por ID

  // Filtra productos que no sean el seleccionado
  const otherProducts = products.filter((p) => p.id !== parseInt(id));

  return (
    <>
      <Navbar />
      <div className="w-full flex flex-col items-center justify-center mt-24">
        {product ? (
          <div className="w-[80%] bg-white p-8 rounded-lg">
            <div className="w-full flex flex-col justify-center items-center">
              <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
              <Carousel
                autoplay={true}
                loop={true}
                nextArrow={false}
                prevArrow={false}
                className="w-full h-full"
              >
                {product.images && product.images.length > 0 ? (
                  product.images.map((imgSrc, imgIndex) => (
                    <div key={imgIndex} className="w-full flex justify-center">
                      <img
                        src={imgSrc}
                        alt={`${product.name} image ${imgIndex + 1}`}
                        className="h-64 object-contain mb-10"
                      />
                    </div>
                  ))
                ) : (
                  <div>No Image Available</div>
                )}
              </Carousel>
            </div>
            <p className="text-lg mb-4">{product.description}</p>
            <p className="text-lg mb-4 font-bold">${product.price}</p>

            {/* Información adicional del producto */}
            <div className="grid grid-cols-2 gap-4">
              <div className="border p-4 rounded-lg">
                <h3 className="text-xl font-bold mb-2">
                  Solicitar información:
                </h3>
                <input
                  type="text"
                  placeholder="Nombre"
                  className="border border-gray-300 px-4 py-2 mb-2 w-full"
                />
                <input
                  type="email"
                  placeholder="Correo"
                  className="border border-gray-300 px-4 py-2 mb-2 w-full"
                />
                <textarea
                  id="content"
                  rows={5}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline `}
                  placeholder="Cuentanos que duda tienes, o que necesitas para la cotización del producto."
                ></textarea>
                <button className="bg-blue-600 text-white px-4 py-2 rounded-lg">
                  Enviar
                </button>
              </div>
              <div className="border p-4 rounded-lg">
                <h3 className="text-xl font-bold mb-2">
                  Lo que tienes que saber:
                </h3>
                <p>{product.more_information}</p>
              </div>
              <div className="w-full flex justify-between">
                <button
                  onClick={() => addToCart(product)} // Agregar a cotizar
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg"
                >
                  Agregar a cotizar
                </button>
                <Link to="/stock-cart">
                  <button className="bg-blue-600 text-white px-4 py-2 rounded-lg relative">
                    Carrito
                    {cartItems.length >= 0 && (
                      <span className="absolute top-0 right-0 left-16 inline-block w-6 h-6 text-center text-white bg-red-600 rounded-full">
                        {cartItems.length}
                      </span>
                    )}
                  </button>
                </Link>
              </div>
            </div>
            {/* Otros productos que te pueden interesar */}
            <div className="w-full mt-12">
              <h2 className="text-2xl font-bold mb-4">
                Otros productos que te pueden interesar
              </h2>
              <div className="grid grid-cols-4 gap-4">
                {otherProducts.map((item) => (
                  <div
                    key={item.id}
                    className="border p-4 rounded-lg bg-[#0E4980] flex flex-col items-center"
                  >
                    <div className="bg-[#C2DBF2] w-full flex flex-col justify-center items-center rounded-md">
                      <Carousel
                        autoplay={true}
                        loop={true}
                        nextArrow={false}
                        prevArrow={false}
                        className="w-full h-full"
                      >
                        {item.images && item.images.length > 0 ? (
                          item.images.map((imgSrc, imgIndex) => (
                            <div
                              key={imgIndex}
                              className="w-full flex justify-center"
                            >
                              <img
                                src={imgSrc}
                                alt={`${item.name} image ${imgIndex + 1}`}
                                className="h-60 object-contain mb-10"
                              />
                            </div>
                          ))
                        ) : (
                          <div>No Image Available</div>
                        )}
                      </Carousel>
                    </div>
                    <br />
                    <div className="w-full h-16 flex justify-start">
                      <h3 className="text-lg text-white font-semibold mb-4">
                        {item.name}
                      </h3>
                    </div>
                    <div className="w-full flex  justify-start gap-5">
                      <Link
                        to={`/stock-devices/${item.id}`}
                        className="bg-blue-700 text-white px-4 py-2 rounded-lg"
                      >
                        Ver más
                      </Link>
                      <button
                        onClick={() => addToCart(item)} // Agregar a cotizar
                        className="bg-blue-300 text-white px-4 py-2 rounded-lg"
                      >
                        Agregar a cotizar
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Botón para volver a la página anterior */}
            <div className="w-full">
              <Link to="/stock-devices">
                <button className="w-full bg-blue-600 text-white px-4 py-2 mt-2 rounded-b-md">
                  Volver
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <p>Producto no encontrado</p>
        )}
      </div>

      <Footer />
    </>
  );
}

export default InfoProductById;
