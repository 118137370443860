import React, { useState, useEffect, useRef, useContext } from "react";
import { ThemeContext } from "../../Context/ThemeContext";
import "./aliadosFabricantes.css";
import { useContentHome } from "../../Context/AuthContentHomeContext";

function AliadosFabricantes() {
  const { darkMode } = useContext(ThemeContext); //variable de estado para asignar estilos del modo oscuro/claro
  const [isVisible, setIsVisible] = useState(false); //estado para la visibilidad del contenedor
  const containerRef = useRef(null); //referencia al contenedor para el Intersection Observer
  const { manufactures, getManufactures } = useContentHome(); // Obtiene funciones y datos del contexto
  const { manufacturesImages, getManufacturesImages } = useContentHome(); // Obtiene funciones y datos del contexto
  const { alliesImages, getAlliesImages } = useContentHome();

  // Hook de efecto que se ejecuta al montar el componente, carga el contenido inicial
  useEffect(() => {
    getManufactures();
    getManufacturesImages();
    getAlliesImages();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true); //actualiza la visibilidad cuando el contenedor es visible
            observer.unobserve(entry.target); //deja de observar una vez que es visible
          }
        });
      },
      { threshold: 0.1 } //observa cuando el 10% del contenedor es visible
    );

    if (containerRef.current) {
      observer.observe(containerRef.current); //empieza a observar el contenedor
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current); //deja de observar al desmontar
      }
    };
  }, []);

  return (
    <>
      <div
        className={`al-ma-co w-full pt-[10%] ${darkMode ? "bg-gray-800 text-white" : ""}`}
      >
        <div className="w-full flex justify-center bg-[#0c4a8000]">
          <div className={`contF w-[80%] flex flex-col`} ref={containerRef}>
            {manufactures.map((manufactureInfo, index) => (
              <div key={index}>
                <div className="w-full mb-8">
                  <h1
                    className={`titleA text-4xl mb-4 text-[#0e4980] font-semibold ${
                      darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                    }`}
                  >
                    {manufactureInfo.title}
                  </h1>
                  <h2
                    className={`title2 text-2xl mb-4 text-[#0e4980] font-semibold ${
                      darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                    }`}
                  >
                    {manufactureInfo.sub_title}
                  </h2>
                  <p
                    className={`conten w-[100%] text-[#0c2645] text-lg mt-4 ${
                      darkMode ? "bg-gray-800 text-white" : ""
                    }`}
                  >
                    {manufactureInfo.description}
                  </p>
                </div>
              </div>
            ))}
            <div
              className={`w-full ${
                isVisible
                  ? "animate-fade-right animate-once animate-duration-[4000ms] animate-normal animate-fill-backwards"
                  : ""
              }`}
            >
              <div
                className={`w-full h-[50px] bg-[#0E4980] p-2 rounded-t-md ${
                  darkMode ? "bg-gray-900 text-white" : ""
                }`}
              >
                {manufactures.map((manufactureInfo, index) => (
                  <h1 key={index} className={"text-2xl text-[#ffffff] font-semibold"}>
                    {manufactureInfo.title_manufactures}
                  </h1>
                ))}
              </div>
              <div
                className={`grid grid-cols-7 ${
                  darkMode ? "bg-gray-600 text-white" : "bg-[#cae3f8]"
                }`}
              >
                {manufacturesImages.map((logo, index) => (
                  <div
                    key={index}
                    className="img-hover-container flex items-center justify-center rounded-md"
                  >
                    <img
                      src={darkMode ? logo.image_dark_manufactures : logo.image_manufactures}
                      className="w-[80%] h-[80%] rounded-md"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`w-full mt-8 ${
                isVisible
                  ? "animate-fade-left animate-once animate-duration-[4000ms] animate-normal animate-fill-backwards"
                  : ""
              }`}
            >
              <div
                className={`w-full h-[50px] bg-[#0E4980] p-2 rounded-t-md ${
                  darkMode ? "bg-gray-900 text-white" : ""
                }`}
              >
                {manufactures.map((manufactureInfo, index) => (
                  <h1 key={index} className={"text-2xl text-[#ffffff] font-semibold"}>
                    {manufactureInfo.title_allies}
                  </h1>
                ))}
              </div>
              <div
                className={`grid grid-cols-7 ${
                  darkMode ? "bg-gray-600 text-white" : "bg-[#cae3f8]"
                }`}
              >
                {alliesImages.map((logo, index) => (
                  <div
                    key={index}
                    className="img-hover-container flex items-center justify-center rounded-md"
                  >
                    <img
                      src={darkMode ? logo.image_dark_allies : logo.image_allies}
                      className="w-[80%] h-[80%] rounded-md"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AliadosFabricantes;
