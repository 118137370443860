// Importar módulos y hooks necesarios de React
import { createContext, useContext, useEffect, useState } from "react";

// Importar funciones para manejar la autenticación del superadministrador desde la API
import {
  loginRequestSuperAdmin,
  protectedRouteSuperAdmin,
  registerRequestSuperAdmin,
  verifyTokenRequestSuperAdmin,
} from "../api/authUser";

// Crear un contexto para manejar la autenticación del superadministrador
export const AuthSuperAdminContext = createContext();

// Hook personalizado para usar el contexto de autenticación del superadministrador
export const useSuperAdminAuth = () => {
  const context = useContext(AuthSuperAdminContext);

  // Si el contexto no está disponible, lanzar un error
  if (!context) {
    throw new Error(
      "useSuperAdminAuth must be used within an AuthSuperAdminProvider"
    );
  }

  // Devolver el contexto de autenticación del superadministrador
  return context;
};

// Componente proveedor del contexto de autenticación del superadministrador
export const AuthSuperAdminProvider = ({ children }) => {
  // Definir estado para almacenar los datos del usuario superadministrador
  const [user, setUser] = useState(null);

  // Estado para verificar si el superadministrador está autenticado
  const [isAuthenticatedSuperAdmin, setIsAuthenticatedSuperAdmin] =
    useState(false);

  // Estado para almacenar los errores relacionados con el usuario
  const [userErrors, setUserErrors] = useState([]);

  // Estado para manejar el indicador de carga durante las operaciones de autenticación
  const [loadingSuperAdmin, setLoadingSuperAdmin] = useState(true);

  // Estado para manejar una lista de administradores
  const [administrators, setAdministrators] = useState([]);

  // Estado para almacenar las credenciales de un administrador
  const [administratorCrdential, setAdministratorCredential] = useState(null);

  //registro de usuario
  const signup = async (user) => {
    try {
      const res = await registerRequestSuperAdmin(user);
      if (res.data === 200) {
        setUser(res.data);
        setIsAuthenticatedSuperAdmin(true);
      }
    } catch (error) {
      setUserErrors(error);
      console.log(error);
    }
  };

  //inicio de sesión
  const signin = async (user) => {
    try {
      const res = await loginRequestSuperAdmin(user);
      if (res.data.token) {
        localStorage.setItem("Supertoken", res.data.token);
        setIsAuthenticatedSuperAdmin(true);
        setUser(res.data);
        console.log(res);
      } else {
        setIsAuthenticatedSuperAdmin(false);
        setUserErrors(["Correo o contraseña invalidos*"]);
      }
    } catch (error) {
      console.log(error);
      if (Array.isArray(error.response.data)) {
        return setUserErrors(error.response.data);
      }
      setUserErrors([error.response.data.message]);
    }
  };

  //cerrar sesión
  const logout = () => {
    localStorage.removeItem("Supertoken");
    setIsAuthenticatedSuperAdmin(false);
    setUser(null);
  };

  //traer todos los administradores
  const getAllAdministrators = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get("/allAdministrators");
      setAdministrators(res.data.Administrators); // Asegúrate de acceder al array correcto
    } catch (error) {
      console.log(error);
    }
  };
  //traer todos los administradores
  const getAdministratorsById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(`/administrators/${id}`);
      console.log("Administrador obtenido:", res.data);
      setAdministratorCredential(res.data); // Establece el administrador en el estado
    } catch (error) {
      console.error("Error al obtener el administrador:", error);
    }
  };

  // Actualizar datos del usuario
  const updateCredentialsAdministrators = async (id, userData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/credentialdAdministratorUpdate/${id}`,
        userData
      );
      if (res.data) {
        setAdministratorCredential(res.data); // Actualiza los datos del usuario en el estado
        console.log("Usuario actualizado", res.data);
      }
    } catch (error) {
      setUserErrors(["Error al actualizar los datos del usuario"]);
      console.log(error);
    }
  };

  //verificación de errores
  useEffect(() => {
    if (userErrors.length > 0) {
      const timer = setTimeout(() => {
        setUserErrors([]);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [userErrors]);

  //verificación del inicio de sesión
  useEffect(() => {
    const checkLogin = async () => {
      const token = localStorage.getItem("Supertoken");
      if (!token) {
        setIsAuthenticatedSuperAdmin(false);
        setLoadingSuperAdmin(false);
        return setUser(null);
      }

      try {
        const res = await verifyTokenRequestSuperAdmin(token);
        // console.log(res);
        if (!res.data) {
          setIsAuthenticatedSuperAdmin(false);
          setLoadingSuperAdmin(false);
          return;
        }

        setIsAuthenticatedSuperAdmin(true);
        setUser(res.data);
        setLoadingSuperAdmin(false);
      } catch (error) {
        console.log(error);
        setIsAuthenticatedSuperAdmin(false);
        setUser(null);
        setLoadingSuperAdmin(false);
      }
    };
    checkLogin();
  }, []);

  return (
    <AuthSuperAdminContext.Provider
      value={{
        signup,
        signin,
        logout,
        loadingSuperAdmin,
        user,
        isAuthenticatedSuperAdmin,
        userErrors,
        administrators,
        getAllAdministrators,
        getAdministratorsById,
        updateCredentialsAdministrators,
        administratorCrdential,
      }}
    >
      {children}
    </AuthSuperAdminContext.Provider>
  );
};
