// Importar los módulos y hooks necesarios de React
import { createContext, useContext, useState } from "react";

// Importar la función para hacer peticiones protegidas
import { protectedRoute } from "../api/authUser";

// Crear un contexto para manejar el contenido de la página de inicio
const ContentHomeContext = createContext();

// Crear un hook personalizado para utilizar el contexto del contenido de la página de inicio
export const useContentHome = () => {
  const context = useContext(ContentHomeContext);

  // Si el contexto no está disponible, lanzar un error
  if (!context) {
    throw new Error("useContentHome must be used within a ContentHomeProvider");
  }

  // Devolver el contexto
  return context;
};

// Componente proveedor del contexto para el contenido de la página de inicio
export const ContentHomeProvider = ({ children }) => {
  // Definir estados para manejar los diferentes tipos de contenido
  const [sliders, setSliders] = useState([]); // Estado para los sliders
  const [manufactures, setManufactures] = useState([]); // Estado para los fabricantes
  const [manufacturesImages, setManufacturesImages] = useState([]); // Estado para las imágenes de los fabricantes
  const [alliesImages, setAlliesImages] = useState([]); // Estado para las imágenes de los aliados
  const [cycleContent, setCycleContent] = useState([]); // Estado para el contenido del ciclo
  const [cycleImages, setCycleImages] = useState([]); // Estado para las imágenes del ciclo
  const [servicesTi, setServicesTi] = useState([]); // Estado para los servicios TI
  const [content, setContent] = useState([]); // Estado para el contenido general de la página de inicio
  const [slideBlog, setSlideBlog] = useState([]); // Estado para los slides del blog

  //Sliders content
  const getSliders = async () => {
    try {
      const res = await protectedRoute().get(`/sliders`);
      setSliders(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSlider = async (id) => {
    try {
      const res = await protectedRoute().get(`/sliders/${id}`);
      const sliderData = res.data;
      return sliderData;
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  const createSliders = async (sliderData) => {
    try {
      const res = await protectedRoute().post("/sliders", sliderData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateSliders = async (id, sliderData) => {
    try {
      const res = await protectedRoute().put(`/sliders/${id}`, sliderData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesSliders = async (id) => {
    try {
      const res = await protectedRoute().delete(`/sliders/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //Manufactures content
  const getManufactures = async () => {
    try {
      const res = await protectedRoute().get(`/manufactures_and_allies`);
      setManufactures(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getManufacture = async (id) => {
    try {
      const res = await protectedRoute().get(`/manufactures_and_allies/${id}`);
      const manufactureData = res.data;
      return manufactureData;
    } catch (error) {
      console.error("Error fetching manufacture:", error);
    }
  };

  const createManufactures = async (manufactureData) => {
    try {
      const res = await protectedRoute().post(
        "/manufactures_and_allies",
        manufactureData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateManufactures = async (id, manufactureData) => {
    try {
      const res = await protectedRoute().put(
        `/manufactures_and_allies/${id}`,
        manufactureData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesManufactures = async (id) => {
    try {
      const res = await protectedRoute().delete(
        `/manufactures_and_allies/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //Manufactures images
  const getManufacturesImages = async () => {
    try {
      const res = await protectedRoute().get(`/manufactures_images`);
      setManufacturesImages(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getManufactureImages = async (id) => {
    try {
      const res = await protectedRoute().get(`/manufactures_images/${id}`);
      const manufactureData = res.data;
      return manufactureData;
    } catch (error) {
      console.error("Error fetching manufacture:", error);
    }
  };

  const createManufacturesImages = async (manufactureData) => {
    try {
      const res = await protectedRoute().post(
        "/manufactures_images",
        manufactureData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateManufacturesImages = async (id, manufactureData) => {
    try {
      const res = await protectedRoute().put(
        `/manufactures_images/${id}`,
        manufactureData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesManufacturesImages = async (id) => {
    try {
      const res = await protectedRoute().delete(`/manufactures_images/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //Allies images
  const getAlliesImages = async () => {
    try {
      const res = await protectedRoute().get(`/allies_images`);
      setAlliesImages(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllieImages = async (id) => {
    try {
      const res = await protectedRoute().get(`/allies_images/${id}`);
      const allieData = res.data;
      return allieData;
    } catch (error) {
      console.error("Error fetching allie:", error);
    }
  };

  const createAlliesImages = async (allieData) => {
    try {
      const res = await protectedRoute().post("/allies_images", allieData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateAlliesImages = async (id, allieData) => {
    try {
      const res = await protectedRoute().put(
        `/allies_images/${id}`,
        allieData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesAlliesImages = async (id) => {
    try {
      const res = await protectedRoute().delete(`/allies_images/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //CService ti
  const getServicesTi = async () => {
    try {
      const res = await protectedRoute().get(`/services-ti`);
      setServicesTi(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getServicesTiById = async (id) => {
    try {
      const res = await protectedRoute().get(`/services-ti/${id}`);
      const serviceTiData = res.data;
      return serviceTiData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createServicesTi = async (serviceTiData) => {
    try {
      const res = await protectedRoute().post("/services-ti", serviceTiData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateServicesTi = async (id, serviceTiData) => {
    try {
      const res = await protectedRoute().put(
        `/services-ti/${id}`,
        serviceTiData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesServicesTi = async (id) => {
    try {
      const res = await protectedRoute().delete(`/services-ti/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //first Content
  const getContent = async () => {
    try {
      const res = await protectedRoute().get(`/content-home`);
      setContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getContentById = async (id) => {
    try {
      const res = await protectedRoute().get(`/content-home/${id}`);
      const sliderData = res.data;
      return sliderData;
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  const createContent = async (sliderData) => {
    try {
      const res = await protectedRoute().post("/content-home", sliderData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateContent = async (id, sliderData) => {
    try {
      const res = await protectedRoute().put(
        `/content-home/${id}`,
        sliderData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //Slide Home Blog
  const getSlideBlogHome = async () => {
    try {
      const res = await protectedRoute().get(`/slide-blog-home`);
      setSlideBlog(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSlideBlogHomeById = async (id) => {
    try {
      const res = await protectedRoute().get(`/slide-blog-home/${id}`);
      const sliderData = res.data;
      return sliderData;
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  const createSlideBlogHome = async (sliderData) => {
    try {
      const res = await protectedRoute().post("/slide-blog-home", sliderData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateSlideBlogHome = async (id, sliderData) => {
    try {
      const res = await protectedRoute().put(
        `/slide-blog-home/${id}`,
        sliderData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContentHomeContext.Provider
      value={{
        sliders,
        getSlider,
        getSliders,
        createSliders,
        updateSliders,
        deletesSliders,
        manufactures,
        getManufacture,
        getManufactures,
        createManufactures,
        updateManufactures,
        deletesManufactures,
        manufacturesImages,
        getManufactureImages,
        getManufacturesImages,
        createManufacturesImages,
        updateManufacturesImages,
        deletesManufacturesImages,
        alliesImages,
        getAllieImages,
        getAlliesImages,
        createAlliesImages,
        updateAlliesImages,
        deletesAlliesImages,
        servicesTi,
        getServicesTiById,
        getServicesTi,
        createServicesTi,
        updateServicesTi,
        deletesServicesTi,
        content,
        getContent,
        getContentById,
        createContent,
        updateContent,
        slideBlog,
        getSlideBlogHomeById,
        getSlideBlogHome,
        createSlideBlogHome,
        updateSlideBlogHome,
      }}
    >
      {children}
    </ContentHomeContext.Provider>
  );
};
