import React, { useContext, useEffect } from "react"; // Importa React, useContext para usar el contexto de tema y useEffect para manejar efectos secundarios
import "slick-carousel/slick/slick.css"; // Importa los estilos CSS necesarios para el carrusel de Slick
import "slick-carousel/slick/slick-theme.css"; // Importa el tema del carrusel de Slick
import Slider from "react-slick"; // Importa el componente Slider de react-slick para crear el carrusel
import { ThemeContext } from "../../Context/ThemeContext"; // Importa el contexto de tema para cambiar entre modo oscuro y claro
import "./serviciosTi.css"; // Importa los estilos CSS específicos de este componente
import { useContentHome } from "../../Context/AuthContentHomeContext"; // Importa el contexto para manejar los servicios de TI

function ServiciosTiNegocio() {
  const { darkMode } = useContext(ThemeContext); // Obtiene el estado del modo oscuro del contexto de tema
  const { servicesTi, getServicesTi } = useContentHome(); // Obtiene los servicios de TI y la función para cargarlos del contexto

  useEffect(() => {
    getServicesTi(); // Llama a la función para obtener los servicios de TI cuando el componente se monta
  }, []); // El array vacío asegura que el efecto solo se ejecute una vez cuando el componente se monta

  // Configuración del carrusel de Slick
  const settings = {
    dots: true, // Muestra puntos de navegación
    infinite: true, // Hace que el carrusel sea infinito
    slidesToShow: 1, // Muestra un slide a la vez
    slidesToScroll: 1, // Desplaza un slide a la vez
    vertical: true, // Desplaza verticalmente
    autoplay: true, // Activa el desplazamiento automático
    autoplaySpeed: 4000, // Establece la velocidad del desplazamiento automático a 4 segundos
    verticalSwiping: true, // Permite el deslizamiento vertical con el mouse
    arrows: false, // Oculta las flechas de navegación
    pauseOnHover: false, // No pausa el desplazamiento cuando se pasa el mouse por encima
  };

  return (
    <>
      <div
        className={`full w-full ${darkMode ? "bg-gray-800 text-white" : ""}`}
      >
        <div className="con w-full flex justify-center">
          <div className="w-[80%]">
            <div className="cont flex justify-center">
              <h1
                className={`title text-4xl mb-4 text-[#0e4980] font-semibold w-[60%] text-center ${
                  darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                }`}
              >
                Productos de TI pensados específicamente en su negocio.
                 Busquemos juntos la mejor opción
              </h1>
            </div>
            <div>
              <Slider {...settings}>
                {servicesTi.map((product) => (
                  <div
                    className="py-8 bg-[#beddf900] rounded-md"
                    key={product.id}
                  >
                    <div className="w-[80%] ml-[10%]">
                      <div className="cont flex flex-col items-center justify-center">
                        <h1
                          className={`text-[#0e4980] text-2xl font-semibold ${
                            darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                          }`}
                        >
                          {product.sub_title}
                        </h1>
                        <img
                          className="slide-img h-[450px]"
                          src={product.images_services_ti}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiciosTiNegocio;
