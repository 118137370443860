import React, { useEffect, useState, useRef, useContext } from "react";
import "./blog.css";
import { useBlog } from "../../Context/BlogContext";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { ThemeContext } from "../../Context/ThemeContext";
import { Parallax } from "react-parallax";
import search from "../../../public/lupa.png";
import searchDark from "../../../public/lupaDark.png";
import { useParams } from 'react-router-dom';

const BlogSlide = () => {
  const { articles, getArticles, getArticle } = useBlog();
  const [viewingArticle, setViewingArticle] = useState(null);
  const [fullArticle, setFullArticle] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda
  const articleTitleRef = useRef(null); // Ref específico para el título del artículo
  const { darkMode } = useContext(ThemeContext);
  const { articleId } = useParams(); // Obtiene el ID del artículo desde la URL

  useEffect(() => {
    getArticles();
    if (articleId) {
      handleReadMore(articleId); // Si hay un ID en la URL, carga el artículo correspondiente
    }
  }, [getArticles, articleId]);

  const handleReadMore = async (id) => {
    const article = await getArticle(id);
    setFullArticle(article);
    setViewingArticle(id);
    
    setTimeout(() => {
      const titleElement = document.getElementById("article-title");
      if (titleElement) {
        titleElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 100000000);
  };

  const truncateContent = (content) => {
    const lines = content.split("\n");
    const truncatedLines = lines.slice(0, 6).join("\n");
    return truncatedLines.length > 100
      ? truncatedLines.substring(0, 100) + "..."
      : truncatedLines;
  };

  // Filtrar los artículos según el término de búsqueda en título, contenido, categoría y fecha
  const filteredArticles = articles.filter((article) => {
    const searchLower = searchTerm.toLowerCase();
    const titleMatch = article.title.toLowerCase().includes(searchLower);
    const contentMatch = article.content.toLowerCase().includes(searchLower);
    const categoryMatch = article.category.toLowerCase().includes(searchLower);
    const dateMatch = new Date(article.created_at)
      .toLocaleDateString()
      .includes(searchTerm);

    return titleMatch || contentMatch || categoryMatch || dateMatch;
  });

  return (
    <>
      <Navbar />
      <Parallax
        bgImage="https://bairesdev.mo.cloudinary.net/blog/2022/02/tech-stacks-1.jpg?tx=w_1920,q_auto"
        strength={50}
      >
        <div
          className={`principal bg-black/35 flex w-full justify-between ${
            darkMode ? "text-white" : ""
          }`}
        >
          <div className="txt flex flex-col justify-center items-center w-full rounded-r-full h-[55vh]">
            <h1 className={`text-8xl pb-4 ${darkMode ? "text-white" : "text-white"}`}>GTI Blog</h1>
            <h2 className={`text-2xl ${darkMode ? "text-white" : "text-white"}`}>Artículos de tecnología y temas de interés</h2>
          </div>
        </div>
      </Parallax>
      <div
        className={`w-full flex flex-col items-center justify-center ${
          darkMode
            ? "bg-[#424242] text-[#ffffff]"
            : "bg-[#F1F8FE] text-[#0e4980]"
        }`}
      >
        {!viewingArticle && (
          <div
            className={`pt-[2%] w-full flex justify-center ${
              darkMode
                ? "bg-[#424242] text-[#ffffff]"
                : "bg-[#F1F8FE] text-[#0e4980]"
            }`}
          >
            <div className="search w-[80%] flex gap-5">
              <input
                type="text"
                placeholder="Buscar artículos..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`w-[40%] search-input p-2 rounded-md ${
                  darkMode ? "text-[#000000]" : "text-[#0e4980]"
                }`}
              />
              <img
                src={darkMode ? searchDark : search}
                className="w-[4%]"
                alt="search"
              />
            </div>
          </div>
        )}

        {viewingArticle ? (
          <div className="article-container">
          <h2 id="article-title" className="article-title">{fullArticle.title}</h2>
          <p className="article-date">
            {new Date(fullArticle.created_at).toLocaleDateString()}
          </p>
          <p className="article-category">{fullArticle.category}</p>
          <img
            src={fullArticle.image}
            alt={fullArticle.title}
            className="article-image"
          />
          <p className="article-content">{fullArticle.content}</p>
          <button className="back-button">
            <a href="/blog">Volver a los artículos</a>
          </button>
        </div>
        ) : (
          <div className="blog-container">
            {filteredArticles.map((article, index) => (
              <div
                key={article.id}
                className={`blog-card ${
                  index % 3 === 2 ? "blog-card-wide" : ""
                } ${
                  darkMode
                    ? "bg-gray-900 text-[#ffffff]"
                    : "bg-[#FFFF] text-[#0e4980]"
                }`}
              >
                <img
                  src={article.image}
                  alt={article.title}
                  className="blog-image"
                />
                <div className="blog-content">
                  <h2 className="blog-title">{article.title}</h2>
                  <p className="blog-date">
                    {new Date(article.created_at).toLocaleDateString()}
                  </p>
                  <p className="blog-category">{article.category}</p>
                  <p className="blog-text">{truncateContent(article.content)}</p>
                  <span
                    onClick={() => handleReadMore(article.id)}
                    className="read-more-text"
                  >
                    Leer más
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default BlogSlide;
