import axios from "axios"; // Importa la biblioteca Axios

// Crea una instancia personalizada de Axios con configuración específica
const instance = axios.create({
  baseURL: "https://appser-gti-bk-prod-fwe0e3e9ftaghzeq.eastus2-01.azurewebsites.net", // Define la URL base para todas las solicitudes
  //baseURL: "http://localhost:10101", // (Comentar o descomentar esta línea para usar la URL local en lugar de la de producción)
  withCredentials: true, // Incluye cookies y credenciales en las solicitudes
});

export default instance; // Exporta la instancia para usarla en otras partes de la aplicación
