// Importar axios y una instancia personalizada de axios
import axios from "axios";
import instance from "./axios.js";

// Función para crear una instancia de axios con autenticación protegida
export const protectedRoute = () => {
  // Obtener el token de autenticación desde el almacenamiento local
  const token = localStorage.getItem("token");

  if (token) {
    // Si el token existe, crear una instancia de axios con el token en los encabezados
    const clienteAxios = instance.create({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return clienteAxios;
  } else {
    // Si el token no existe, crear una instancia de axios con 'Bearer null' en los encabezados
    const clienteAxios = instance.create({
      headers: {
        authorization: `Bearer null`,
      },
    });
    return clienteAxios;
  }
};

// Función para crear una instancia de axios con autenticación protegida para superadministradores
export const protectedRouteSuperAdmin = () => {
  // Obtener el token de superadministrador desde el almacenamiento local
  const token = localStorage.getItem("Supertoken");

  if (token) {
    // Si el token existe, crear una instancia de axios con el token en los encabezados
    const clienteAxios = instance.create({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return clienteAxios;
  } else {
    // Si el token no existe, crear una instancia de axios con 'Bearer null' en los encabezados
    const clienteAxios = instance.create({
      headers: {
        authorization: `Bearer null`,
      },
    });
    return clienteAxios;
  }
};

// Funciones para hacer solicitudes a la API relacionadas con el registro y la autenticación de administradores

export const registerRequest = (user) =>
  instance.post("/registerAdministrator", user);
export const updateCredentialsRequest = (id, user) =>
  instance.put(`/update-credentials/${id}`, user);
export const loginRequest = (user) =>
  instance.post("/loginAdministrator", user);
export const verifyTokenRequest = () => protectedRoute().get("/verify");

// Funciones para hacer solicitudes a la API relacionadas con el registro y la autenticación de superadministradores

export const registerRequestSuperAdmin = (user) =>
  instance.post("/registerSuperAdmin", user);
export const loginRequestSuperAdmin = (user) =>
  instance.post("/loginSuperAdmin", user);
export const verifyTokenRequestSuperAdmin = () =>
  protectedRouteSuperAdmin().get("/verify-superAdmin");
