// Importar los módulos y hooks necesarios de React
import { createContext, useContext, useState } from "react";

// Importar la función para hacer peticiones protegidas para el superadministrador
import { protectedRouteSuperAdmin } from "../api/authUser";

// Crear un contexto para manejar el contenido de "Sobre Nosotros" para el superadministrador
const ContentAboutUsContextSuperAdmin = createContext();

// Crear un hook personalizado para utilizar el contexto del superadministrador
export const useContentAboutUsSuperAdmin = () => {
  const context = useContext(ContentAboutUsContextSuperAdmin);

  // Si el contexto no está disponible, lanzar un error
  if (!context) {
    throw new Error(
      "useContentAboutUs must be used within a ContentAboutUsProvider"
    );
  }

  // Devolver el contexto
  return context;
};

// Componente proveedor del contexto para el superadministrador
export const ContentAboutUsSuperAdminProvider = ({ children }) => {
  // Definir estados para manejar los diferentes tipos de contenido
  const [firstContent, setFirstContent] = useState([]); // Estado para el contenido principal
  const [subContent, setSubContent] = useState([]); // Estado para el subcontenido
  const [missionAndVissionContent, setMissionAndVissionContent] = useState([]); // Estado para la misión y visión
  const [valuesContent, setValuesContent] = useState([]); // Estado para los valores
  const [membershipsContent, setMembershipsContent] = useState([]); // Estado para las membresías
  const [policyContent, setPolicyContent] = useState([]); // Estado para las políticas
  const [cycleImages, setCycleImages] = useState([]); // Estado para las imágenes del ciclo
  const [cycleContent, setCycleContent] = useState([]); // Estado para el contenido del ciclo

  // content about us
  const getFirstContent = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get(`/content-about-us`);
      setFirstContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFirstContentById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/content-about-us/${id}`
      );
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createFirstContent = async (contentData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/content-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateFirstContent = async (id, contentData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-content-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // sub content about us
  const getSubContent = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get(`/sub-content-about-us`);
      setSubContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSubContentById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/sub-content-about-us/${id}`
      );
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createSubContent = async (contentData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/sub-content-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateSubContent = async (id, contentData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-sub-content-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // sub content about us
  const getMissionAndVissionContent = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/mission-vission-about-us`
      );
      setMissionAndVissionContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMissionAndVissionContentById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/mission-vission-about-us/${id}`
      );
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createMissionAndVissionContent = async (contentData) => {
    try {
      const res = await protectedRoute().post(
        "/mission-vission-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateMissionAndVissionContent = async (id, contentData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-mission-vission-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // values content about us
  const getValuesContent = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get(`/values-about-us`);
      setValuesContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getValuesContentById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/values-about-us/${id}`
      );
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createValuesContent = async (contentData) => {
    try {
      const res = await protectedRoute().post("/values-about-us", contentData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateValuesContent = async (id, contentData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-values-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // memberships content about us
  const getMembershipsContent = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/membershipsImages-about-us`
      );
      setMembershipsContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMembershipsContentById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/membershipsImages-about-us/${id}`
      );
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createMembershipsContent = async (contentData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/super-admin-membershipsImages-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateMembershipsContent = async (id, contentData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-membershipsImages-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMembershipsContent = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().delete(
        `/super-admin-membershipsImages-about-us/${id}`
      );
      return res.data;
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  // quality policy content about us
  const getPolicyContent = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/qualityPolicy-about-us`
      );
      setPolicyContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPolicyContentById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/qualityPolicy-about-us/${id}`
      );
      const contentData = res.data;
      return contentData;
    } catch (error) {
      console.error("Error fetching serviceTi:", error);
    }
  };

  const createPolicyContent = async (contentData) => {
    try {
      const res = await protectedRoute().post(
        "/qualityPolicy-about-us",
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updatePolicyContent = async (id, contentData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-qualityPolicy-about-us/${id}`,
        contentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  // Cycle experience content
  const getCycleExperienceImages = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/images-cycle-experience`
      );
      setCycleImages(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCycleExperienceImagesById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/images-cycle-experience/${id}`
      );
      const cycleData = res.data;
      return cycleData;
    } catch (error) {
      console.error("Error fetching cycle:", error);
    }
  };

  const createCycleExperienceImages = async (cycleData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/images-cycle-experience",
        cycleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateCycleExperienceImages = async (id, cycleData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-images-cycle-experience/${id}`,
        cycleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesCycleExperienceImages = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().delete(
        `/images-cycle-experience/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  //Cycle experience content
  const getCycleExperienceContent = async () => {
    try {
      const res = await protectedRouteSuperAdmin().get(`/cycle-experience`);
      setCycleContent(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCycleExperienceContentById = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().get(
        `/cycle-experience/${id}`
      );
      const cycleData = res.data;
      return cycleData;
    } catch (error) {
      console.error("Error fetching cycle:", error);
    }
  };

  const createCycleExperienceContent = async (cycleData) => {
    try {
      const res = await protectedRouteSuperAdmin().post(
        "/cycle-experience",
        cycleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateCycleExperienceContent = async (id, cycleData) => {
    try {
      const res = await protectedRouteSuperAdmin().put(
        `/super-admin-cycle-experience/${id}`,
        cycleData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  const deletesCycleExperienceContent = async (id) => {
    try {
      const res = await protectedRouteSuperAdmin().delete(
        `/cycle-experience/${id}`
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContentAboutUsContextSuperAdmin.Provider
      value={{
        firstContent,
        getFirstContentById,
        getFirstContent,
        createFirstContent,
        updateFirstContent,
        subContent,
        getSubContentById,
        getSubContent,
        createSubContent,
        updateSubContent,
        missionAndVissionContent,
        getMissionAndVissionContentById,
        getMissionAndVissionContent,
        createMissionAndVissionContent,
        updateMissionAndVissionContent,
        valuesContent,
        getValuesContentById,
        getValuesContent,
        createValuesContent,
        updateValuesContent,
        membershipsContent,
        getMembershipsContent,
        getMembershipsContentById,
        createMembershipsContent,
        updateMembershipsContent,
        deleteMembershipsContent,
        policyContent,
        getPolicyContent,
        getPolicyContentById,
        createPolicyContent,
        updatePolicyContent,
        cycleImages,
        getCycleExperienceImages,
        getCycleExperienceImagesById,
        createCycleExperienceImages,
        updateCycleExperienceImages,
        deletesCycleExperienceImages,
        cycleContent,
        getCycleExperienceContent,
        getCycleExperienceContentById,
        createCycleExperienceContent,
        updateCycleExperienceContent,
        deletesCycleExperienceContent,
      }}
    >
      {children}
    </ContentAboutUsContextSuperAdmin.Provider>
  );
};
