import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterAmin from "../../components/Footer/FooterAmin";
import NavbarAdmin from "../../components/Navbar/NavbarAdmin";
import { ThemeContext } from "../../Context/ThemeContext";
import { useContentAboutUs } from "../../Context/AuthAboutUsContext";

function QualityPolicyForm() {
  // Configuración de useForm para manejar el formulario
  const { register, handleSubmit, setValue } = useForm();

  // Desestructuración de funciones del contexto personalizado
  const { getPolicyContentById, updatePolicyContent, createPolicyContent } =
    useContentAboutUs();

  const navigate = useNavigate();
  const params = useParams();

  // Obtener el estado del modo oscuro desde el contexto
  const { darkMode } = useContext(ThemeContext);

  // Estados para manejar las imágenes y sus previsualizaciones
  const [imagePolicy, setImagePolicy] = useState(null);
  const [imagePolicyPreview, setImagePolicyPreview] = useState(null);
  const [imagePolicyDark, setImagePolicyDark] = useState(null);
  const [imagePolicyDarkPreview, setImagePolicyDarkPreview] = useState(null);

  // Estado para saber si se está actualizando un contenido existente
  const [isUpdating, setIsUpdating] = useState(false);

  // Cargar el contenido si hay un ID en los parámetros (modo edición)
  useEffect(() => {
    const loadContent = async () => {
      if (params.id) {
        try {
          const content = await getPolicyContentById(params.id);

          if (content && Object.keys(content).length > 0) {
            // Prellenar el formulario con los datos existentes
            setValue("title", content.title);
            setValue("description", content.description);

            // Configurar las previsualizaciones de las imágenes si existen
            if (content.policy_image) {
              setImagePolicyPreview(content.policy_image);
            }
            if (content.policy_dark_image) {
              setImagePolicyDarkPreview(content.policy_dark_image);
            }

            setIsUpdating(true); // Establecer que se está en modo actualización
          }
        } catch (error) {
          console.error("Error loading content:", error);
        }
      }
    };
    loadContent();
  }, [params.id, setValue, getPolicyContentById]);

  // Manejar el envío del formulario
  const onSubmit = handleSubmit(async (data) => {
    // Crear un FormData para enviar los datos del formulario y las imágenes
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);

    // Añadir las imágenes al FormData si se han seleccionado
    if (imagePolicy) {
      formData.append("policy_image", imagePolicy);
    }
    if (imagePolicyDark) {
      formData.append("policy_dark_image", imagePolicyDark);
    }

    try {
      console.log("Form data being submitted:", formData);
      if (isUpdating) {
        // Si está en modo actualización, llamar a la función de actualización
        await updatePolicyContent(params.id, formData);
      } else {
        // Si está en modo creación, llamar a la función de creación
        await createPolicyContent(formData);
      }
      navigate("/about-us-panel"); // Redirigir después de enviar el formulario
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  });

  // Manejar cambios en la imagen de la política y actualizar la previsualización
  const handleImagePolicyChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePolicy(file);
      setImagePolicyPreview(URL.createObjectURL(file));
    } else {
      setImagePolicy(null);
      setImagePolicyPreview(null);
    }
  };

  // Manejar cambios en la imagen de la política en modo oscuro y actualizar la previsualización
  const handleImagePolicyDarkChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePolicyDark(file);
      setImagePolicyDarkPreview(URL.createObjectURL(file));
    } else {
      setImagePolicyDark(null);
      setImagePolicyDarkPreview(null);
    }
  };

  return (
    <>
      <NavbarAdmin />
      <div
        className={`w-full flex justify-center h-full gap-20 ${
          darkMode ? "bg-[#424242] text-white" : "bg-gray-100"
        }`}
      >
        <div className={`h-full pt-[12%] pb-[4%]`}>
          <form
            onSubmit={onSubmit}
            className={`h-full p-6 rounded shadow-md ${
              darkMode ? "bg-[#212121]" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">
              {isUpdating ? "Actualizar Contenido" : "Añade un nuevo Contenido"}
            </h2>
            <p className="mb-4">
              LLena todos los campos para{" "}
              {isUpdating ? "actualizar" : "agregar"} el Contenido.
            </p>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="mission"
              >
                Titulo:
              </label>
              <input
                type="text"
                id="mission"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("title")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description_mission"
              >
                Descripción:
              </label>
              <textarea
                id="description"
                rows={5}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade la descripción"
                {...register("description")}
              ></textarea>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image_mission"
              >
                Imagen:
              </label>
              {imagePolicyPreview && (
                <div className="mt-4 flex justify-center">
                  <img
                    src={imagePolicyPreview}
                    alt="Preview"
                    className="w-[160px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="image_mission"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImagePolicyChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image_mission_dark"
              >
                Imagen (Modo oscuro):
              </label>
              {imagePolicyDarkPreview && (
                <div className="mt-4 flex justify-center">
                  <img
                    src={imagePolicyDarkPreview}
                    alt="Preview"
                    className="w-[160px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="image_mission_dark"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImagePolicyDarkChange}
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-[#0e4980] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isUpdating ? "Actualizar" : "Agregar"}
              </button>
              <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <Link to="/about-us-panel">Cancelar</Link>
              </button>
            </div>
          </form>
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default QualityPolicyForm;
