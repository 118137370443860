import { useContext } from "react";
import "./footer.css";  // Importa los estilos CSS específicos
import { ThemeContext } from "../../Context/ThemeContext";  // Importa el contexto de tema (modo oscuro/claro)

function FooterAmin() {
  const { darkMode } = useContext(ThemeContext); //variable de estado para asignar estilos del modo oscuro/claro

  return (
    <footer className={`${darkMode ? "bg-gray-900 text-white" : ""}`}>
      <div className="w-full flex justify-center flex-col items-center">
        <div className="w-[80%] h-[1px] bg-[#0E4980]"></div>
        <div className="mt-4 mb-4 text-center text-[#0E4980]">
          <p
            className={`font-bold ${
              darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
            }`}
          >
            Copyright © GTI
          </p>
        </div>
      </div>
    </footer>
  );
}

export default FooterAmin;
