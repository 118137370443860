import "./subContentHome.css"; // Importa los estilos CSS específicos para el componente
import "slick-carousel/slick/slick.css"; // Importa los estilos CSS necesarios para el carrusel de Slick
import "slick-carousel/slick/slick-theme.css"; // Importa el tema del carrusel de Slick
import React, { useContext, useEffect } from "react"; // Importa React, useContext para usar el contexto de tema y useEffect para manejar efectos secundarios
import { ThemeContext } from "../../Context/ThemeContext"; // Importa el contexto de tema para cambiar entre modo oscuro y claro
import Slider from "react-slick"; // Importa el componente Slider de react-slick para crear el carrusel
import { Typography } from "@material-tailwind/react"; // Importa el componente Typography de material-tailwind para el texto estilizado
import { useContentHome } from "../../Context/AuthContentHomeContext"; // Importa el contexto para manejar el contenido de la página de inicio

function SubContentHome() {
  const { darkMode } = useContext(ThemeContext); // Obtiene el estado del modo oscuro del contexto de tema
  const { sliders, getSliders } = useContentHome(); // Obtiene los sliders y la función para cargarlos del contexto

  useEffect(() => {
    getSliders(); // Llama a la función para obtener los sliders cuando el componente se monta
  }, []); // El array vacío asegura que el efecto solo se ejecute una vez cuando el componente se monta

  // Configuración del carrusel de Slick
  const settings = {
    dots: true, // Muestra puntos de navegación
    infinite: true, // Hace que el carrusel sea infinito
    speed: 2000, // Establece la velocidad de transición entre slides
    slidesToShow: 1, // Muestra un slide a la vez
    slidesToScroll: 1, // Desplaza un slide a la vez
    autoplay: true, // Activa el desplazamiento automático
    autoplaySpeed: 8000, // Establece la velocidad del desplazamiento automático a 8 segundos
    arrows: false, // Oculta las flechas de navegación
    pauseOnHover: false, // No pausa el desplazamiento cuando se pasa el mouse por encima
  };

  return (
    <>
      <div className="sliders-ti w-full h-[100vh] mb-16">
        <div
          className={`w-full flex flex-col justify-center items-center py-8 ${
            darkMode
              ? "bg-gray-900 text-[#ffffff]"
              : "bg-[#F1F8FE] text-[#0e4980]"
          }`}
        >
          <div className="titles-sliders w-[80%] flex flex-col gap-5 justify-center items-center">
            <p className="text-3xl text-center font-semibold">
              Ofrecemos valor constante a nuestros clientes, integrando
              soluciones de vanguardia, soportados en los principales lideres
              tecnológicos a nivel Mundial.
            </p>
            <h2 className="text-4xl text-center font-extrabold">
              Continuemos juntos este viaje de transformación !!{" "}
            </h2>
          </div>
        </div>
        <Slider {...settings}>
          {sliders.map((slider, index) => (
            <div key={index} className="relative w-full">
              <img
                src={slider.image_slider}
                alt={`image ${index + 1}`}
                className="h-[100vh] w-full object-cover"
              />
              <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/55">
                <div className="w-3/4 text-center md:w-2/4 lg:w-[85%]">
                  <Typography
                    variant="h1"
                    color="white"
                    className="title-ti mb-4 text-4xl md:text-4xl lg:text-6xl"
                  >
                    {slider.title}
                  </Typography>
                  <Typography
                    variant="lead"
                    color="white"
                    className="p-title mb-12 opacity-80 text-3xl"
                  >
                    {slider.description}
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default SubContentHome;
