import React, { useState, useEffect, useRef, useContext } from "react";
import NavbarSuperAdmin from "../../components/Navbar/NavbarSuperAdmin";
import FooterAmin from "../../components/Footer/FooterAmin";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../Context/ThemeContext";
import { Parallax } from "react-parallax";
import AOS from "aos";
import "aos/dist/aos.css";
import { useContentAboutUsSuperAdmin } from "../../Context/AuthAboutUsContextSuperAdmin";

function PanelAddAboutUsSuperAdmin() {
  // Obtiene el modo oscuro del contexto de tema
  const { darkMode } = useContext(ThemeContext);

  // Estados locales para visibilidad, carga de contenido e imágenes
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const [loadingCycleContent, setLoadingCycleContent] = useState(true);
  const [loadingCycleImages, setLoadingCycleImages] = useState(true);
  const [loadingMemberships, setLoadingMemberships] = useState(true);

  // Hooks personalizados para obtener el contenido
  const { firstContent, getFirstContent } = useContentAboutUsSuperAdmin();
  const { cycleContent, getCycleExperienceContent } =
    useContentAboutUsSuperAdmin();
  const { cycleImages, getCycleExperienceImages } =
    useContentAboutUsSuperAdmin();
  const { subContent, getSubContent } = useContentAboutUsSuperAdmin();
  const { valuesContent, getValuesContent } = useContentAboutUsSuperAdmin();
  const { policyContent, getPolicyContent } = useContentAboutUsSuperAdmin();
  const {
    membershipsContent,
    getMembershipsContent,
    deleteMembershipsContent,
  } = useContentAboutUsSuperAdmin();
  const { missionAndVissionContent, getMissionAndVissionContent } =
    useContentAboutUsSuperAdmin();

  // Carga el contenido al montar el componente
  useEffect(() => {
    getFirstContent();
    getSubContent();
    getMissionAndVissionContent();
    getValuesContent();
    getCycleExperienceContent();
    getCycleExperienceImages();
    getMembershipsContent();
    getPolicyContent();
  }, []);

  // Maneja la eliminación de un ítem de membresía
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      await deleteMembershipsContent(id);
      getMembershipsContent(); // Recarga el contenido de membresías
      Swal.fire("¡Eliminado!", "El logo ha sido eliminado.", "success");
    }
  };

  // Detecta cuando el contenedor entra en el viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Deja de observar una vez visible
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Inicializa AOS (Animate On Scroll)
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <NavbarSuperAdmin />
      <div
        className={`w-full h-full flex justify-center flex-col items-center pb-[2%] overflow-x-hidden ${
          darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
        }`}
      >
        {firstContent.map((content, index) => (
          <div className="w-[80%] pt-[8%] pb-[2%] container" key={content.id}>
            <Parallax
              bgImage={content.image_background}
              blur={{ min: -2, max: 4 }}
              strength={500}
              className="parallax-container"
            >
              <div
                className={`principal bg-black/65 flex w-full justify-between pt-[9%] pb-[5%] ${
                  darkMode ? "text-white" : ""
                }`}
                ref={containerRef}
              >
                <div className="txt flex flex-col justify-center items-center w-full rounded-r-full h-[50vh]">
                  <div
                    className={`txt-c w-[80%] h-[50vh] rounded-r-full flex flex-col justify-center ${
                      isVisible
                        ? "animate-fade-left animate-once animate-duration-[5000ms] animate-normal animate-fill-backwards"
                        : ""
                    }`}
                  >
                    <h1
                      className={`text-6xl mb-4 w-[80%] font-semibold ${
                        darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                      }`}
                    >
                      {content.title}
                    </h1>
                    <h2
                      className={`text-3xl mb-4 w-[80%] font-semibold ${
                        darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                      }`}
                    >
                      {content.sub_title}
                    </h2>
                    <p
                      className={`w-[50%] text-xl text-justify font-medium ${
                        darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                      }`}
                    >
                      {content.description}
                    </p>
                  </div>
                </div>
              </div>
            </Parallax>
            <button
              className={`w-full p-2 rounded-md ${
                darkMode
                  ? "text-[#ffffff] bg-gray-900"
                  : "bg-[#0e4980] text-[#ffffff]"
              }`}
            >
              <Link
                to={`/first-content-about-us-form-super-admin/${content.id}`}
              >
                Editar contenido
              </Link>
            </button>
          </div>
        ))}

        {subContent.map((subContent, index) => (
          <div className="w-[80%] container pb-[2%]" key={subContent.id}>
            <div
              className={`w-full flex flex-col items-center ${
                darkMode
                  ? "bg-[#424242] text-[#ffffff]"
                  : "bg-white text-[#0e4980]"
              }`}
            >
              <div
                className={`middle-title w-full flex justify-center text-4xl font-semibold py-[5%] ${
                  darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                }`}
              >
                <h1>{subContent.title}</h1>
              </div>
              <div className="about-2 w-full flex justify-between items-center mb-[5%]">
                <div
                  data-aos="fade-up"
                  className="secondC w-[40%] flex justify-center"
                >
                  <img
                    src={subContent.image_sub_content}
                    className="w-[100%] h-full rounded-[2%]"
                    alt=""
                  />
                </div>
                <div className="w-[50%] firstC">
                  <p
                    className={`p-2 text-lg text-justify font-semibold ${
                      darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                    }`}
                  >
                    {subContent.description}
                  </p>
                </div>
              </div>
              <button
                className={`w-full p-2 rounded-md ${
                  darkMode
                    ? "text-[#ffffff] bg-gray-900"
                    : "bg-[#0e4980] text-[#ffffff]"
                }`}
              >
                <Link
                  to={`/sub-content-about-us-form-super-admin/${subContent.id}`}
                >
                  Editar contenido
                </Link>
              </button>
            </div>
          </div>
        ))}

        <div
          className={`w-full flex justify-center items-center ${
            darkMode ? "bg-gray-900 text-[#ffffff]" : "bg-white text-[#0e4980]"
          }`}
        >
          <div
            className={`w-full ${
              darkMode
                ? "bg-gray-900 text-[#ffffff]"
                : "bg-white text-[#0e4980]"
            }`}
          >
            <div
              className={`middle-title w-full flex justify-center pb-8 text-4xl font-semibold ${
                darkMode
                  ? "bg-gray-950 text-[#ffffff]"
                  : "bg-white text-[#0e4980]"
              }`}
            >
              <h1>Nuestras Membresías</h1>
            </div>
            <div className="w-full flex justify-center py-4">
              <div className="w-[80%] flex justify-start">
                <button
                  className={`w-[20%] p-2 rounded-md ${
                    darkMode
                      ? "text-[#ffffff] bg-gray-700"
                      : "bg-[#0e4980] text-[#ffffff]"
                  }`}
                >
                  <Link to={`/memberships-content-about-us-form-super-admin`}>
                    Agregar logo nuevo
                  </Link>
                </button>
              </div>
            </div>
            <div className="w-full flex justify-center items-center pb-6">
              <div
                data-aos="fade-up"
                className="mem w-[80%] grid grid-cols-5 gap-3 justify-center items-center"
              >
                {membershipsContent.map((value, index) => (
                  <div className="w-full flex flex-col items-center">
                    <img
                      src={
                        darkMode
                          ? value.memberships_dark_image
                          : value.memberships_image
                      }
                      className="w-[70%] rounded-[2%]"
                      alt=""
                    />
                    <div className="w-full flex justify-center">
                      <button
                        className={`p-2 rounded-md ${
                          darkMode
                            ? "text-[#ffffff] bg-gray-700"
                            : "bg-[#0e4980] text-[#ffffff]"
                        }`}
                      >
                        <Link
                          to={`/memberships-content-about-us-form-super-admin/${value.id}`}
                        >
                          Editar contenido
                        </Link>
                      </button>
                      <button
                        className={`p-2 rounded-md ${
                          darkMode
                            ? "text-[#ffffff] bg-[#7d1e1e]"
                            : "bg-[#800e0e] text-[#ffffff]"
                        }`}
                        onClick={() => handleDelete(value.id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`w-full flex flex-col items-center ${
            darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
          }`}
        >
          {valuesContent.map((value, index) => (
            <div>
              <h2 className="text-4xl font-semibold mb-2" data-aos="fade-up">
                {value.title}
              </h2>
            </div>
          ))}
          <div className="w-[80%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
            {valuesContent.map((value, index) => (
              <div
                className="value-item p-4 text-center"
                data-aos="fade-up"
                key={value.id}
              >
                <img
                  src={darkMode ? value.values_dark_image : value.values_image}
                  className="w-20 h-20 mx-auto mb-4"
                  alt="Calidad"
                />
                <h3 className="text-xl font-semibold">
                  {value.values_about_us}
                </h3>
                <button
                  className={`w-full p-2 rounded-md ${
                    darkMode
                      ? "text-[#ffffff] bg-gray-900"
                      : "bg-[#0e4980] text-[#ffffff]"
                  }`}
                >
                  <Link to={`/values-about-us-form-super-admin/${value.id}`}>
                    Editar contenido
                  </Link>
                </button>
              </div>
            ))}
          </div>
        </div>

        <div
          className={`w-full flex flex-col py-10 items-center ${
            darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
          }`}
        >
          {missionAndVissionContent.map((missionAndvission, index) => (
            <div
              className="w-[80%] flex justify-center"
              key={missionAndvission.id}
            >
              <div
                className={`flex flex-col items-center ${
                  darkMode
                    ? "bg-[#424242] text-[#ffffff]"
                    : "bg-white text-[#0e4980]"
                }`}
              >
                <div className="w-full values-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                  <div
                    className="value-item p-4 text-center"
                    data-aos="fade-up"
                  >
                    <img
                      src={
                        darkMode
                          ? missionAndvission.mission_dark_image
                          : missionAndvission.mission_image
                      }
                      className="w-20 h-20 mx-auto mb-4"
                      alt="Misión"
                    />
                    <h3 className="text-2xl font-semibold">
                      {missionAndvission.mission}
                    </h3>
                    <p className="text-lg text-justify">
                      {missionAndvission.description_mission}
                    </p>
                  </div>
                  <div
                    className="value-item p-4 text-center"
                    data-aos="fade-up"
                  >
                    <img
                      src={
                        darkMode
                          ? missionAndvission.vission_dark_image
                          : missionAndvission.vission_image
                      }
                      className="w-20 h-20 mx-auto mb-4"
                      alt="Visión"
                    />
                    <h3 className="text-2xl font-semibold">
                      {missionAndvission.vission}
                    </h3>
                    <p className="text-md text-justify">
                      {missionAndvission.description_vission}
                    </p>
                  </div>
                </div>
                <button
                  className={`w-full p-2 rounded-md ${
                    darkMode
                      ? "text-[#ffffff] bg-gray-900"
                      : "bg-[#0e4980] text-[#ffffff]"
                  }`}
                >
                  <Link
                    to={`/mission-vission-content-about-us-form-super-admin/${missionAndvission.id}`}
                  >
                    Editar contenido
                  </Link>
                </button>
              </div>
            </div>
          ))}
        </div>

        <div
          className={`w-full flex justify-center pb-10 ${
            darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
          }`}
        >
          <div
            className={`w-full flex justify-center flex-col items-center ${
              darkMode
                ? "bg-[#424242] text-[#ffffff]"
                : "bg-white text-[#0e4980]"
            }`}
          >
            {policyContent.map((policy, index) => (
              <div className="w-[80%]">
                <div
                  className={`middle-title w-full flex justify-center text-4xl font-semibold ${
                    darkMode
                      ? "bg-[#424242] text-[#ffffff]"
                      : "bg-white text-[#0e4980]"
                  }`}
                >
                  <h1>{policy.title}</h1>
                </div>
                <div className="cali w-full flex justify-enter items-center">
                  <div className="w-[80%]">
                    <p
                      className={`p-2 whitespace-pre-line text-lg text-justify font-semibold ${
                        darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                      }`}
                    >
                      {policy.description}
                    </p>
                  </div>
                  <div className="iso w-[20%]">
                    <img
                      src={
                        darkMode
                          ? policy.policy_dark_image
                          : policy.policy_image
                      }
                      alt=""
                    />
                  </div>
                </div>
                <button
                  className={`w-full p-2 rounded-md ${
                    darkMode
                      ? "text-[#ffffff] bg-gray-900"
                      : "bg-[#0e4980] text-[#ffffff]"
                  }`}
                >
                  <Link
                    to={`/quality-policy-content-about-us-form-super-admin/${policy.id}`}
                  >
                    Editar contenido
                  </Link>
                </button>
              </div>
            ))}
          </div>
        </div>

        <h1 className="text-2xl">Editar contenido del ciclo de experiencia:</h1>
        <div className="w-[88%] flex justify-end items-end rounded-md">
          {cycleContent.map((cycle, index) => (
            <Parallax
              bgImage={cycle.image_background}
              className="w-full flex items-center h-[600px]"
              strength={400}
            >
              <div className="customer-experience-cycle">
                <div className="cycle-container">
                  <div className={`center-circle ${darkMode ? "" : ""}`}>
                    <img
                      src={
                        darkMode ? cycle.image_dark_cycle : cycle.image_cycle
                      }
                      alt=""
                    />
                  </div>
                  {cycleImages.map((cycleImage, index) => (
                    <div
                      key={index}
                      className={`outer-circle ${
                        darkMode
                          ? "bg-[#424242e1] border-gray-600"
                          : "bg-[#3a9ec9ec]"
                      }`}
                    >
                      <img
                        src={cycleImage.images_cycle}
                        className="w-[32px]"
                        alt={cycleImage.title}
                      />
                      <p>{cycleImage.title}</p>
                      <span>{cycleImage.cycle_sequence}</span>
                      <button
                        className={`h-[35px] px-2 mt-2 text-sm rounded-md ${
                          darkMode
                            ? "text-[#ffffff] bg-gray-900"
                            : "bg-[#0e4980] text-[#ffffff]"
                        }`}
                      >
                        <Link
                          to={`/cycle-experience-images-super-admin/${cycleImage.id}`}
                        >
                          Editar
                        </Link>
                      </button>
                    </div>
                  ))}
                </div>
                <div
                  className={`description ${
                    darkMode ? "bg-[#424242e1]" : "bg-[#113d69f4]"
                  }`}
                >
                  <h2
                    className={`ml-[15%] text-2xl py-8 w-[80%] font-semibold ${
                      darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                    }`}
                  >
                    {cycle.title}
                  </h2>
                  <p
                    className={`w-[75%] ml-[15%] text-md text-justify font-medium pb-[5%] ${
                      darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                    }`}
                  >
                    {cycle.description_one}
                  </p>
                  <p
                    className={`w-[75%] ml-[15%] text-md text-justify pb-4 font-medium ${
                      darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                    }`}
                  >
                    {cycle.description_two}
                  </p>
                  <button
                    className={`w-[50%] ml-[25%] p-2 rounded-md ${
                      darkMode
                        ? "text-[#ffffff] bg-gray-900"
                        : "bg-[#316799] text-[#ffffff]"
                    }`}
                  >
                    <Link
                      to={`/cycle-experience-content-super-admin/${cycle.id}`}
                    >
                      Editar
                    </Link>
                  </button>
                </div>
              </div>
            </Parallax>
          ))}
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default PanelAddAboutUsSuperAdmin;
