// Importar módulos y hooks necesarios de React
import React, { createContext, useContext, useState } from "react";

// Importar función para manejar rutas protegidas desde la API
import { protectedRoute } from "../api/authUser";

// Crear un contexto para manejar la información de contacto
export const ContactContext = createContext();

// Hook personalizado para usar el contexto de contacto
export const useContactContext = () => {
  const context = useContext(ContactContext);

  // Si el contexto no está disponible, lanzar un error
  if (!context) {
    throw new Error("useContactContext must be used within a ContactProvider");
  }

  // Devolver el contexto de contacto
  return context;
};

// Componente proveedor del contexto de contacto
export const ContactProvider = ({ children }) => {
  // Estado para almacenar el mensaje de contacto (aunque actualmente no se usa)
  const [contactMessage, setContactMessage] = useState(false);

  // Función para enviar un mensaje de contacto a través de una solicitud POST
  const sendContactMessage = async (formData) => {
    try {
      // Realizar la solicitud POST a la ruta de contacto protegida
      const res = await protectedRoute().post("/contact", formData);
      return res.data;
    } catch (error) {
      // Manejar errores en la solicitud
      console.log(error);
      throw new Error("Error sending contact message");
    }
  };

  // Proveer el contexto a los componentes hijos
  return (
    <ContactContext.Provider value={{ sendContactMessage }}>
      {children}
    </ContactContext.Provider>
  );
};
