import React, { useContext } from "react"; // Importa React y useContext para usar el contexto de tema
import Navbar from "../../components/Navbar/Navbar"; // Importa el componente Navbar
import Footer from "../../components/Footer/Footer"; // Importa el componente Footer
import "./info.css"; // Importa los estilos CSS específicos de este componente
import { ThemeContext } from "../../Context/ThemeContext"; // Importa el contexto de tema para cambiar entre modo oscuro y claro
import "animate.css"; // Importa las animaciones de Animate.css
import cumplimiento from "../../../public/cumplimiento.png"; // Importa la imagen de cumplimiento en modo claro
import cumplimientoBlack from "../../../public/cumplimientoBlack.png"; // Importa la imagen de cumplimiento en modo oscuro
import idea from "../../../public/idea.png"; // Importa la imagen de idea en modo claro
import ideaBlack from "../../../public/ideaBlack.png"; // Importa la imagen de idea en modo oscuro
import buscar from "../../../public/buscar.png"; // Importa la imagen de buscar en modo claro
import buscarBlack from "../../../public/buscarBlack.png"; // Importa la imagen de buscar en modo oscuro
import sagrilaftYptee from "../../../public/sagrilaft.jpg"; // Importa la imagen de fondo del Parallax
import sagri1 from "../../../public/sagri1.png"; // Importa la imagen para el primer compromiso
import sagri2 from "../../../public/sagri2.png"; // Importa la imagen para el segundo compromiso
import sagri3 from "../../../public/sagri3.png"; // Importa la imagen para el tercer compromiso
import sagri4 from "../../../public/sagri4.png"; // Importa la imagen para el cuarto compromiso
import sagri5 from "../../../public/sagri5.png"; // Importa la imagen para el quinto compromiso
import sagri6 from "../../../public/sagri6.png"; // Importa la imagen para el sexto compromiso
import persona from "../../../public/persona.png"; // Importa la imagen de persona en modo claro
import personaBlack from "../../../public/personaBlack.png"; // Importa la imagen de persona en modo oscuro
import { Parallax } from "react-parallax"; // Importa el componente Parallax

const InfoGTI = () => {
  const { darkMode } = useContext(ThemeContext); // Obtiene el estado del modo oscuro del contexto de tema

  // Define los compromisos de los clientes, proveedores y contratistas
  const compromisos = [
    {
      text: "Entregar información verídica y completa en el momento de su vinculación con GTI ALBERTO ÁLVAREZ LÓPEZ S.A.S.",
      img: sagri1,
    },
    {
      text: "Actualizar la información al menos una vez cada año o en caso de presentarse novedades.",
      img: sagri2,
    },
    {
      text: "Entregar información del (los) Beneficiario (s) Final(es).",
      img: sagri3,
    },
    {
      text: "Manifestar si los administradores, representantes legales o beneficiarios finales ostentan la calidad de PEP y suministrar la documentación e información complementaria que se requiera.",
      img: sagri4,
    },
    {
      text: "Indicar si como contraparte cuenta con Sistemas de Administración de Riesgos (SAGRILAFT y/o PTEE).",
      img: sagri5,
    },
    {
      text: "Establecer comunicación con el Oficial de Cumplimiento a través de los canales dispuestos y realizar las denuncias a que haya lugar.",
      img: sagri6,
    },
  ];

  return (
    <>
      <Navbar />
      <Parallax bgImage={sagrilaftYptee} strength={500}>
        <div
          className={`principal bg-black/60 flex w-full justify-between ${
            darkMode ? "text-white" : ""
          }`}
        >
          <div className="txt flex flex-col justify-center items-center w-full rounded-r-full h-[60vh]">
            <h1
              className={`text-9xl pb-4 ${
                darkMode ? "text-white" : "text-white"
              }`}
            >
              SAGRILAFT Y PTEE
            </h1>
            <h2
              className={`text-3xl ${darkMode ? "text-white" : "text-white"}`}
            >
              GTI – ALBERTO ÁLVAREZ LÓPEZ S.A.S
            </h2>
          </div>
        </div>
      </Parallax>
      <div
        className={`tope w-full flex justify-center pt-[2%] ${
          darkMode ? "bg-gray-900 text-white" : "text-[#0E4980]"
        } animate__animated animate__fadeIn`}
      >
        <div className={`w-[80%]`}>
          <section className="section animate__animated animate__fadeInLeft">
            <p className={`${darkMode ? "text-white" : "text-[#000]"}`}>
              GTI – ALBERTO ÁLVAREZ LÓPEZ S.A.S está comprometida con el
              fortalecimiento de una cultura de ética empresarial y de
              cumplimiento legal y normativo en los negocios. Es por eso que
              divulgamos y comunicamos a clientes, proveedores, terceras partes,
              colaboradores y socios la implementación del SAGRILAFT (Sistema de
              autocontrol, prevención y gestión de riesgo integral contra el
              lavado de activos, financiación del terrorismo y financiamiento de
              la proliferación de armas de destrucción masiva) y PTEE (Programa
              de Transparencia y Ética Empresarial) como muestra de una cultura
              tendiente a mantener buenas prácticas y actuar con integridad en
              el desarrollo de nuestro objeto social.
            </p>
            <br />
            <p className={`${darkMode ? "text-white" : "text-[#000]"}`}>
              Para GTI – ALBERTO ÁLVAREZ LÓPEZ S.A.S. es imperativo lograr el
              mejoramiento de la comunicación con nuestros clientes,
              proveedores, colaboradores, asociados, administradores y demás
              vinculados o partes interesadas ante situaciones que resulten
              contrarias a la ley y a nuestros valores.
            </p>
          </section>

          <section className="section animate__animated animate__fadeInRight">
            <h2>Generalidades del Sistema</h2>
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-10">
              <div
                className={`${
                  darkMode
                    ? "bg-gray-800 border-8 border-gray-100 text-white"
                    : "bg-[#0f4271e1] border-8 border-[#6a9cd9] text-white"
                } p-4 rounded-md`}
              >
                <div className="flex justify-center pb-2">
                  <img
                    src={darkMode ? cumplimientoBlack : cumplimiento}
                    alt="cumplimiento"
                  />
                </div>
                <p className={`${darkMode ? "text-white" : ""}`}>
                  GTI cuenta con un Oficial de Cumplimiento quien es la persona
                  encargada de velar por el adecuado cumplimiento del{" "}
                  <span className="font-bold">SAGRILAFT y PTEE.</span>
                </p>
              </div>
              <div
                className={`${
                  darkMode
                    ? "bg-gray-800 border-8 border-gray-100 text-white"
                    : "bg-[#0f4271e1] border-8 border-[#6a9cd9] text-white"
                } p-4 rounded-md`}
              >
                <div className="flex justify-center pb-2">
                  <img src={darkMode ? ideaBlack : idea} alt="idea" />
                </div>
                <p className={`${darkMode ? "text-white" : ""}`}>
                  GTI diseñó e implementó procedimientos y políticas internas
                  que permiten tener un adecuado conocimiento de sus
                  contrapartes (asociados, empleados, clientes, contratistas,
                  distribuidores, proveedores y demás vinculados).
                </p>
              </div>
              <div
                className={`${
                  darkMode
                    ? "bg-gray-800 border-8 border-gray-100 text-white"
                    : "bg-[#0f4271e1] border-8 border-[#6a9cd9] text-white"
                } p-4 rounded-md`}
              >
                <div className="flex justify-center pb-2">
                  <img src={darkMode ? buscarBlack : buscar} alt="dibulgar" />
                </div>
                <p className={`${darkMode ? "text-white" : ""}`}>
                  GTI en cumplimiento de sus obligaciones se permite divulgar la
                  existencia del SAGRILAFT y PTEE a sus contrapartes.
                </p>
              </div>
            </div>
          </section>

          <section className="section slider-container animate__animated animate__fadeInUp">
            <h2>
              Compromisos de nuestros clientes, proveedores y/o contratistas con
              el SAGRILAFT y PTEE
            </h2>
            <div className={`grid grid-cols-1 md:grid-cols-3 gap-10`}>
              {compromisos.map((compromiso, index) => (
                <div
                  key={index}
                  className={`${
                    darkMode
                      ? "bg-gray-800 border-8 border-gray-100 text-white"
                      : "bg-[#0f4271e1] border-8 border-[#6a9cd9] text-white"
                  } p-4 rounded-md min-h-[250px] animate__animated animate__fadeIn`}
                  style={{ animationDelay: `${index * 0.1}s` }}
                >
                  <div className="flex justify-center pb-2">
                    <img src={compromiso.img} alt={`compromiso-${index + 1}`} />
                  </div>
                  <p>{compromiso.text}</p>
                </div>
              ))}
            </div>
          </section>

          <section className="section animate__animated animate__fadeInLeft">
          <h2 className="text-center">Oficial de Cumplimiento</h2>
            <div className="flex justify-center gap-4">
              <div>
                <img src={darkMode ? personaBlack : persona} alt="" />
              </div>
              <div>
                <p className={`${darkMode ? "text-white" : "text-[#000]"}`}>
                  Email:{" "}
                  <a href="mailto:oficialdecumplimiento@gti.net.co">
                    oficialdecumplimiento@gti.net.co
                  </a>
                </p>
                <p className={`${darkMode ? "text-white" : "text-[#000]"}`}>
                  Dirección: Calle 64 A # 21 - 10 Edificio Portal del Cable -
                  Local 18
                </p>
                <p className={`${darkMode ? "text-white" : "text-[#000]"}`}>
                  Teléfono: 3107387502
                </p>
                <p className={`${darkMode ? "text-white" : "text-[#000]"}`}>
                  Manizales, Caldas
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InfoGTI;
