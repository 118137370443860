import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import bcrypt from "bcryptjs";
import NavbarSuperAdmin from "../../components/Navbar/NavbarSuperAdmin";
import { useForm } from "react-hook-form";
import { useSuperAdminAuth } from "../../Context/AuthSuperAdminContext";

function ProfilePageEditing() {
  // Obtiene la información del administrador y funciones desde el contexto
  const { administrators, getAdministratorsById, logout } = useSuperAdminAuth();
  // Estados locales para controlar si los formularios están en modo edición
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  // Obtiene el parámetro de id de la URL
  const params = useParams();
  // Inicializa el hook de formularios
  const { register, handleSubmit, reset, setValue } = useForm();
  // Obtiene la información del administrador actual desde el contexto
  const { administratorCrdential } = useSuperAdminAuth();

  // Efecto para obtener la información del administrador al montar el componente
  useEffect(() => {
    if (params.id) {
      getAdministratorsById(params.id);
    }
  }, [params.id]);

  // Efecto para actualizar los valores del formulario cuando cambia la información del administrador
  useEffect(() => {
    if (administratorCrdential) {
      setValue("username", administratorCrdential.username);
      setValue("email", administratorCrdential.email);
    }
  }, [administratorCrdential, setValue]);

  // Función para mostrar alertas usando SweetAlert2
  const showAlert = (message, type, time, confirmation) => {
    let iconType = "info";
    if (type === "error") iconType = "error";
    else if (type === "warning") iconType = "warning";
    else if (type === "success") iconType = "success";

    Swal.fire({
      title: message,
      icon: iconType,
      color: "#BEDDF9",
      iconColor: "#BEDDF9",
      background: "#0E4980",
      timer: time,
      showConfirmButton: confirmation,
    });
  };

  // Maneja el clic en el botón de editar información
  const handleEditClick = () => setIsEditing(true);

  // Maneja el clic en el botón de editar contraseña
  const handleEditPasswordClick = () => setIsEditingPassword(true);

  // Maneja el envío del formulario para guardar la información del administrador
  const handleSaveClick = async (data) => {
    try {
      const res = await axios.put(
        // URL de la API para actualizar la información del administrador
        `https://appser-gti-bk-prod-fwe0e3e9ftaghzeq.eastus2-01.azurewebsites.net/update-credentials/${params.id}`,
        { username: data.username, email: data.email }
      );
      if (res.status === 200) {
        showAlert("La información se actualizó correctamente", "success", 2000);
      }
    } catch (error) {
      console.error(error);
    }
    setIsEditing(false);
  };

  // Maneja el envío del formulario para cambiar la contraseña
  const handleChangePassword = async (data) => {
    const admin = administratorCrdential;
    if (!admin) return showAlert("Administrador no encontrado", "error");

    // Verifica si las nuevas contraseñas coinciden
    if (data.newPassword !== data.confirmNewPassword) {
      return showAlert("Las contraseñas no coinciden", "error");
    }

    try {
      await axios.put(
        // URL de la API para actualizar la contraseña del administrador
        `https://appser-gti-bk-prod-fwe0e3e9ftaghzeq.eastus2-01.azurewebsites.net/update-credentials/${params.id}`,
        { password: data.newPassword }
      );

      showAlert("La contraseña se actualizó correctamente", "success", 3000);
      reset({ newPassword: "", confirmNewPassword: "" });
    } catch (error) {
      console.error(error);
      reset({ newPassword: "", confirmNewPassword: "" });
    }

    setIsEditingPassword(false);
  };

  // Maneja el clic en el botón de cancelar y restablece el formulario a los valores iniciales
  const handleCancelInfo = () => {
    if (administratorCrdential) {
      reset({
        username: administratorCrdential.username,
        email: administratorCrdential.email,
      });
    }
    setIsEditing(false);
  };

  return (
    <>
      <NavbarSuperAdmin />
      <div className="w-full flex justify-center items-center bg-white">
        <div className="w-[50%] flex flex-col justify-center mt-[15%]">
          <h1 className="text-3xl mb-4">Actualizar Datos</h1>
          <div className="mb-4">
            <div>
              <button
                onClick={handleEditClick}
                disabled={isEditing}
                className="w-full text-base bg-[#0E4980] text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-md rounded-b-none"
              >
                Editar información
              </button>
            </div>
            <form onSubmit={handleSubmit(handleSaveClick)}>
              <div>
                <input
                  id="username"
                  className={`${
                    isEditing
                      ? "bg-[#BEDDF9] pl-1 border-amber-950 border-[1px] border-y-0 rounded-b-none w-full p-2 h-10 outline-none"
                      : "bg-[#BEDDF9] text-gray-600 pl-1 border-amber-950 border-[1px] border-y-0 rounded-b-none w-full p-2 h-10 outline-none"
                  }`}
                  type="text"
                  {...register("username")}
                  disabled={!isEditing}
                />
              </div>
              <div>
                <input
                  id="emailProfile"
                  className={`${
                    isEditing
                      ? "bg-[#BEDDF9] pl-1 border-amber-950 border-[1px] border-y-0 rounded-b-none w-full p-2 h-10 outline-none"
                      : "bg-[#BEDDF9] text-gray-600 pl-1 border-amber-950 border-[1px] border-y-0 rounded-b-none w-full p-2 h-10 outline-none"
                  }`}
                  type="email"
                  {...register("email")}
                  disabled={!isEditing}
                />
              </div>
              <div className="flex">
                <button
                  type="submit"
                  className="w-[50%] bg-[#0E4980] text-white text-base mb-4 bg-acent font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-t-none rounded-md"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  className="w-[50%] text-base mb-4 bg-red-800 text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-t-none rounded-md hover:bg-red-700"
                  onClick={handleCancelInfo}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <div className="mb-2 w-full">
            <div>
              <button
                onClick={handleEditPasswordClick}
                className={
                  isEditingPassword
                    ? "w-full text-base bg-[#0E4980] text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-md rounded-b-none"
                    : "w-full text-base bg-[#0E4980] text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-md rounded-b-none"
                }
              >
                Cambiar contraseña
              </button>
            </div>
            {isEditingPassword && (
              <form onSubmit={handleSubmit(handleChangePassword)}>
                {/* <div>
                  <input
                    id="currentPassword"
                    className="bg-[#BEDDF9] pl-1 border-amber-950 border-[1px] border-y-0 rounded-b-none w-full p-2 h-10 outline-none"
                    type="password"
                    {...register("currentPassword")}
                  />
                </div> */}
                <div>
                  <input
                    id="newPassword"
                    placeholder="Contraseña nueva"
                    className="bg-[#BEDDF9] pl-1 border-amber-950 border-[1px] border-y-0 rounded-b-none w-full p-2 h-10 outline-none"
                    type="password"
                    {...register("newPassword")}
                  />
                </div>
                <div>
                  <input
                    id="confirmNewPassword"
                    placeholder="Confirmar contraseña nueva"
                    className="bg-[#BEDDF9] pl-1 border-amber-950 border-[1px] border-y-0 rounded-b-none w-full p-2 h-10 outline-none"
                    type="password"
                    {...register("confirmNewPassword")}
                  />
                </div>
                <div className="flex">
                  <button
                    type="submit"
                    className="w-[50%] bg-[#0E4980] text-white text-base mb-4 bg-acent font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-t-none rounded-md"
                  >
                    Cambiar contraseña
                  </button>
                  <button
                    type="button"
                    className="w-[50%] text-base mb-4 bg-red-800 text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-t-none rounded-md hover:bg-red-700"
                    onClick={() => setIsEditingPassword(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfilePageEditing;
