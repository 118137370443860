import "./solutionContent.css"; // Importa los estilos CSS específicos para este componente
import { useState, useEffect, useContext } from "react"; // Importa hooks de React
import { ThemeContext } from "../../Context/ThemeContext"; // Importa el contexto del tema (modo oscuro/claro)
import AOS from "aos"; // Importa AOS para las animaciones de desplazamiento
import "aos/dist/aos.css"; // Importa los estilos CSS de AOS
import { Parallax } from "react-parallax"; // Importa el componente Parallax para crear efectos de paralaje
import { useNavigate } from "react-router-dom"; // Importa el hook useNavigate de React Router para navegación
import { useSolutions } from "../../Context/SolutionsContext"; // Importa el contexto para las soluciones
import { useServices } from "../../Context/ServicesContext"; // Importa el contexto para los servicios
import gtiSolutionsLogo from "../../../public/gti-sol-logo.png"; // Importa el logo de GTI para el modo claro
import gtiSolutionsLogoDark from "../../../public/bg-sol-dark.png"; // Importa el logo de GTI para el modo oscuro

function SolutionsContent() {
  const { darkMode } = useContext(ThemeContext); // Obtiene el valor de `darkMode` del contexto del tema
  const { solutions, getAllSolutions } = useSolutions(); // Obtiene las soluciones y la función para cargarlas del contexto
  const { services, getServices } = useServices(); // Obtiene los servicios y la función para cargarlos del contexto
  const [selectedService, setSelectedService] = useState(null); // Estado local para el servicio seleccionado
  const { solutionsContent, getSolutionsContent } = useSolutions(); // Obtiene el contenido de las soluciones y la función para cargarlas del contexto
  const navigate = useNavigate(); // Hook para navegar entre rutas

  // Efecto para cargar el contenido de las soluciones cuando se monta el componente
  useEffect(() => {
    getSolutionsContent();
  }, []);

  // Efecto para inicializar AOS y cargar todas las soluciones cuando se monta el componente
  useEffect(() => {
    AOS.init({ duration: 1000 });
    getAllSolutions().catch((error) =>
      console.error("Error loading solutions:", error)
    );
  }, []);

  // Efecto para seleccionar un servicio por defecto cuando se cargan las soluciones
  useEffect(() => {
    if (Array.isArray(solutions) && solutions.length > 0) {
      const defaultService = solutions.find(
        (service) => service.title === "XaaS (Todo como servicio)"
      );
      if (defaultService) {
        handleServiceClick(defaultService.id); // Selecciona el servicio por defecto
      }
    } else {
      // console.error("solutions no es un arreglo o está vacío:", solutions);
    }
  }, [solutions]);

  // Efecto para manejar la animación de las imágenes cuando el usuario hace scroll
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".cycle-item2"); // Selecciona todos los elementos con la clase "cycle-item2"
      const translateValue = window.innerWidth <= 430 ? 150 : 220; // Establece un valor de traducción dependiendo del ancho de la ventana
      elements.forEach((el, index) => {
        // Aplica transformaciones de rotación y traducción a cada elemento basado en el scroll
        el.style.transform = `rotate(${
          window.scrollY / 10 + index * (360 / solutions.length)
        }deg) translate(${translateValue}px) rotate(-${
          window.scrollY / 10 + index * (360 / solutions.length)
        }deg)`;
      });
    };

    window.addEventListener("scroll", handleScroll); // Agrega el listener para el evento de scroll
    return () => window.removeEventListener("scroll", handleScroll); // Limpia el listener cuando el componente se desmonta
  }, [solutions.length]);

  // Maneja el clic en un servicio, carga los servicios relacionados y actualiza el servicio seleccionado
  const handleServiceClick = async (id) => {
    await getServices(id).catch((error) =>
      console.error("Error loading services:", error)
    );
    const service = solutions.find((service) => service.id === id);
    setSelectedService(service); // Actualiza el servicio seleccionado
  };

  return (
    <div>
      {solutionsContent.map((solutionContent, index) => (
        <Parallax
          strength={400}
          bgImage={solutionContent.image_background}
          // blur={{ min: -2, max: 4 }}
          key={index}
        >
          <div
            className={`so w-full flex flex-col items-center justify-center bg-black/45 pb-[3%]  ${
              darkMode ? "" : ""
            }`}
          >
            <div className="title-initial w-[90%] pb-[8%] text-center flex justify-center mt-4 items-center">
              <h1
                className={`title text-9xl mb-8 font-semibold ${
                  darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                }`}
              >
                {solutionContent.title}
              </h1>
            </div>
            <div
              className={`full2 w-full py-8 flex justify-center items-center ${
                darkMode ? "" : ""
              }`}
            >
              <div className="tdo w-[80%] flex justify-center items-center">
                <div className="container-cycle w-[50%] p-4" data-aos="fade-up">
                  <div className="cycle-container2">
                    <div className="cycle2">
                      <div className={`center-circle2 ${darkMode ? "" : ""}`}>
                        <img
                          src={
                            darkMode ? gtiSolutionsLogoDark : gtiSolutionsLogo
                          }
                          alt=""
                        />
                      </div>
                      {Array.isArray(solutions) &&
                        solutions.map((solution) => (
                          <div
                            className={`cycle-item2 z-10 ${
                              selectedService &&
                              selectedService.id === solution.id
                                ? "active"
                                : ""
                            } ${
                              darkMode
                                ? "bg-[#424242e1] border-[#2b2a2ae1]"
                                : "bg-[#0e3153f4]"
                            }`}
                            onClick={() => handleServiceClick(solution.id)}
                            key={solution.id}
                          >
                            <div className="icon">
                              <img
                                src={solution.icon_solution}
                                alt={solution.title}
                              />
                            </div>
                            <div className={"label"}>
                              <h1
                                className={`${
                                  darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                                }`}
                              >
                                {solution.title}
                              </h1>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="container w-[50%]" data-aos="fade-up">
                  <div className="description-container w-full">
                    {selectedService && (
                      <div
                        className={`flex justify-center items-center p-8 rounded-md min-h-[300px] border-l-[20px] border-[#113d69] ${
                          darkMode
                            ? "bg-gray-600 text-white border-gray-800"
                            : "bg-[#beddf9]"
                        }`}
                      >
                        <div className="w-full h-full flex flex-col justify-center">
                          <h1
                            className={`text-sm text-[#113d69] mb-8 font-medium ${
                              darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                            }`}
                          >
                            <span className="font-bold text-lg">
                              {selectedService ? selectedService.title : ""}
                            </span>
                          </h1>
                          <p
                            className={`sol text-sm mb-4 text-justify text-[#113d69] ${
                              darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                            }`}
                          >
                            {selectedService.description}
                          </p>
                          <button
                            className={`bg-[#0c5498] rounded-md text-white w-[100px] h-[35px] ${
                              darkMode
                                ? "text-[#ffffff] bg-gray-800"
                                : "text-[#0e4980]"
                            }`}
                            onClick={() =>
                              navigate(
                                `/solutions-gti?service=${selectedService.title}`
                              )
                            }
                          >
                            Ver más
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="h-[100px]"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      ))}
    </div>
  );
}

export default SolutionsContent;
