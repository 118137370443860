import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import FooterAmin from "../../components/Footer/FooterAmin";
import NavbarAdmin from "../../components/Navbar/NavbarAdmin";
import { ThemeContext } from "../../Context/ThemeContext";
import { useContentAboutUs } from "../../Context/AuthAboutUsContext";

function MissionAndVissionForm() {
  // Inicializa useForm para gestionar el formulario
  const { register, handleSubmit, setValue } = useForm();

  // Obtiene funciones para interactuar con el contenido de misión y visión
  const {
    getMissionAndVissionContentById,
    updateMissionAndVissionContent,
    createMissionAndVissionContent,
  } = useContentAboutUs();

  const navigate = useNavigate(); // Hook para navegar entre rutas
  const params = useParams(); // Obtiene parámetros de la URL
  const { darkMode } = useContext(ThemeContext); // Obtiene el estado del modo oscuro

  // Estados para manejar las imágenes y sus previsualizaciones
  const [imageMission, setImageMission] = useState(null);
  const [imageMissionPreview, setImageMissionPreview] = useState(null);
  const [imageVission, setImageVission] = useState(null);
  const [imageVissionPreview, setImageVissionPreview] = useState(null);
  const [imageMissionDark, setImageMissionDark] = useState(null);
  const [imageMissionDarkPreview, setImageMissionDarkPreview] = useState(null);
  const [imageVissionDark, setImageVissionDark] = useState(null);
  const [imageVissionDarkPreview, setImageVissionDarkPreview] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false); // Verifica si está actualizando contenido existente

  // Efecto para cargar el contenido si se pasa un ID en los parámetros
  useEffect(() => {
    const loadContent = async () => {
      if (params.id) {
        try {
          const content = await getMissionAndVissionContentById(params.id);

          if (content && Object.keys(content).length > 0) {
            // Establece los valores del formulario con el contenido existente
            setValue("mission", content.mission);
            setValue("description_mission", content.description_mission);
            setValue("vission", content.vission);
            setValue("description_vission", content.description_vission);

            // Establece las previsualizaciones de las imágenes si existen
            if (content.mission_image) {
              setImageMissionPreview(content.mission_image);
            }
            if (content.vission_image) {
              setImageVissionPreview(content.vission_image);
            }
            if (content.mission_dark_image) {
              setImageMissionDarkPreview(content.mission_dark_image);
            }
            if (content.vission_dark_image) {
              setImageVissionDarkPreview(content.vission_dark_image);
            }

            setIsUpdating(true); // Indica que se está actualizando contenido existente
          }
        } catch (error) {
          console.error("Error loading content:", error);
        }
      }
    };
    loadContent();
  }, [params.id, setValue, getMissionAndVissionContentById]);

  // Maneja el envío del formulario
  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append("mission", data.mission);
    formData.append("description_mission", data.description_mission);
    formData.append("vission", data.vission);
    formData.append("description_vission", data.description_vission);

    // Añade las imágenes seleccionadas al formulario si existen
    if (imageMission) {
      formData.append("mission_image", imageMission);
    }
    if (imageVission) {
      formData.append("vission_image", imageVission);
    }
    if (imageMissionDark) {
      formData.append("mission_dark_image", imageMissionDark);
    }
    if (imageVissionDark) {
      formData.append("vission_dark_image", imageVissionDark);
    }

    try {
      console.log("Form data being submitted:", formData);
      if (isUpdating) {
        // Actualiza el contenido si es un formulario de actualización
        await updateMissionAndVissionContent(params.id, formData);
      } else {
        // Crea un nuevo contenido si no es una actualización
        await createMissionAndVissionContent(formData);
      }
      navigate("/about-us-panel"); // Redirige después de enviar el formulario
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  });

  // Maneja el cambio de la imagen de la misión
  const handleImageMissionChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageMission(file);
      setImageMissionPreview(URL.createObjectURL(file)); // Muestra la previsualización
    } else {
      setImageMission(null);
      setImageMissionPreview(null);
    }
  };

  // Maneja el cambio de la imagen de la visión
  const handleImageVissionChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageVission(file);
      setImageVissionPreview(URL.createObjectURL(file)); // Muestra la previsualización
    } else {
      setImageVission(null);
      setImageVissionPreview(null);
    }
  };

  // Maneja el cambio de la imagen de la misión en modo oscuro
  const handleImageMissionDarkChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageMissionDark(file);
      setImageMissionDarkPreview(URL.createObjectURL(file)); // Muestra la previsualización
    } else {
      setImageMissionDark(null);
      setImageMissionDarkPreview(null);
    }
  };

  // Maneja el cambio de la imagen de la visión en modo oscuro
  const handleImageVissionDarkChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageVissionDark(file);
      setImageVissionDarkPreview(URL.createObjectURL(file)); // Muestra la previsualización
    } else {
      setImageVissionDark(null);
      setImageVissionDarkPreview(null);
    }
  };

  return (
    <>
      <NavbarAdmin />
      <div
        className={`w-full flex justify-center h-full gap-20 ${
          darkMode ? "bg-[#424242] text-white" : "bg-gray-100"
        }`}
      >
        <div className={`h-full pt-[12%] pb-[4%]`}>
          <form
            onSubmit={onSubmit}
            className={`h-full p-6 rounded shadow-md ${
              darkMode ? "bg-[#212121]" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">
              {isUpdating ? "Actualizar Contenido" : "Añade un nuevo Contenido"}
            </h2>
            <p className="mb-4">
              LLena todos los campos para{" "}
              {isUpdating ? "actualizar" : "agregar"} el Contenido.
            </p>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="mission"
              >
                Titulo Misíon:
              </label>
              <input
                type="text"
                id="mission"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("mission")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description_mission"
              >
                Descripción misíon:
              </label>
              <textarea
                id="description_mission"
                rows={5}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade la descripción"
                {...register("description_mission")}
              ></textarea>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image_mission"
              >
                Imagen misíon:
              </label>
              {imageMissionPreview && (
                <div className="mt-4 flex justify-center">
                  <img
                    src={imageMissionPreview}
                    alt="Preview"
                    className="w-[160px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="image_mission"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageMissionChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image_mission_dark"
              >
                Imagen misíon (Modo oscuro):
              </label>
              {imageMissionDarkPreview && (
                <div className="mt-4 flex justify-center">
                  <img
                    src={imageMissionDarkPreview}
                    alt="Preview"
                    className="w-[160px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="image_mission_dark"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageMissionDarkChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="vission"
              >
                Titulo Visíon:
              </label>
              <input
                type="text"
                id="vission"
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade el nombre"
                {...register("vission")}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description_vission"
              >
                Descripción visíon:
              </label>
              <textarea
                id="description_vission"
                rows={5}
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  darkMode ? "bg-[#212121]" : "bg-white"
                }`}
                placeholder="Añade la descripción"
                {...register("description_vission")}
              ></textarea>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image_vission"
              >
                Imagen visíon:
              </label>
              {imageVissionPreview && (
                <div className="mt-4 flex justify-center">
                  <img
                    src={imageVissionPreview}
                    alt="Preview"
                    className="w-[160px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="image_vission"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageVissionChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="image_vission_dark"
              >
                Imagen visíon (Modo oscuro):
              </label>
              {imageVissionDarkPreview && (
                <div className="mt-4 flex justify-center">
                  <img
                    src={imageVissionDarkPreview}
                    alt="Preview"
                    className="w-[160px]"
                  />
                </div>
              )}
              <input
                type="file"
                id="image_vission_dark"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={handleImageVissionDarkChange}
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-[#0e4980] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {isUpdating ? "Actualizar" : "Agregar"}
              </button>
              <button className="bg-[#800e10] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <Link to="/about-us-panel">Cancelar</Link>
              </button>
            </div>
          </form>
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default MissionAndVissionForm;
