import React, { useState, useEffect, useRef, useContext } from "react"; // Importa React y varios hooks
import NavbarAdmin from "../../components/Navbar/NavbarAdmin";
import NavbarSuperAdmin from "../../components/Navbar/NavbarSuperAdmin";
import FooterAmin from "../../components/Footer/FooterAmin";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ThemeContext } from "../../Context/ThemeContext";
import { Carousel, Typography } from "@material-tailwind/react";
import { useContentHome } from "../../Context/AuthContentHomeContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Parallax } from "react-parallax"; // Importa el componente Parallax para crear efectos de paralaje
import sepImage from "../../../public/sep.png";

function PanelAddHome() {
  const { darkMode } = useContext(ThemeContext); // Obtiene el tema de contexto (modo oscuro/claro)
  const [loadingSliders, setLoadingSliders] = useState(true);
  const [loadingManufactures, setLoadingManufactures] = useState(true);
  const [loadingManufacturesImages, setLoadingManufacturesImages] =
    useState(true);
  const [loadingServicesTi, setLoadingServicesTi] = useState(true);
  const navigate = useNavigate();
  const { sliders, getSliders, deletesSliders } = useContentHome(); // Obtiene funciones del contexto para sliders
  const { manufactures, getManufactures } = useContentHome(); // Obtiene funciones del contexto para fabricantes
  const {
    manufacturesImages,
    getManufacturesImages,
    deletesManufacturesImages,
  } = useContentHome(); // Obtiene funciones del contexto para imágenes de fabricantes
  const { alliesImages, getAlliesImages, deletesAlliesImages } =
    useContentHome(); // Obtiene funciones del contexto para imágenes de aliados
  const { servicesTi, getServicesTi, deletesServicesTi } = useContentHome(); // Obtiene funciones del contexto para servicios TI
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null); // Crea una referencia para el contenedor principal
  const { content, getContent } = useContentHome(); // Obtiene funciones del contexto para el contenido
  const { slideBlog, getSlideBlogHome } = useContentHome(); // Obtiene funciones del contexto para el blog

  // Efecto para obtener todos los datos al montar el componente
  useEffect(() => {
    getSliders();
    getManufactures();
    getManufacturesImages();
    getAlliesImages();
    getServicesTi();
    getContent();
    getSlideBlogHome();
  }, []);

  // Efectos para cargar datos de forma asíncrona y actualizar los estados de carga
  useEffect(() => {
    const fetchSliders = async () => {
      await getSliders();
      setLoadingSliders(false);
    };
    fetchSliders();

    const fetchManufactures = async () => {
      await getManufactures();
      setLoadingManufactures(false);
    };
    fetchManufactures();

    const fetchManufacturesImages = async () => {
      await getManufacturesImages();
      setLoadingManufacturesImages(false);
    };
    fetchManufacturesImages();

    const fetchServicesTi = async () => {
      await getServicesTi();
      setLoadingServicesTi(false);
    };
    fetchServicesTi();
  }, []);

  // Maneja la eliminación de sliders con una confirmación
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      await deletesSliders(id);
      getSliders();
      Swal.fire("¡Eliminado!", "El Slider ha sido eliminado.", "success");
      navigate("/home-panel");
    }
  };

  // Maneja la eliminación de fabricantes con una confirmación
  const handleDeleteManufacture = async (id) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      await deletesManufacturesImages(id);
      getManufactures();
      Swal.fire("¡Eliminado!", "El Fabricante ha sido eliminado.", "success");
      navigate("/home-panel");
    }
  };

  // Maneja la eliminación de aliados con una confirmación
  const handleDeleteAllie = async (id) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      await deletesAlliesImages(id);
      getAlliesImages();
      Swal.fire("¡Eliminado!", "El Aliado ha sido eliminado.", "success");
      navigate("/home-panel");
    }
  };

  // Maneja la eliminación de servicios TI con una confirmación
  const handleDeleteServiceTi = async (id) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      await deletesServicesTi(id);
      getServicesTi();
      Swal.fire("¡Eliminado!", "El Servicio ha sido eliminado.", "success");
      navigate("/home-panel");
    }
  };

  // Configuración del slider
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false, // Cambiado a false
    autoplay: true,
    autoplaySpeed: 5000,
    verticalSwiping: false, // Cambiado a false
    arrows: false,
  };

  return (
    <>
      <NavbarAdmin />
      <div
        className={`w-full h-full flex justify-center flex-col items-center ${
          darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
        }`}
      >
        <div className="w-[90%] py-[8%]">
          <h1 className={`text-3xl`}>
            Edita el contenido de las secciones de la pagina de inicio:
          </h1>
          <div className="w-full h-full flex flex-wrap justify-center items-center gap-28 pt-10">
            <div className="w-full h-[600px]  flex flex-col items-center rounded-md">
              <Carousel
                autoplay={true}
                loop={true}
                nextArrow={false}
                prevArrow={false}
                className="w-full h-full"
              >
                {content.map((item) => (
                  <div key={item.id} className="w-full">
                    <Parallax bgImage={item.image_background} strength={500}>
                      <div
                        className={`w-full h-[600px] bg-black/65 flex justify-between items-center ${
                          darkMode ? "text-white" : ""
                        }`}
                      >
                        <div className="flex flex-col justify-center items-center rounded-r-full h-full">
                          <div className="w-[90%] rounded-r-full flex flex-col justify-center">
                            <h1
                              className={`text-6xl mb-4 w-[80%] font-semibold ${
                                darkMode ? "text-white" : "text-white"
                              }`}
                            >
                              {item.title}
                            </h1>
                            <h2
                              className={`text-7xl mb-4 w-[80%] font-semibold ${
                                darkMode ? "text-white" : "text-white"
                              }`}
                            >
                              {item.sub_title}
                            </h2>
                            <div className="w-full flex gap-10">
                              <p
                                className={`w-[80%] text-2xl text-justify font-medium ${
                                  darkMode ? "text-white" : "text-white"
                                }`}
                              >
                                {item.description}
                              </p>
                              <img className="w-[22%]" src={item.logo} alt="" />
                            </div>
                          </div>
                          <div className="w-full flex justify-center">
                            <button
                              className={`w-[10%] text-xl h-[35px] px-2 ml-2 mt-2 rounded-md ${
                                darkMode
                                  ? "text-[#ffffff] bg-gray-900"
                                  : "bg-[#0e4980] text-[#ffffff]"
                              }`}
                            >
                              <Link to={`/first-content-home/${item.id}`}>
                                Editar
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Parallax>
                  </div>
                ))}
                {content.map((image) => (
                  <Parallax
                    bgImage={image.image_slide}
                    className="w-full h-[600px] flex justify-center items-center"
                    strength={50}
                  >
                    <button
                      className={`w-[100%] text-xl h-[35px] px-2 ml-2 mt-2 rounded-md ${
                        darkMode
                          ? "text-[#ffffff] bg-gray-900"
                          : "bg-[#0e4980] text-[#ffffff]"
                      }`}
                    >
                      <Link to={`/first-content-home-slide/${image.id}`}>
                        Editar
                      </Link>
                    </button>
                  </Parallax>
                ))}
                {slideBlog.map((content) => (
                  <Parallax
                    bgImage={content.image_background}
                    className="h-[600px] bg-black/60 w-full flex justify-center items-center"
                    strength={50}
                  >
                    <h1
                      className={`text-6xl mb-4 w-[80%] font-semibold ${
                        darkMode ? "text-white" : "text-white"
                      }`}
                    >
                      {content.title}
                    </h1>
                    <div className="w-full flex justify-center">
                    <button
                      className={`w-[10%] text-xl h-[35px] px-2 ml-2 mt-2 rounded-md ${
                        darkMode
                          ? "text-[#ffffff] bg-gray-900"
                          : "bg-[#0e4980] text-[#ffffff]"
                      }`}
                    >
                      <Link to={`/slide-blog-home/${content.id}`}>Editar</Link>
                    </button>
                    </div>
                  </Parallax>
                ))}
              </Carousel>
            </div>
            <div className="w-full h-[600px] flex flex-col items-center rounded-md">
              <div className="w-full h-full">
                <Carousel
                  transition={{ duration: 2 }}
                  autoplay={true}
                  loop={true}
                  nextArrow={false}
                  prevArrow={false}
                >
                  {sliders.map((slider, index) => (
                    <div className="relative h-full w-full">
                      <img
                        src={slider.image_slider}
                        alt="image 1"
                        className="h-full w-full object-cover"
                      />
                      <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/55">
                        <div className="w-3/4 text-center md:w-2/4">
                          <Typography
                            variant="h1"
                            color="white"
                            className="mb-4 text-3xl md:text-4xl lg:text-3xl"
                          >
                            {slider.title}
                          </Typography>
                          <Typography
                            variant="lead"
                            color="white"
                            className="mb-12 opacity-80 text-lg"
                          >
                            {slider.description}
                          </Typography>
                        </div>
                        <div className="flex pb-[4%]">
                          <button
                            className={`h-[35px] px-2 ml-2 mt-2 rounded-md ${
                              darkMode
                                ? "text-[#ffffff] bg-gray-900"
                                : "bg-[#0e4980] text-[#ffffff]"
                            }`}
                          >
                            <Link to={`/sliders/${slider.id}`}>Editar</Link>
                          </button>
                          <button
                            className={`h-[35px] px-2 ml-2 mt-2 rounded-md ${
                              darkMode
                                ? "text-[#ffffff] bg-[#7d1e1e]"
                                : "bg-[#800e0e] text-[#ffffff]"
                            }`}
                            onClick={() => handleDelete(slider.id)}
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className="w-full flex justify-end items-end h-full">
                <button
                  className={`w-full p-2 rounded-md rounded-t-none ${
                    darkMode
                      ? "text-[#ffffff] bg-gray-900"
                      : "bg-[#0e4980] text-[#ffffff]"
                  }`}
                >
                  <Link to="/slider-home-form-panel">Agregar</Link>
                </button>
              </div>
            </div>
            <div
              className={`w-full h-auto flex flex-col items-center rounded-md py-6 ${
                darkMode
                  ? "bg-[#606060] text-[#ffffff]"
                  : "bg-blue-100 text-[#0e4980]"
              }`}
            >
              <h1 className="text-2xl py-2">
                Editar contenido de los aliados y fabricantes:
              </h1>
              <div className="w-full flex justify-center h-full">
                {manufactures.map((manufacture, index) => (
                  <div key={index}>
                    <div className="w-full mb-8">
                      <h1
                        className={`titleA text-4xl mb-4 text-[#0e4980] font-semibold ${
                          darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                        }`}
                      >
                        {manufacture.title}
                      </h1>
                      <h2
                        className={`title2 text-2xl mb-4 text-[#0e4980] font-semibold ${
                          darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                        }`}
                      >
                        {manufacture.sub_title}
                      </h2>
                      <p
                        className={`conten w-[100%] text-lg mt-4} ${
                          darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                        }`}
                      >
                        {manufacture.description}
                      </p>
                    </div>
                    <button
                      className={`w-full p-2 rounded-md ${
                        darkMode
                          ? "text-[#ffffff] bg-gray-900"
                          : "bg-[#0e4980] text-[#ffffff]"
                      }`}
                    >
                      <Link
                        to={`/manufactures-allies-content/${manufacture.id}`}
                      >
                        Editar contenido
                      </Link>
                    </button>
                  </div>
                ))}
              </div>
              <div className="w-full flex justify-around py-6">
                <h1 className="text-2xl py-2">Editar logos de fabricantes:</h1>
                <button
                  className={`h-[35px] px-2 ml-2 mt-2 rounded-md ${
                    darkMode
                      ? "text-[#ffffff] bg-gray-900"
                      : "bg-[#0e4980] text-[#ffffff]"
                  }`}
                >
                  {" "}
                  <Link to="/manufactures-form-images">Agregar Logos</Link>
                </button>
              </div>
              <div className="w-full flex justify-center h-full">
                <div
                  className={`grid grid-cols-7 ${
                    darkMode ? " text-white" : ""
                  }`}
                >
                  {manufacturesImages.map((logo, index) => (
                    <div
                      key={index}
                      className="img-hover-container flex flex-col items-center justify-center rounded-md"
                    >
                      <img
                        src={
                          darkMode
                            ? logo.image_dark_manufactures
                            : logo.image_manufactures
                        }
                        className="w-[80%] h-[80%] rounded-md"
                      />
                      <div className="w-full flex justify-center pb-[4%]">
                        <button
                          className={`h-[35px] px-2 mt-2 rounded-md ${
                            darkMode
                              ? "text-[#ffffff] bg-gray-900"
                              : "bg-[#0e4980] text-[#ffffff]"
                          }`}
                        >
                          <Link to={`/manufactures-images/${logo.id}`}>
                            Editar
                          </Link>
                        </button>
                        <button
                          className={`h-[35px] px-2 mt-2 rounded-md ${
                            darkMode
                              ? "text-[#ffffff] bg-[#7d1e1e]"
                              : "bg-[#800e0e] text-[#ffffff]"
                          }`}
                          onClick={() => handleDeleteManufacture(logo.id)}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full flex justify-around py-6">
                <h1 className="text-2xl py-2">Editar logos de aliados:</h1>
                <button
                  className={`h-[35px] px-2 ml-2 mt-2 rounded-md ${
                    darkMode
                      ? "text-[#ffffff] bg-gray-900"
                      : "bg-[#0e4980] text-[#ffffff]"
                  }`}
                >
                  {" "}
                  <Link to="/allies-form-images">Agregar Logos</Link>
                </button>
              </div>
              <div className="w-full flex justify-center h-full">
                <div
                  className={`grid grid-cols-7 ${darkMode ? "text-white" : ""}`}
                >
                  {alliesImages.map((logo, index) => (
                    <div
                      key={index}
                      className="img-hover-container flex flex-col items-center justify-center rounded-md"
                    >
                      <img
                        src={
                          darkMode ? logo.image_dark_allies : logo.image_allies
                        }
                        className="w-[80%] h-[80%] rounded-md"
                      />
                      <div className="flex justify-center pb-[4%]">
                        <button
                          className={`h-[35px] px-2 mt-2 rounded-md ${
                            darkMode
                              ? "text-[#ffffff] bg-gray-900"
                              : "bg-[#0e4980] text-[#ffffff]"
                          }`}
                        >
                          <Link to={`/allies-images/${logo.id}`}>Editar</Link>
                        </button>
                        <button
                          className={`h-[35px] px-2 mt-2 rounded-md ${
                            darkMode
                              ? "text-[#ffffff] bg-[#7d1e1e]"
                              : "bg-[#800e0e] text-[#ffffff]"
                          }`}
                          onClick={() => handleDeleteAllie(logo.id)}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* <h1 className="text-2xl">
              Editar contenido del ciclo de experiencia:
            </h1>
            <div className="w-full flex justify-end items-end rounded-md">
              {cycleContent.map((cycle, index) => (
                <Parallax
                  bgImage={cycle.image_background}
                  className="w-full flex items-center h-[600px]"
                  strength={400}
                >
                  <div className="customer-experience-cycle">
                    <div className="cycle-container">
                      <div className={`center-circle ${darkMode ? "" : ""}`}>
                        <img
                          src={
                            darkMode
                              ? cycle.image_dark_cycle
                              : cycle.image_cycle
                          }
                          alt=""
                        />
                      </div>
                      {cycleImages.map((cycleImage, index) => (
                        <div
                          key={index}
                          className={`outer-circle ${
                            darkMode
                              ? "bg-[#424242e1] border-gray-600"
                              : "bg-[#3a9ec9ec]"
                          }`}
                        >
                          <img
                            src={cycleImage.images_cycle}
                            className="w-[32px]"
                            alt={cycleImage.title}
                          />
                          <p>{cycleImage.title}</p>
                          <span>{cycleImage.cycle_sequence}</span>
                          <button
                            className={`h-[35px] px-2 mt-2 text-sm rounded-md ${
                              darkMode
                                ? "text-[#ffffff] bg-gray-900"
                                : "bg-[#0e4980] text-[#ffffff]"
                            }`}
                          >
                            <Link
                              to={`/cycle-experience-images/${cycleImage.id}`}
                            >
                              Editar
                            </Link>
                          </button>
                        </div>
                      ))}
                    </div>
                    <div
                      className={`description ${
                        darkMode ? "bg-[#424242e1]" : "bg-[#113d69f4]"
                      }`}
                    >
                      <h2
                        className={`ml-[15%] text-2xl py-8 w-[80%] font-semibold ${
                          darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                        }`}
                      >
                        {cycle.title}
                      </h2>
                      <p
                        className={`w-[75%] ml-[15%] text-md text-justify font-medium pb-[5%] ${
                          darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                        }`}
                      >
                        {cycle.description_one}
                      </p>
                      <p
                        className={`w-[75%] ml-[15%] text-md text-justify pb-4 font-medium ${
                          darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                        }`}
                      >
                        {cycle.description_two}
                      </p>
                      <button
                        className={`w-[50%] ml-[25%] p-2 rounded-md ${
                          darkMode
                            ? "text-[#ffffff] bg-gray-900"
                            : "bg-[#316799] text-[#ffffff]"
                        }`}
                      >
                        <Link to={`/cycle-experience-content/${cycle.id}`}>
                          Editar
                        </Link>
                      </button>
                    </div>
                  </div>
                </Parallax>
              ))}
            </div> */}

            <div
              className={`w-full h-auto flex flex-col items-center rounded-md pt-6 ${
                darkMode
                  ? "bg-[#606060] text-[#ffffff]"
                  : "bg-blue-100 text-[#0e4980]"
              }`}
            >
              <h1 className="text-2xl py-2">
                Editar contenido del slider de los productos de TI
              </h1>
              <div className="w-full flex flex-col justify-end items-end h-full">
                <div className="w-full">
                  <div>
                    <Slider {...settings}>
                      {servicesTi.map((product) => (
                        <div
                          className="py-8 bg-[#beddf900] rounded-md"
                          key={product.id}
                        >
                          <div className="w-[80%] ml-[10%]">
                            <div className="cont flex flex-col items-center justify-center">
                              <h1
                                className={`text-[#0e4980] text-2xl font-semibold ${
                                  darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                                }`}
                              >
                                {product.sub_title}
                              </h1>
                              <img
                                className="slide-img h-[450px]"
                                src={product.images_services_ti}
                                alt=""
                              />
                              <div className="w-full flex justify-center pb-[4%]">
                                <button
                                  className={`h-[35px] px-2 mt-2 rounded-md ${
                                    darkMode
                                      ? "text-[#ffffff] bg-gray-900"
                                      : "bg-[#0e4980] text-[#ffffff]"
                                  }`}
                                >
                                  <Link to={`/services-ti/${product.id}`}>
                                    Editar
                                  </Link>
                                </button>
                                <button
                                  className={`h-[35px] px-2 mt-2 rounded-md ${
                                    darkMode
                                      ? "text-[#ffffff] bg-[#7d1e1e]"
                                      : "bg-[#800e0e] text-[#ffffff]"
                                  }`}
                                  onClick={() =>
                                    handleDeleteServiceTi(product.id)
                                  }
                                >
                                  Eliminar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
              <button
                className={`w-full p-2 mt-8 rounded-md ${
                  darkMode
                    ? "text-[#ffffff] bg-gray-900"
                    : "bg-[#0e4980] text-[#ffffff]"
                }`}
              >
                <Link to="/services-ti-form">Agregar Productos Ti</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterAmin />
    </>
  );
}

export default PanelAddHome;
