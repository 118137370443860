import React, { createContext, useState, useEffect } from "react";
import Swal from "sweetalert2";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Cargar productos desde localStorage al iniciar la aplicación
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem("cartItems");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Guardar productos en localStorage cada vez que cambie cartItems
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  // Función para agregar productos al carrito
  const addToCart = (item) => {
    // Verificar si el producto ya está en el carrito
    const itemExists = cartItems.some((cartItem) => cartItem.id === item.id);

    if (itemExists) {
      // Mostrar alerta si el producto ya fue agregado
      Swal.fire({
        icon: "warning",
        title: "El producto ya está en el carrito",
        text: `${item.name} ya fue agregado a la cotización.`,
        confirmButtonText: "Entendido",
      });
    } else {
      setCartItems([...cartItems, item]); // Agregar el producto si no está
      Swal.fire({
        icon: "success",
        title: "Producto agregado",
        text: `${item.name} ha sido agregado al carrito.`,
        confirmButtonText: "Genial",
      });
    }
  };

  // Función para eliminar productos del carrito
  const removeFromCart = (id) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};
