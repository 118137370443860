import React, { useState, useEffect, useRef, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Navbar/Navbar";
import { ThemeContext } from "../Context/ThemeContext";
import Footer from "../components/Footer/Footer";
import "./styles/aboutUs.css";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-parallax";
import AOS from "aos";
import "aos/dist/aos.css";
import { useContentAboutUs } from "../Context/AuthAboutUsContext";
import CicloExperiencia from "../components/CicloExperiencia/CicloExperiencia";
// import bgAboutUs from "../../public/bg-about-us.jpg";

function AboutUs() {
  const { darkMode } = useContext(ThemeContext); //variable de estado para asignar estilos del modo oscuro/claro

  const [isVisible, setIsVisible] = useState(false); //estado para controlar visibilidad
  const containerRef = useRef(null); //referencia al contenedor principal
  const { firstContent, getFirstContent } = useContentAboutUs();
  const { subContent, getSubContent } = useContentAboutUs();
  const { valuesContent, getValuesContent } = useContentAboutUs();
  const { membershipsContent, getMembershipsContent } = useContentAboutUs();
  const { policyContent, getPolicyContent } = useContentAboutUs();
  const { missionAndVissionContent, getMissionAndVissionContent } =
    useContentAboutUs();

  useEffect(() => {
    getFirstContent();
    getSubContent();
    getMissionAndVissionContent();
    getValuesContent();
    getMembershipsContent();
    getPolicyContent();
  }, []);

  useEffect(() => {
    //configura el IntersectionObserver para detectar visibilidad
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true, //configura para que solo se active una vez
    threshold: 0.1,
  });

  useEffect(() => {
    //inicializa AOS para animaciones
    AOS.init({
      duration: 1000, //duración de la animación en milisegundos
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="h-[85vh]">
        {firstContent.map((content, index) => (
          <Parallax
            bgImage={content.image_background}
            // blur={{ min: -2, max: 4 }}
            strength={0}
            key={index}
          >
            <div
              className={`principal bg-black/65 flex w-full justify-between ${
                darkMode ? "text-white" : ""
              }`}
              ref={containerRef}
            >
              <div className="txt flex flex-col justify-center items-center w-full rounded-r-full animate-fade-right animate-once animate-duration-[8000ms] animate-normal animate-fill-backwards h-[85vh]">
                <div
                  className={`txt-c w-[90%] h-[50vh] rounded-r-full flex flex-col pt-16 justify-center ${
                    darkMode ? "" : ""
                  } ${
                    isVisible
                      ? "animate-fade-left animate-once animate-duration-[5000ms] animate-normal animate-fill-backwards"
                      : ""
                  }`}
                >
                  <h1
                    className={`text-7xl mb-4 w-[80%] font-semibold ${
                      darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                    }`}
                  >
                    {content.title}
                    {/* ¿Quiénes Somos? */}
                  </h1>
                  <h2
                    className={`text-5xl mb-4 w-[80%] font-semibold ${
                      darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                    }`}
                  >
                    {content.sub_title}
                    {/* GTI – Alberto Álvarez López */}
                  </h2>
                  <p
                    className={`w-[70%] text-2xl font-medium text-justify ${
                      darkMode ? "text-[#ffffff]" : "text-[#ffffff]"
                    }`}
                  >
                    {content.description}
                    {/* Somos GTI - Alberto Álvarez López SAS, empresa líder en
                integración tecnológica, contamos con el respaldo de los
                principales fabricantes a nivel mundial y una trayectoria de 30
                años en el país, apoyamos la transformación digital basados en
                la innovación y la co-creación de valor para nuestros clientes.
                Juntos, estamos construyendo un futuro donde las tendencias
                tecnológicas no solo mejoran nuestras operaciones, sino que
                también enriquecen la vida de las personas y las comunidades a
                las que servimos. */}
                  </p>
                </div>
              </div>
            </div>
          </Parallax>
        ))}
      </div>
      <div
        ref={ref}
        className={`estadisticas w-full py-8 flex ${
          darkMode
            ? "bg-gray-900 text-[#ffffff]"
            : "bg-[#F1F8FE] text-[#0e4980]"
        }`}
      >
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={30} duration={3} suffix="+" />}
          </h2>
          <p className="text-xl">Años de experiencia</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={1000} duration={3} suffix="+" />}
          </h2>
          <p className="text-xl">Clientes</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={6000} duration={3} suffix="+" />}
          </h2>
          <p className="text-xl">Dispositivos en modelo DaaS e IaaS</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={100} duration={3} suffix="+" />}
          </h2>
          <p className="text-xl">Colaboradores</p>
        </div>
        <div>
          <h2 className="text-4xl">
            {inView && <CountUp start={0} end={100} duration={3} suffix="%" />}
          </h2>
          <p className="text-xl">Cubrimiento a nivel nacional</p>
        </div>
      </div>
      <div
        className={`w-full flex justify-center ${
          darkMode ? "bg-gray-900 text-[#ffffff]" : "bg-white text-[#0e4980]"
        }`}
      >
        {subContent.map((subContent, index) => (
          <div
            className={`w-full flex justify-center flex-col items-center ${
              darkMode
                ? "bg-gray-900 text-[#ffffff]"
                : "bg-white text-[#0e4980]"
            }`}
            key={subContent.id}
          >
            <div
              className={`middle-title w-full flex justify-center text-3xl font-semibold py-[2%] ${
                darkMode
                  ? "bg-gray-950 text-[#ffffff]"
                  : "bg-white text-[#0e4980]"
              }`}
            >
              <h1>{subContent.title}</h1>
            </div>
            <div className="about-2 w-[80%] flex justify-between items-center mb-[5%]">
              <div
                data-aos="fade-up"
                className="secondC w-[40%] flex justify-center"
              >
                <img
                  src={subContent.image_sub_content}
                  className="w-[100%] h-full rounded-[2%]"
                  alt=""
                />
              </div>
              <div className="w-[50%] firstC">
                <p
                  className={`p-2 whitespace-pre-line text-lg text-justify font-semibold ${
                    darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                  }`}
                >
                  {subContent.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`w-full flex justify-center items-center ${
          darkMode ? "bg-gray-900 text-[#ffffff]" : "bg-white text-[#0e4980]"
        }`}
      >
        <div
          className={`w-full ${
            darkMode ? "bg-gray-900 text-[#ffffff]" : "bg-white text-[#0e4980]"
          }`}
        >
          <div
            className={`middle-title w-full flex justify-center pb-8 text-4xl font-semibold ${
              darkMode
                ? "bg-gray-950 text-[#ffffff]"
                : "bg-white text-[#0e4980]"
            }`}
          >
            <h1>Nuestras Membresías</h1>
          </div>
          <div className="w-full flex justify-center items-center pb-6">
            <div
              data-aos="fade-up"
              className="mem w-[80%] grid grid-cols-5 gap-3 justify-center items-center"
            >
              {membershipsContent.map((value, index) => (
                <div key={index} className="w-full flex flex-col items-center">
                  <img
                    src={
                      darkMode
                        ? value.memberships_dark_image
                        : value.memberships_image
                    }
                    className="w-[70%] rounded-[2%]"
                    alt="membresías"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-full flex flex-col items-center ${
          darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
        }`}
      >
        {valuesContent.map((value, index) => (
          <div key={index}>
            <h2 className="text-4xl font-semibold mb-2" data-aos="fade-up">
              {value.title}
            </h2>
          </div>
        ))}
        <div className="w-[80%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {valuesContent.map((value, index) => (
            <div
              className="value-item p-4 text-center"
              data-aos="fade-up"
              key={value.id}
            >
              <img
                src={darkMode ? value.values_dark_image : value.values_image}
                className="w-20 h-20 mx-auto mb-4"
                alt="Calidad"
              />
              <h3 className="text-xl font-semibold">{value.values_about_us}</h3>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`w-full flex flex-col py-10 items-center ${
          darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
        }`}
      >
        {missionAndVissionContent.map((missionAndvission, index) => (
          <div
            className="w-[80%] flex justify-center"
            key={missionAndvission.id}
          >
            <div
              className={`flex flex-col items-center ${
                darkMode
                  ? "bg-[#424242] text-[#ffffff]"
                  : "bg-white text-[#0e4980]"
              }`}
            >
              <div className="w-full values-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                <div className="value-item p-4 text-center" data-aos="fade-up">
                  <img
                    src={
                      darkMode
                        ? missionAndvission.mission_dark_image
                        : missionAndvission.mission_image
                    }
                    className="w-20 h-20 mx-auto mb-4"
                    alt="Misión"
                  />
                  <h3 className="text-2xl font-semibold">
                    {missionAndvission.mission}
                  </h3>
                  <p className="text-lg text-justify">
                    {missionAndvission.description_mission}
                  </p>
                </div>
                <div className="value-item p-4 text-center" data-aos="fade-up">
                  <img
                    src={
                      darkMode
                        ? missionAndvission.vission_dark_image
                        : missionAndvission.vission_image
                    }
                    className="w-20 h-20 mx-auto mb-4"
                    alt="Visión"
                  />
                  <h3 className="text-2xl font-semibold">
                    {missionAndvission.vission}
                  </h3>
                  <p className="text-md text-justify">
                    {missionAndvission.description_vission}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`w-full flex justify-center ${
          darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
        }`}
      >
        {policyContent.map((policy, index) => (
          <div
            key={index}
            className={`w-full flex justify-center flex-col items-center ${
              darkMode
                ? "bg-[#424242] text-[#ffffff]"
                : "bg-white text-[#0e4980]"
            }`}
          >
            <div
              className={`middle-title w-full flex justify-center text-4xl font-semibold ${
                darkMode
                  ? "bg-[#424242] text-[#ffffff]"
                  : "bg-white text-[#0e4980]"
              }`}
            >
              <h1>{policy.title}</h1>
            </div>
            <div className="cali w-[80%] flex justify-enter items-center">
              <div className="w-[80%]">
                <p
                  className={`p-2 whitespace-pre-line text-lg text-justify font-semibold ${
                    darkMode ? "text-[#ffffff]" : "text-[#0e4980]"
                  }`}
                >
                  {policy.description}
                </p>
              </div>
              <div className="iso w-[20%]">
                <img
                  src={
                    darkMode ? policy.policy_dark_image : policy.policy_image
                  }
                  alt="política de calidad"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`w-full flex justify-center ${
          darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
        }`}
      >
        <h1
          className={`p-6 text-4xl font-semibold ${
            darkMode ? "bg-[#424242] text-[#ffffff]" : "bg-white text-[#0e4980]"
          }`}
        >
          ¿Cómo operamos?
        </h1>
      </div>
      <CicloExperiencia />

      <Footer />
    </>
  );
}

export default AboutUs;
