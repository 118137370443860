import React, { useEffect, useState } from "react";
import NavbarAdmin from "../../components/Navbar/NavbarAdmin";
import FooterAmin from "../../components/Footer/FooterAmin";
import { useProducts } from "../../Context/ProductsContext";
import { Carousel } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function PanelEcommerce() {
  const { products, getProducts, deleteProducts } = useProducts();

  // Cargar productos al montar el componente
  useEffect(() => {
    getProducts();
  }, []);

  const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda

  // Filtrar productos según el término de búsqueda
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Separar productos por categorías
  const perifericos = filteredProducts.filter(
    (product) => product.category === "Perifericos"
  );
  const iaas = filteredProducts.filter(
    (product) => product.category === "IaaS"
  );
  const daas = filteredProducts.filter(
    (product) => product.category === "DaaS"
  );

  // Maneja la eliminación de un artículo con una confirmación
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      await deleteProducts(id);
      getProducts();
      Swal.fire("¡Eliminado!", "El Producto ha sido eliminado.", "success");
    }
  };

  // Renderizar productos por categoría
  const renderProducts = (categoryProducts, categoryName) => (
    <div className="w-[80%] my-8">
      <h2 className="text-2xl font-bold mb-4">{categoryName}</h2>
      {categoryProducts.length > 0 ? (
        <div className="grid grid-cols-4 gap-4">
          {categoryProducts.map((item) => (
            <div
              key={item.id}
              className="border p-4 rounded-lg bg-[#0E4980] flex flex-col items-center"
            >
              <div className="bg-[#C2DBF2] w-full flex flex-col justify-center items-center rounded-md">
                <Carousel
                  autoplay={true}
                  loop={true}
                  nextArrow={false}
                  prevArrow={false}
                  className="w-full h-full"
                >
                  {item.images && item.images.length > 0 ? (
                    item.images.map((imgSrc, imgIndex) => (
                      <div
                        key={imgIndex}
                        className="w-full flex justify-center"
                      >
                        <img
                          src={imgSrc}
                          alt={`${item.name} image ${imgIndex + 1}`}
                          className="h-60 object-contain mb-10"
                        />
                      </div>
                    ))
                  ) : (
                    <div>No Image Available</div>
                  )}
                </Carousel>
              </div>
              <br />
              <div className="w-full h-16 flex justify-start">
                <h3 className="text-lg text-white font-semibold mb-4">
                  {item.name}
                </h3>
              </div>
              <div className="w-full flex justify-start gap-5">
                <Link
                  to={`/products-form-stock/${item.id}`}
                  className="bg-blue-700 text-white px-4 py-2 rounded-lg"
                >
                  Editar
                </Link>
                <button
                  className={`p-2 rounded-md bg-red-900 text-white`}
                  onClick={() => handleDelete(item.id)}
                >
                  Eliminar
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-xl text-red-600">
          No hay productos disponibles en esta categoría.
        </p>
      )}
    </div>
  );

  return (
    <>
      <NavbarAdmin />
      <div className="w-full flex flex-col items-center justify-center mt-24">
        {/* Encabezado */}
        <div
          className={`h-[50vh] text-center text-black bg-[url("https://res.cloudinary.com/dtpshcfjx/image/upload/v1726107293/ypsx9atdwauubsriabhq.jpg")] bg-no-repeat bg-cover bg-center py-10 w-full flex flex-col items-center justify-center`}
        ></div>

        {/* Barra de búsqueda */}
        <div className="w-[80%] flex justify-start gap-5 my-8">
          <input
            type="text"
            placeholder="Buscar artículos..."
            className="border border-gray-300 px-4 py-2 w-[90%] rounded-l-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Actualizar el término de búsqueda
          />
        </div>

        {/* Secciones de productos por categoría */}
        {renderProducts(perifericos, "Periféricos")}
        {renderProducts(daas, "DaaS (Dispositivo como servicio)")}
        {renderProducts(iaas, "IaaS (Infraestructura como servicio)")}
      </div>
      <FooterAmin />
    </>
  );
}

export default PanelEcommerce;
