import React, { useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import bcrypt from "bcryptjs";
import NavbarAdmin from "../../components/Navbar/NavbarAdmin";
import { useAuth } from "../../Context/AuthUserContext";
import { useForm } from "react-hook-form";

function ProfilePage() {
  // Contexto para obtener la información del usuario y la función de cierre de sesión
  const { user, logout } = useAuth();

  // Inicializa el hook useForm con los valores por defecto del usuario
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      username: user.User.username,
      email: user.User.email,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  // Estados para manejar la edición de la información del perfil y la contraseña
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  // Parámetros de URL para acceder al ID del usuario
  const params = useParams();

  // Función para mostrar alertas usando SweetAlert2
  const showAlert = (message, type, time, confirmation) => {
    let iconType = "info";
    if (type === "error") {
      iconType = "error";
    } else if (type === "warning") {
      iconType = "warning";
    } else if (type === "success") {
      iconType = "success";
    }

    Swal.fire({
      title: message,
      icon: iconType,
      color: "#BEDDF9",
      iconColor: "#BEDDF9",
      background: "#0E4980",
      timer: time,
      showConfirmButton: confirmation,
    });
  };

  // Maneja el clic en el botón para empezar a editar la información del perfil
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Maneja el clic en el botón para empezar a editar la contraseña
  const handleEditPasswordClick = () => {
    setIsEditingPassword(true);
  };

  // Maneja el guardado de la información del perfil
  const handleSaveClick = async (data) => {
    try {
      const res = await axios.put(
        // URL del servidor para actualizar la información del perfil
        `https://appser-gti-bk-prod-fwe0e3e9ftaghzeq.eastus2-01.azurewebsites.net/update-credentials/${params.id}`,
        { username: data.username, email: data.email }
      );
      if (res.status === 200) {
        showAlert("La información se actualizó correctamente", "success", 2000);
        logout(); // Cierra la sesión después de actualizar
      }
    } catch (error) {
      console.error(error);
    }
    setIsEditing(false); // Sale del modo de edición después de guardar
  };

  // Maneja el cambio de contraseña del usuario
  const handleChangePassword = async (data) => {
    // Verifica si la contraseña actual es correcta
    const isMatch = bcrypt.compareSync(
      data.currentPassword,
      user.User.password
    );

    if (!isMatch) {
      return showAlert("La contraseña actual es incorrecta.", "error");
    }
    if (data.newPassword !== data.confirmNewPassword) {
      return showAlert("Las contraseñas no coinciden", "error");
    }

    try {
      await axios.put(
        // URL del servidor para actualizar la contraseña
        `https://appser-gti-bk-prod-fwe0e3e9ftaghzeq.eastus2-01.azurewebsites.net/update-credentials/${params.id}`,
        {
          password: data.newPassword,
        }
      );

      showAlert("La contraseña se actualizó correctamente", "success", 3000);
      reset({
        // Restablece los campos del formulario
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
      logout(); // Cierra la sesión después de cambiar la contraseña
    } catch (error) {
      console.error(error);
      reset({
        // Restablece los campos del formulario en caso de error
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
    }

    setIsEditingPassword(false); // Sale del modo de edición de contraseña después de guardar
  };

  // Maneja la cancelación de la edición de información del perfil
  const handleCancelInfo = () => {
    reset({
      username: user.User.username,
      email: user.User.email,
    });
    setIsEditing(false); // Sale del modo de edición
  };

  return (
    <>
      <NavbarAdmin />
      <div className="w-full flex justify-center items-center bg-white ">
        <div className="w-[50%] flex flex-col justify-center mt-[15%] ">
          <h1 className={`text-3xl mb-4`}>Actualizar Datos</h1>
          <div className="mb-4">
            <div>
              <button
                onClick={handleEditClick}
                disabled={isEditing}
                className="w-full text-base bg-[#0E4980] text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-md rounded-b-none"
              >
                Editar información
              </button>
            </div>
            <form onSubmit={handleSubmit(handleSaveClick)}>
              <div className="">
                <input
                  id="emailProfile"
                  className={
                    isEditing
                      ? "bg-[#BEDDF9] pl-1 border-amber-950  border-[1px] border-y-0 rounded-b-none  w-full p-2 h-10 outline-none"
                      : "bg-[#BEDDF9] text-gray-600 pl-1 border-amber-950  border-[1px] border-y-0 rounded-b-none  w-full p-2 h-10 outline-none"
                  }
                  type="email"
                  {...register("email")}
                  disabled={!isEditing}
                />
              </div>

              <div className="flex">
                <button
                  type="submit"
                  className="w-[50%] bg-[#0E4980] text-white text-base mb-4 bg-acent font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-t-none rounded-md"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  className="w-[50%] text-base mb-4 bg-red-800 text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-t-none rounded-md  hover:bg-red-700"
                  onClick={handleCancelInfo}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <div className="mb-2 w-full">
            <div>
              <button
                onClick={handleEditPasswordClick}
                className={
                  isEditingPassword
                    ? "w-full text-base bg-[#0E4980] text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-md rounded-b-none"
                    : "w-full text-base bg-[#0E4980] text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-md"
                }
              >
                Cambiar contraseña
              </button>
            </div>
            {isEditingPassword && (
              <form onSubmit={handleSubmit(handleChangePassword)}>
                <div>
                  <input
                    className="bg-[#BEDDF9] pl-1 border-amber-950  border-[1px]  rounded-b-none  w-full p-2 h-10 outline-none animate-slide-in-top animate-duration-300"
                    type="password"
                    id="currentPassword"
                    {...register("currentPassword")}
                    placeholder="Contraseña actual"
                  />
                </div>
                <div>
                  <input
                    className="bg-[#BEDDF9] pl-1 border-amber-950  border-[1px] border-t-0  rounded-b-none  w-full p-2 h-10 outline-none animate-slide-in-top animate-duration-300"
                    type="password"
                    id="newPassword"
                    {...register("newPassword")}
                    placeholder="Nueva contraseña"
                  />
                </div>
                <div>
                  <input
                    className="bg-[#BEDDF9] pl-1 border-amber-950  border-x-[1px]  rounded-b-none  w-full p-2 h-10 outline-none animate-slide-in-top animate-duration-300"
                    type="password"
                    id="confirmNewPassword"
                    {...register("confirmNewPassword")}
                    placeholder="Confirmar contraseña"
                  />
                </div>

                <div className="flex">
                  <button
                    type="submit"
                    className="w-[70%] bg-[#0E4980] text-white md:w-[50%] text-base bg-acent font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-t-none rounded-md animate-slide-in-top animate-duration-300"
                  >
                    Cambiar Contraseña
                  </button>
                  <button
                    type="button"
                    className="w-[50%] text-base bg-red-800 text-white font-bold p-2 h-10 text-textBlack shadow-gold shadow-inner rounded-t-none rounded-md  hover:bg-red-700 animate-slide-in-top animate-duration-300"
                    onClick={() => setIsEditingPassword(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
